export const TRANSITION = '1000ms opacity'

/**
 * Increments the index until it reaches a final number
 * and then resets.
 */
export const incrementQuoteIndex = (prevIndex: number, arrayLength: number) => {
  const newIndex = prevIndex + 1
  return arrayLength - 1 === prevIndex ? 0 : newIndex
}
