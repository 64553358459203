import styled from 'styled-components'

import { BagSimple } from 'phosphor-react'

interface CartNumberProps {
  quantity: number
}

export const CartNumber = ({ quantity }: CartNumberProps) => (
  <>
    <BagSimple weight="light" size={26} />
    <CartValue>{quantity}</CartValue>
  </>
)

const CartValue = styled.span`
  font-size: 11px;
  font-weight: 500;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
`
