import { atom, useAtom } from 'jotai'
import { SetStateAction, useEffect } from 'react'
import useLocalStorage from './useLocalStorage'
const usdRateAtom = atom<number>(1)
const eurRateAtom = atom<number>(1)
const currencyAtom = atom<CURRENCY>('GBP')

export const useExchangeRate = () => {
  const [usdRate, setUsdRate] = useAtom(usdRateAtom)
  const [eurRate, setEurRate] = useAtom(eurRateAtom)
  return { USD: [usdRate, setUsdRate], EUR: [eurRate, setEurRate] } as {
    USD: [number, (update: SetStateAction<number>) => void]
    EUR: [number, (update: SetStateAction<number>) => void]
  }
}

type CURRENCY = 'GBP' | 'USD' | 'EUR'

export const useCurrency = () => {
  const [currency, setCurrency] = useAtom(currencyAtom)
  const [value, setValue] = useLocalStorage<CURRENCY>('currency', 'GBP')

  useEffect(() => {
    if (value) {
      setCurrency(value)
    }
  }, [])

  const customSetCurrency = (val: CURRENCY) => {
    setValue(val)
    setCurrency(val)
  }

  const options = ['GBP', 'USD', 'EUR']
  return [currency, customSetCurrency, options] as [
    string,
    (update: SetStateAction<CURRENCY>) => void,
    string[]
  ]
}
