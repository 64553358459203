import { useRef, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'

import { ModalUGCReadMore, ModalUGCReadMoreProps } from '../Modals'

type PortalUGCReadMoreProps = ModalUGCReadMoreProps & {
  id: string
}

export const PortalUGCReadMore = (props: PortalUGCReadMoreProps) => {
  const ref = useRef<HTMLElement>(null!)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const el = document.getElementById(props.id)
    ref.current = el!
    if (el) {
      setMounted(true)
    }
  }, [])

  return mounted
    ? createPortal(
        <ModalUGCReadMore key={props.imageId} {...props} />,
        ref.current
      )
    : null
}
