import styled from 'styled-components'
import clsx from 'clsx'
import { Sanity } from '@cellulargoods/types'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { HeaderTextSizes } from '../../references/constants'

import { Heading } from '../Text/Heading'
import { Text } from '../Text/Text'
import { Button, ButtonTypes } from '../Button'
import { Media } from '../Media/Media'

import { getHeaderFontStyles } from '../../helpers/text'
import { seperateTextByLineBreaks } from '../../helpers/strings'

export type ContentFullBleedProps = Sanity.Keyed<Sanity.FullBleedComponent> & {
  className?: string
}

export const ContentFullBleed = ({
  media,
  position,
  headingText,
  isFullBleed = true,
}: ContentFullBleedProps) => {
  const { title, subtitle, size, body, CTAButton: cta } = headingText ?? {}

  const { headerFontStyle, subtitleFontStyle } = getHeaderFontStyles(
    size as HeaderTextSizes
  )

  const isThereCta = Boolean(cta && cta.label && cta.url)

  return (
    <FullBleed>
      {media && media.length > 0 && (
        <FullMediaContainer className={clsx(!isFullBleed && 'not-full-bleed')}>
          <Media {...media[0]} sizes={['100vw']} />
        </FullMediaContainer>
      )}
      {title || subtitle || body || isThereCta ? (
        <FullContent position={position ?? 'center'}>
          {title && (
            <FullTitle tag="h2" fontStyle={headerFontStyle}>
              {title}
            </FullTitle>
          )}
          {subtitle && (
            <FullSubtitle tag="h3" fontStyle={subtitleFontStyle}>
              {seperateTextByLineBreaks(subtitle).map((str, i) => (
                <span key={i}>{str}</span>
              ))}
            </FullSubtitle>
          )}
          {body && (
            <FullCopy>
              {seperateTextByLineBreaks(body).map((str, i) => (
                <span key={i}>{str}</span>
              ))}
            </FullCopy>
          )}
          {isThereCta && (
            <FullCta
              variant={ButtonTypes.PRIMARY}
              tag="a"
              isExternal={Boolean(cta!.isExternal)}
              href={cta!.url!}
            >
              {cta!.label}
            </FullCta>
          )}
        </FullContent>
      ) : null}
    </FullBleed>
  )
}

const FullBleed = styled.section`
  height: auto;
  width: 100%;
`

const FullMediaContainer = styled.div`
  background-color: var(--white);
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 5/2.8;
`

const FullContent = styled.div<{
  position: string
}>`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 67rem;
    padding: 0;
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    ${(props) =>
      props.position === 'top'
        ? `top: 18rem;`
        : props.position === 'bottom'
        ? `bottom: 18rem;`
        : `
        transform: translate(-50%, -50%);
        top: 50%;
    `};
  }
`

const FullTitle = styled(Heading)``

const FullSubtitle = styled(Heading)`
  margin-top: 2rem;

  & > span + span {
    display: block;
    margin-top: 1.37em;
  }
`

const FullCopy = styled(Text)`
  margin-top: 2rem;

  & > span + span {
    display: block;
    margin-top: 1.37em;
  }
`

const FullCta = styled(Button)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 21rem;
    margin: 2rem auto 0 auto;
  }
`
