import {
  useState,
  useRef,
  useEffect,
  ReactEventHandler,
  useCallback,
} from 'react'
import styled, { css } from 'styled-components'
import Hls from 'hls.js'
import { captureException } from '@sentry/nextjs'
import { animated, useSpring } from '@react-spring/web'

import { Button, ButtonTypes, Bg, IconButton } from '../Button'
import { omit, pick } from '../../helpers'

import { VideoControls } from './VideoControls'
import { useVideoPlayingContext } from './VideoPlayingContext'

import Play from '../../assets/play.svg'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

type VideoPlayerStateProps = {
  muted: boolean
  autoPlay: boolean
  loop: boolean
  playsInline: boolean
  controls: boolean
}

export type VideoPlayerProps = VideoPlayerStateProps & {
  src: string
  poster?: string
  floodParent?: boolean
  isMux?: boolean
  playbackId: string
  mode?: VIDEO_MODES
  showVolume?: boolean
}

export enum VIDEO_MODES {
  DEFAULT = 'default',
  CAROUSEL = 'carousel',
}

type VideoPlayerState = VideoPlayerStateProps & {
  playing: boolean
  currentTime: number
  duration: number
  scrubberValue: number
  volume: number
  expanded: boolean
}

export const VideoPlayer = ({
  src,
  poster,
  isMux,
  floodParent = true,
  playbackId,
  mode = VIDEO_MODES.DEFAULT,
  showVolume,
  ...videoInitialState
}: VideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null!)
  const [videoState, setVideoState] = useState<VideoPlayerState>({
    ...videoInitialState,
    duration: 0,
    currentTime: 0,
    scrubberValue: 0,
    volume: 1,
    expanded: false,
    playing: videoInitialState.autoPlay,
  })

  /**
   * Only used if the video is MUX which at the
   * time of writing it would only be...
   */
  useEffect(() => {
    const video = videoRef.current

    if (!video || !isMux) return

    let hls: Hls

    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      // This will run in safari, where HLS is supported natively
      video.src = src
    } else if (Hls.isSupported()) {
      // This will run in all other modern browsers
      hls = new Hls()
      hls.loadSource(src)
      hls.attachMedia(video)
    } else {
      const ERR_MSG =
        'This is an old browser that does not support MSE https://developer.mozilla.org/en-US/docs/Web/API/Media_Source_Extensions_API'
      console.error(ERR_MSG)
      captureException(new Error(ERR_MSG))
    }

    return () => {
      if (hls) {
        hls.destroy()
      }
    }
  }, [src, isMux])

  const [styles, api] = useSpring(
    () => ({
      y: '100%',
    }),
    []
  )

  useEffect(() => {
    if (videoState.playing && videoState.controls) {
      api.start({
        y: '0',
      })
    } else if (!videoState.playing) {
      api.start({
        y: '100%',
      })
    }
  }, [videoState.playing, videoState.controls, api])

  const handlePointerLeave = () => {
    if (videoState.playing && videoState.controls) {
      api.start({
        y: '100%',
        delay: 1000,
      })
    }
  }

  const handlePointerEnter = () => {
    if (videoState.playing && videoState.controls) {
      api.start({
        y: '0',
      })
    }
  }

  const handlePlayClick = () => {
    let overrideState: Partial<VideoPlayerState> = {}

    if (!videoState.controls) {
      /**
       * CMS mess up, someone won't be able to stop the video
       * so we just loop it
       */

      overrideState = {
        autoPlay: true,
        loop: true,
        muted: true,
      }
    }

    videoRef.current.play()

    setVideoState((s) => ({
      ...s,
      playing: true,
      isPlaying: true,
      ...overrideState,
    }))
  }

  const handleVideoDataLoaded: ReactEventHandler<HTMLVideoElement> = ({
    currentTarget,
  }) => {
    if (currentTarget) {
      setVideoState((s) => ({
        ...s,
        duration: currentTarget.duration,
        currentTime: currentTarget.currentTime,
      }))
    }
  }

  const handleTimeUpdate: ReactEventHandler<HTMLVideoElement> = ({
    currentTarget: { currentTime },
  }) => {
    const { duration } = videoState

    if (currentTime) {
      const scrubberValue = currentTime / duration
      setVideoState((s) => ({ ...s, currentTime: currentTime, scrubberValue }))
    }
  }

  const handlePlayPauseClick = useCallback((newPlayingState: boolean) => {
    if (newPlayingState) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }

    setVideoState((s) => {
      const { currentTime, duration } = s

      let newCurrentTime = currentTime
      if (currentTime === duration && newPlayingState) {
        /**
         * Rest the video if we're playing from the beginning
         */
        newCurrentTime = 0
        videoRef.current.currentTime = 0
      }

      return {
        ...s,
        playing: newPlayingState,
        currentTime: newCurrentTime,
      }
    })
  }, [])

  const handleVideoEnded = () => {
    setVideoState((s) => ({
      ...s,
      playing: false,
    }))
  }

  const handleScrubberDrag = (value: number) => {
    const { duration } = videoState
    if (videoRef.current) {
      const newTime = duration ? duration * value : value
      videoRef.current.currentTime = newTime
      setVideoState((s) => ({
        ...s,
        currentTime: newTime,
        scrubberValue: value,
      }))
    }
  }

  const handleScreenSizeClick = (newScreenSizeState: boolean) => {
    const { current: video } = videoRef
    if (video) {
      if (video.requestFullscreen) {
        video.requestFullscreen()
        // @ts-ignore shut up TS it does
      } else if (video.webkitRequestFullscreen) {
        // @ts-ignore shut up TS it does
        video.webkitRequestFullscreen()
        // @ts-ignore shut up TS it does
      } else if (video.webkitEnterFullscreen) {
        // @ts-ignore shut up TS it does
        video.webkitEnterFullscreen()
      }
      setVideoState((s) => ({ ...s, expanded: newScreenSizeState }))
    }
  }

  const handleVolumeClick = (newVolumeState: boolean) => {
    setVideoState((s) => ({ ...s, muted: newVolumeState }))
  }

  const handleVolumeScrubberDrag = (value: number) => {
    videoRef.current.volume = value

    let additionalState: Partial<VideoPlayerState> = {}

    if (value === 0) {
      additionalState = {
        muted: true,
      }
    } else if (value > 0 && videoState.muted) {
      additionalState = {
        muted: false,
      }
    }

    setVideoState((s) => ({ ...s, volume: value, ...additionalState }))
  }

  const [videos, setVideoPlaying] = useVideoPlayingContext((state) => state)

  useEffect(() => {
    if (videos && !videos[playbackId]) {
      handlePlayPauseClick(false)
    }
  }, [handlePlayPauseClick, playbackId, videos])

  useEffect(() => {
    if (setVideoPlaying && videoState.playing) {
      setVideoPlaying((s) => ({
        ...s,
        [playbackId]: true,
      }))
    } else if (setVideoPlaying && !videoState.playing) {
      setVideoPlaying((s) => ({
        ...s,
        [playbackId]: false,
      }))
    }

    return () => {
      if (setVideoPlaying) {
        setVideoPlaying((s) => omit(s, playbackId))
      }
    }
  }, [playbackId, setVideoPlaying, videoState.playing])

  return (
    <VideoContainer
      $floodParent={floodParent}
      onPointerLeave={handlePointerLeave}
      onPointerEnter={handlePointerEnter}
    >
      {!videoState.autoPlay &&
        !videoState.playing &&
        (mode === VIDEO_MODES.DEFAULT ? (
          <PlayButton
            variant={ButtonTypes.PRIMARY}
            bg={Bg.white}
            onClick={handlePlayClick}
          >{`Play`}</PlayButton>
        ) : (
          <PlayIconButton onClick={handlePlayClick} ariaLabel="play">
            <Play width="100%" height="100%" />
          </PlayIconButton>
        ))}

      <Video
        ref={videoRef}
        preload="auto"
        src={isMux ? undefined : src}
        poster={poster}
        $floodParent={floodParent}
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={handleVideoDataLoaded}
        onEnded={handleVideoEnded}
        {...pick(videoState, 'autoPlay', 'loop', 'muted', 'playsInline')}
      />

      {videoState.controls && (
        <VideoControlsContainer style={styles}>
          <VideoControls
            isPlaying={videoState.playing}
            onPlayPauseClick={handlePlayPauseClick}
            onScrubberDrag={handleScrubberDrag}
            onScreenSizeClick={handleScreenSizeClick}
            onVolumeClick={handleVolumeClick}
            onVolumeScrubberDrag={handleVolumeScrubberDrag}
            showVolume={showVolume}
            {...pick(
              videoState,
              'currentTime',
              'scrubberValue',
              'expanded',
              'muted',
              'volume'
            )}
          />
        </VideoControlsContainer>
      )}
    </VideoContainer>
  )
}

const VideoContainer = styled.div<{ $floodParent: boolean }>`
  ${(props) =>
    props.$floodParent
      ? `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
      : `
        position:relative;
      `}

  overflow: hidden;
`

const Video = styled.video<{ $floodParent: boolean }>`
  max-width: 100%;

  ${(props) =>
    props.$floodParent
      ? `
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  `
      : `
      
    width: 100%;
      `}

  ${MEDIA_QUERIES.desktopUp} {
    object-fit: cover;
  }
`

const VideoControlsContainer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const SharedButtonStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`

const PlayButton = styled(Button)`
  ${SharedButtonStyles}
  max-width: 21rem;
`

const PlayIconButton = styled(IconButton)`
  ${SharedButtonStyles}
  width: 5.2rem;
  height: 5.2rem;
`
