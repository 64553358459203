import styled from 'styled-components'
import { FONT_STYLE_SOFIA_11_400, getFontStyles } from '@cellulargoods/styles'
import { useState } from 'react'

export interface CheckboxInputProps {
  label?: string | React.ReactNode
  onChange?: (checked: boolean, fieldName?: string | undefined) => void
  checked?: boolean
  error?: boolean
  name?: string
  className?: string
  testId?: string
  disabled?: boolean
}

export const CheckboxInput = ({
  className,
  label,
  onChange,
  name,
  checked,
  error,
  disabled,
  testId,
}: CheckboxInputProps) => {
  const [internalChecked, setInternalChecked] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked, name)
    }
    setInternalChecked(e.target.checked)
  }

  const actualChecked = checked === undefined ? internalChecked : checked

  return (
    <Wrap aria-disabled={disabled} error={error} className={className}>
      <StyledCheckboxInput
        type="checkbox"
        name={name}
        checked={actualChecked}
        onChange={handleChange}
        disabled={disabled}
        data-testid={testId}
      />
      {label}
    </Wrap>
  )
}

const Wrap = styled.label<{
  error?: boolean
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  display: flex;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;

  ${({ error }) =>
    error &&
    `
    color: var(--validationError) !important;
    
    a {
      color: var(--validationError) !important;
    }

    input {
      border: 1px solid var(--validationError);
    }
  `}

  input {
    margin-right: 10px;
  }
`

const StyledCheckboxInput = styled.input`
  position: relative;
  width: 15px;
  height: 15px;
  color: inherit;
  border: 1px solid grey;
  appearance: none;
  outline: 0;
  cursor: pointer;
  flex: 0 0 15px;

  &:disabled {
    opacity: 0.35;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }

  &::before {
    position: absolute;
    content: '';
    top: 1px;
    left: 4px;
    width: 5px;
    height: 9px;
    border-style: solid;
    border-color: inherit;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
  }

  &:checked {
    color: inherit;
    border-color: inherit;
    &::before {
      opacity: 1;
    }
  }
`
