import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { UtilityPageSlugs } from 'references/constants'

export const WidgetIubenda = () => (
  <>
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: /* js */ `
        window._iub = window._iub || [];
        window._iub.csConfiguration = {
            "cookiePolicyInOtherWindow":true,
            "consentOnScroll":false,
            "consentOnHorizontalScroll":true,
            "consentOnDocument":true,
            "lang":"en",
            "siteId":2086908,
            "cookiePolicyId":26199281,
            "cookiePolicyUrl":"/${UtilityPageSlugs.cookiePolicy}", 
            "whitelabel": true,
            perPurposeConsent: true,
            priorConsent: true,
            "banner":{ 
                "applyStyles": false,
                "acceptButtonDisplay":true,
                "customizeButtonDisplay":true,
                "rejectButtonDisplay":true,
                acceptButtonCaption: 'Allow all cookies',
                customizeButtonCaption: 'Cookie settings',
                rejectButtonCaption: 'Reject cookies',
                "content":"<div class='iubenda-cs-paragraph' id='iubenda-cs-paragraph'>We use cookies and similar technologies to provide you with the best experience. We and our selected third parties store cookies and other similar technologies to process your personal data, including analytics cookies to understand and improve your browsing experience and advertising cookies to deliver personalised advertisements to you. You can find out more details about cookies used by us and selected third parties in our <a href='/${UtilityPageSlugs.cookiePolicy}'>cookies and privacy policies</a>. To modify or opt-out of the use of all or some of our cookies, please go to “Cookie settings”. By clicking “Allow all cookies”, you consent to the use of these cookies.</div>" 
            }
        };`,
      }}
    />
    <script
      type="text/javascript"
      src="//cdn.iubenda.com/cs/iubenda_cs.js"
      async
    />
    <style
      dangerouslySetInnerHTML={{
        __html: /* css */ `
                .iubenda-cs-default {
                    position:fixed;
                    width: 100%;
                    background-color: var(--white);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    z-index: 99999;
                    padding: 2rem;
                    ${getFontStyles(FONT_STYLE_SOFIA_11_400)};
                }
                .iubenda-cs-default button {
                    ${getFontStyles(FONT_STYLE_SOFIA_16_400)};
                    border: none;
                    background: var(--white);
                    border: 1px solid var(--darkGrey1);
                    padding: 0.8rem 3.5rem 1.4rem 3.5rem;
                    width: 100%;
                    cursor: pointer;
                    margin: 0;
                }
               .iubenda-cs-visible {
                   display:block;
                } 
                .iubenda-cs-paragraph {
                    margin-bottom: 2rem;
                }
                .iubenda-cs-paragraph a {
                    color: inherit;
                }
                .iubenda-cs-opt-group {
                    display:flex;
                    flex-direction: column;
                }
                .iubenda-cs-opt-group-custom {
                    order: 2;
                }
                .iubenda-cs-opt-group-consent {
                    order: 1;
                    display:flex;
                    flex-direction: column;
                }
                .iubenda-cs-default .iubenda-cs-reject-btn {
                    order: 2;
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
                .iubenda-cs-default .iubenda-cs-accept-btn {
                    order: 1;
                    background-color: var(--darkGrey1);
                    color: var(--white);
                }

                #purposes-container h1.purposes-header-title {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 500 !important;
                    font-size: 4rem !important;
                    line-height: 4.8rem !important;
                    margin-bottom: 2rem !important;
                }

                #purposes-container p.purposes-header-text, #purposes-container p.purposes-header-text button {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.6rem !important;
                    line-height: 2.6rem !important;
                }

                #purposes-container .purposes-body .purposes-buttons button {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.6rem !important;
                    line-height: 2.6rem !important;
                    border: none !important;
                    background: var(--white) !important;
                    border: 1px solid var(--darkGrey1) !important;
                    padding: 0.8rem 3.5rem 1.4rem 3.5rem !important;
                    width: 100% !important;
                    cursor: pointer !important;
                    border-radius: 0px !important;
                }

                #purposes-container div.purposes-btn-stroked, 
                #purposes-container button.purposes-btn-stroked, 
                #iubenda-iframe .purposes-top-right div.purposes-btn-cp,
                #iubenda-iframe .purposes-top-right button.purposes-btn-cp {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.6rem !important;
                    line-height: 2.6rem !important;
                    border: none !important;
                    background: var(--white) !important;
                    border: 1px solid var(--darkGrey1) !important;
                    padding: 0.8rem 3.5rem 1.4rem 3.5rem !important;
                    cursor: pointer !important;
                    border-radius: 0px !important;
                }

                #iubenda-iframe .purposes-top-right div.purposes-btn-cp > span,
                #iubenda-iframe .purposes-top-right button.purposes-btn-cp > span,
                #purposes-container button.purposes-btn-stroked > span {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.6rem !important;
                    line-height: 2.6rem !important; 
                }
                
                #iubenda-iframe .purposes-top-right button.purposes-btn-cp,
                #purposes-container button.purposes-btn-stroked {
                    padding: 0.8rem 2.5rem 1.4rem 2.5rem !important
                }

                #purposes-container div.purposes-btn-stroked > svg,
                #purposes-container button.purposes-btn-stroked > svg, 
                #iubenda-iframe .purposes-top-right div.purposes-btn-cp > svg,
                #iubenda-iframe .purposes-top-right button.purposes-btn-cp > svg {
                    display: none !important;
                }

                #purposes-container .purposes-body .purposes-item .purposes-item-title label {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 500 !important;
                    font-size: 1.8rem !important;
                    line-height: 2.4rem !important;
                }

                #purposes-container .purposes-body .purposes-item button.purposes-item-title-btn, 
                #purposes-container .purposes-body .purposes-item div.purposes-item-body {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.4rem !important;
                    line-height: 2rem !important;
                }

                #iubenda-iframe #iubFooterBtnContainer button#iubFooterBtn {
                    font-family: sofia-pro, Helvetica, Ariel, sans-serif !important;
                    font-weight: 400 !important;
                    font-size: 1.6rem !important;
                    line-height: 2.6rem !important;
                    border: none !important;
                    background: var(--white) !important;
                    border: 1px solid var(--darkGrey1) !important;
                    padding: 0.8rem 3.5rem 1.4rem 3.5rem !important;
                    width: 100% !important;
                    cursor: pointer !important;
                    border-radius: 0px !important;
                    background-image: unset !important;
                    background-color: var(--darkGrey1) !important;
                    color: var(--white) !important;
                    margin: 0 !important;
                }

                #iubenda-iframe #iubFooterBtnContainer button#iubFooterBtn:hover, #iubenda-iframe #iubFooterBtnContainer button#iubFooterBtn:focus {
                    opacity: 1 !important;
                }

                #iubenda-iframe div.iubenda-iframe-footer {
                    box-shadow: none !important;
                    border: none !important;
                    border-radius: none !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-accept {
                    background-image: unset !important;
                    background-color: var(--darkGrey1) !important;
                    color: var(--white) !important;
                    margin: 0 !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-accept:before {
                    display: none !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-accept:hover, #purposes-container .purposes-body .purposes-buttons button.purposes-btn-accept:focus {
                    opacity: 1 !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-reject {
                    background-image: unset !important;
                    background-color: var(--white) !important;
                    color: var(--darkGrey1) !important;
                    margin: 0 !important;
                    margin-bottom: 2rem !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-reject:before {
                    display: none !important;
                }

                #purposes-container .purposes-body .purposes-buttons button.purposes-btn-reject:hover, #purposes-container .purposes-body .purposes-buttons button.purposes-btn-reject:focus {
                    opacity: 1 !important;
                }

                #purposes-container .purposes-body .purposes-buttons {
                    flex-direction: column !important;
                }

                #purposes-container .purposes-body .purposes-buttons > div {
                    display: block !important;
                    width: 100% !important;
                }

                @media(min-width: 799px){
                    #purposes-container .purposes-body .purposes-buttons {
                        flex-direction: row !important;
                        justify-content: flex-start !important;
                    }

                    #purposes-container .purposes-body .purposes-buttons button.purposes-btn-reject {
                        margin: 0 !important;
                    }

                    #purposes-container .purposes-body .purposes-buttons > div {
                        width: 21rem !important;
                        margin-right: 2rem !important;
                    }
                }  
                
                ${MEDIA_QUERIES.desktopUp}{
                    .iubenda-cs-rationale {
                         display: flex;
                         justify-content: space-between;
                         align-items:center;
                    }
                    .iubenda-cs-paragraph {
                        margin-bottom: 0;
                        margin-right: 2rem;
                        max-width: 80rem;
                    }
                    .iubenda-cs-opt-group {
                        flex-direction: row;
                        gap: 2rem;
                        align-items: center;    
                    }
                    .iubenda-cs-opt-group-consent {
                        flex-direction: row;
                        gap: 2rem;
                    }
                    .iubenda-cs-default .iubenda-cs-reject-btn {
                        margin: 0;
                    }
                    .iubenda-cs-default button  {
                        min-width: 21rem;
                    }

                    #purposes-container h1.purposes-header-title {
                        font-size: 4.8rem !important;
                        line-height: 5.6rem !important;
                    }
                }
            `,
      }}
    ></style>
  </>
)
