import Script from 'next/script'

export const TrackingKlarna = () => {
  if (!process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID) {
    return null
  }

  return (
    <Script
      async
      data-environment={
        process.env.NODE_ENV === 'production' ? 'production' : 'playground'
      }
      src="https://osm.klarnaservices.com/lib.js"
      data-client-id={process.env.NEXT_PUBLIC_KLARNA_CLIENT_ID}
    />
  )
}
