export const uniqueOnly = <T>(value: T, index: number, self: T[]) =>
  self.indexOf(value) === index

export const chunkArray = <TItem>(
  array: TItem[],
  chunkSize: number
): TItem[][] =>
  Array(Math.ceil(array.length / chunkSize))
    .fill(null)
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize))
    .filter((chunk) => chunk.filter((item) => Boolean(item)))

export const findLastNonNullValue = <TValue>(
  items: Array<TValue | null | undefined>,
  currentIndex: number
): TValue => {
  const sliced = items.slice(0, currentIndex)
  return (
    sliced.filter((val) => val !== null && val !== undefined) as TValue[]
  ).pop() as TValue
}
