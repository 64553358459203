export const fonts = {
  primary: 'font-family: sofia-pro',
}

export const FONT_FAMILIES = [
  {
    name: 'sofia',
    value: 'sofia-pro, Helvetica, Ariel, sans-serif',
  },
]

// body - small
export const FONT_STYLE_SOFIA_11_400 = 'FONT_STYLE_SOFIA_11_400'
export const FONT_STYLE_SOFIA_11_500 = 'FONT_STYLE_SOFIA_11_500'
export const FONT_STYLE_SOFIA_11_600 = 'FONT_STYLE_SOFIA_11_600'

// body - medium
export const FONT_STYLE_SOFIA_14_400 = 'FONT_STYLE_SOFIA_14_400'
export const FONT_STYLE_SOFIA_14_500 = 'FONT_STYLE_SOFIA_14_500'
export const FONT_STYLE_SOFIA_14_600 = 'FONT_STYLE_SOFIA_14_600'

// body - large
export const FONT_STYLE_SOFIA_16_400 = 'FONT_STYLE_SOFIA_16_400'
export const FONT_STYLE_SOFIA_16_500 = 'FONT_STYLE_SOFIA_16_500'
export const FONT_STYLE_SOFIA_16_600 = 'FONT_STYLE_SOFIA_16_600'

/**
 * Heading XS reg
 */
export const FONT_STYLE_SOFIA_18_400 = 'FONT_STYLE_SOFIA_18_400'
/**
 * Heading XS Med
 */
export const FONT_STYLE_SOFIA_18_500 = 'FONT_STYLE_SOFIA_18_500'

/**
 * Heading S Reg
 */
export const FONT_STYLE_SOFIA_22_400 = 'FONT_STYLE_SOFIA_22_400'

/**
 * Heading S Med
 */
export const FONT_STYLE_SOFIA_22_500 = 'FONT_STYLE_SOFIA_22_500'

// heading m
export const FONT_STYLE_SOFIA_28_400 = 'FONT_STYLE_SOFIA_28_400'
export const FONT_STYLE_SOFIA_28_500 = 'FONT_STYLE_SOFIA_28_500'

// heading l
export const FONT_STYLE_SOFIA_32_400 = 'FONT_STYLE_SOFIA_32_400'
export const FONT_STYLE_SOFIA_32_500 = 'FONT_STYLE_SOFIA_32_500'

// heading xl
export const FONT_STYLE_SOFIA_40_400 = 'FONT_STYLE_SOFIA_40_400'
export const FONT_STYLE_SOFIA_40_500 = 'FONT_STYLE_SOFIA_40_500'

/**
 * Heading XXL Reg
 */
export const FONT_STYLE_SOFIA_48_400 = 'FONT_STYLE_SOFIA_48_400'
/**
 * Heading XXL Med
 */
export const FONT_STYLE_SOFIA_48_500 = 'FONT_STYLE_SOFIA_48_500'
