import {
  useRef,
  KeyboardEvent,
  MouseEvent,
  ChangeEvent,
  useEffect,
} from 'react'
import styled from 'styled-components'

import { styleVisuallyHidden } from '@cellulargoods/styles'

const HANDLE_WIDTH = 8
const SCRUBBER_MIN = 0
const SCRUBBER_MAX = 1

type VideoScrubberProps = {
  value: number
  onScrubberDrag: (value: number) => void
  onRangeKeyDown: () => void
}

export const VideoScrubber = ({
  value,
  onScrubberDrag,
  onRangeKeyDown,
}: VideoScrubberProps) => {
  const isDragging = useRef(false)
  const scrubberRef = useRef<HTMLDivElement>(null!)
  const scrubberInputRef = useRef<HTMLInputElement>(null!)
  const scrubberHandleRef = useRef<HTMLSpanElement>(null!)
  const scrubberTrailRef = useRef<HTMLSpanElement>(null!)

  useEffect(() => {
    let x = 0
    if (scrubberRef.current) {
      const { width } = scrubberRef.current.getBoundingClientRect()
      const desiredX = value * (width - HANDLE_WIDTH / 2)

      x = desiredX
    }

    scrubberTrailRef.current.style.width = `${x}px`
    scrubberHandleRef.current.style.left = `${x}px`
  }, [value])

  const handleRangeChange = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    if (onScrubberDrag) {
      onScrubberDrag(Number(currentTarget.value))
    }
  }

  const handleRangeKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Space' && onRangeKeyDown) {
      onRangeKeyDown()
    }
  }

  const handleStartDrag = () => {
    if (!isDragging.current) {
      scrubberInputRef.current.focus()
      isDragging.current = true
    }
  }

  const handleEndDrag = () => {
    if (isDragging.current) {
      isDragging.current = false
    }
  }

  const handleDrag = (clicked: boolean) => (e: MouseEvent) => {
    if ((scrubberRef.current && isDragging.current) || clicked) {
      const { left, width } = scrubberRef.current.getBoundingClientRect()
      const { pageX } = e
      const cursorXfromWindow = pageX - window.pageXOffset

      const newSliderPos =
        (cursorXfromWindow - left) / (width - HANDLE_WIDTH / 2)

      if (onScrubberDrag && newSliderPos > SCRUBBER_MAX) {
        onScrubberDrag(SCRUBBER_MAX)
      } else if (onScrubberDrag && newSliderPos < SCRUBBER_MIN) {
        onScrubberDrag(SCRUBBER_MIN)
      } else if (onScrubberDrag) {
        onScrubberDrag(newSliderPos)
      }
    }
  }

  const handleScrubberClick = (e: MouseEvent<HTMLDivElement>) => {
    scrubberInputRef.current.focus()
    handleDrag(true)(e)
  }

  return (
    <ControlScrubber
      onPointerUp={handleEndDrag}
      onPointerMove={handleDrag(false)}
      onPointerLeave={handleEndDrag}
      onClick={handleScrubberClick}
      ref={scrubberRef}
    >
      <ScrubberBar />
      <ScrubberTrail ref={scrubberTrailRef} />
      <ScrubberRange
        ref={scrubberInputRef}
        aria-label="Video scrubber"
        type="range"
        min={SCRUBBER_MIN}
        max={SCRUBBER_MAX}
        step="0.01"
        onChange={handleRangeChange}
        onKeyDown={handleRangeKeyDown}
        value={value}
      />
      <ScrubberHandle
        ref={scrubberHandleRef}
        onPointerMove={handleDrag(false)}
        onPointerUp={handleEndDrag}
        onPointerDown={handleStartDrag}
      />
    </ControlScrubber>
  )
}

const ControlScrubber = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ScrubberBar = styled.span`
  display: block;
  width: 100%;
  height: 0.2rem;
  background-color: var(--white);
  border-radius: 0.1rem;
  opacity: 0.3;
`

const ScrubberTrail = styled.span`
  display: block;
  height: 0.2rem;
  background-color: var(--white);
  border-radius: 0.1rem;
  position: absolute;
  pointer-events: none;
`

const ScrubberHandle = styled.span`
  position: absolute;
  display: block;
  top: calc(50% - 0.4rem);
  width: ${HANDLE_WIDTH / 10}rem;
  height: ${HANDLE_WIDTH / 10}rem;
  border-radius: 50%;
  background-color: var(--white);
  transition: transform 150ms;
`

const ScrubberRange = styled.input`
  ${styleVisuallyHidden}

  &:focus {
    outline: none;

    & + ${ScrubberHandle} {
      transform: scale(2);
    }
  }
`
