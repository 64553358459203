import { useEffect } from 'react'
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export const useKeyPress = (key: string, action: () => void) => {
  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === key) action()
    }

    window.addEventListener('keyup', onKeyUp)

    return () => window.removeEventListener('keyup', onKeyUp)
  })
}
