import { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { animated, config, useSprings } from '@react-spring/web'
import { Sanity } from '@cellulargoods/types'

import { Media } from '../Media'
import { Text, Heading } from '../Text'
import { Button, ButtonTypes } from '../Button'

import {
  FONT_STYLE_SOFIA_32_500,
  aspectRatio,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { createArticleLink } from '../../helpers/links'

import { HighlighterSnippet } from './HighlighterSnippet'

export type HighlighterArticlesProps = Sanity.Keyed<Sanity.ArticleHighlighter>

const FADE_DURATION = 5000

export const HighlighterArticles = ({ articles }: HighlighterArticlesProps) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const prevIndex = useRef<number | null>(0)
  const textRef = useRef<HTMLDivElement>(null!)

  /**
   * With GROQ we can probably write this a lot better.
   */
  const { media, snippets, content } = useMemo(() => {
    const media = (articles || []).flatMap(
      (article) => article?.reference?.ArticleCardInfo?.media ?? []
    )

    const content = (articles || []).map((article) => ({
      _key: article._key,
      title: article?.reference?.title ?? '',
      excerpt: article?.reference?.ArticleCardInfo?.excerpt ?? '',
      hrefProps: createArticleLink({
        slug: (article?.reference as Sanity.InternalArticle).slug,
        tagSlug: article?.reference?.Tags && article.reference.Tags[0]?.slug,
        link: (article?.reference as Sanity.ExternalArticle).link,
        file: (article?.reference as Sanity.ExternalArticle).file,
      }),
    }))

    const snippets = (articles || []).map((article) => ({
      title: article?.reference?.title ?? '',
      tag:
        (article?.reference?.Tags && article.reference.Tags[0]?.title) ?? null,
    }))

    return {
      media,
      content,
      snippets,
    }
  }, [articles])

  const timer = useRef<number | null>(null)

  const initInterval = useCallback(() => {
    if (!articles) return null
    setActiveIndex((s) => {
      prevIndex.current = s
      return articles.length - 1 === s ? 0 : s + 1
    })
  }, [articles])

  const handleHightlightChange = (index: number) => () => {
    if (index !== activeIndex) {
      timer.current && clearTimeout(timer.current)
      prevIndex.current = activeIndex
      setActiveIndex(index)
    }
  }

  const [springs, api] = useSprings(
    snippets.length,
    (i) => ({
      opacity: i === activeIndex ? 1 : 0,
      pointerEvents: i === activeIndex ? 'auto' : 'none',
      clipPath:
        i === activeIndex
          ? `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)`
          : `polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)`,
      config: config.slow,
    }),
    [articles]
  )

  useEffect(() => {
    const transitionOutThenIn = async () => {
      // fade the old out
      await Promise.all(
        api.start((i) => ({
          opacity: 0,
          pointerEvents: 'none',
          clipPath:
            i === prevIndex.current
              ? `polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%)`
              : `polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)`,
        }))
      )
      // fade the new in
      await Promise.all(
        api.start((i) => ({
          opacity: i === activeIndex ? 1 : 0,
          pointerEvents: i === activeIndex ? 'auto' : 'none',
          clipPath:
            i === activeIndex
              ? `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)`
              : `polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)`,
          onRest: () => {
            if (i === 0) {
              timer.current = window.setTimeout(initInterval, FADE_DURATION)
            }
          },
        }))
      )
    }

    if (prevIndex.current !== activeIndex) {
      transitionOutThenIn()
    }
  }, [activeIndex, api, initInterval])

  useEffect(() => {
    const handleResize = () => {
      const children = Array.from(textRef.current.children)

      if (children) {
        const childrenHeights = children.map(
          (child) => child.getBoundingClientRect().height
        )
        textRef.current.style.height = `${Math.max(...childrenHeights)}px`
      }
    }

    handleResize()

    window.setTimeout(initInterval, FADE_DURATION)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [initInterval])

  /**
   * on Unmount clear the timeout
   */
  useEffect(
    () => () => {
      timer.current && window.clearTimeout(timer.current)
    },
    []
  )

  return (
    <Highlighter>
      <HighlighterTop>
        <HighlighterMedia>
          {springs.map(({ clipPath }, i) => (
            <HighlighterMediaAnimator key={media[i]._key} style={{ clipPath }}>
              <Media {...media[i]} sizes={['100vw', null, null, '50vw']} />
            </HighlighterMediaAnimator>
          ))}
        </HighlighterMedia>
        <HighlighterContent ref={textRef}>
          {springs.map(({ opacity, pointerEvents }, i) => (
            <HighlighterAnimator
              key={content[i]._key}
              style={{
                opacity,
                // @ts-expect-error this appends SpringValue to un-animatable springs, it should be fixed upstream..
                pointerEvents,
              }}
            >
              <HighlighterTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
                {content[i].title}
              </HighlighterTitle>
              {content[i].excerpt ? (
                <HighlighterExcerpt>{content[i].excerpt}</HighlighterExcerpt>
              ) : null}
              <HighlighterCta
                variant={ButtonTypes.SECONDARY}
                tag="a"
                href={content[i].hrefProps.hrefTo}
              >
                Read More
              </HighlighterCta>
            </HighlighterAnimator>
          ))}
        </HighlighterContent>
      </HighlighterTop>
      <HighlighterList>
        {snippets.map((snip, i) => (
          <HighlighterItem key={i}>
            <HighlighterButton
              type="button"
              aria-label={`Change view to ${snip.title}`}
              onClick={handleHightlightChange(i)}
            >
              <HighlighterSnippetText
                active={activeIndex === i}
                title={snip.title}
                tag={snip.tag}
              />
            </HighlighterButton>
          </HighlighterItem>
        ))}
      </HighlighterList>
    </Highlighter>
  )
}

const Highlighter = styled.section`
  padding: 4rem 2rem;
  background-color: var(--softGrey);

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 4rem 5.7rem 4rem;
  }
`

const HighlighterTop = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8.4rem;
  }
`

const HighlighterMedia = styled.div`
  ${aspectRatio(335, 300, false)}

  ${MEDIA_QUERIES.desktopUp} {
    ${aspectRatio(670, 487, false)}
    order: 2;
    flex-basis: calc(50% - 1rem);
  }
`

const HighlighterMediaAnimator = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const HighlighterContent = styled.div`
  margin: 4rem 0 5rem 0;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    order: 1;
    padding: 0;
    margin-top: 6rem;
    flex-basis: 37.5%;
  }
`

const HighlighterAnimator = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
`

const HighlighterTitle = styled(Heading)``

const HighlighterExcerpt = styled(Text)`
  margin-top: 2rem;
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: block;
  }
`

const HighlighterCta = styled(Button)`
  margin-top: 3rem;
`

const HighlighterList = styled.ul`
  display: flex;
  margin: 0 -1rem;

  ${MEDIA_QUERIES.desktopUp} {
  }
`

const HighlighterItem = styled.li`
  flex: 0 1 calc(25% - 2rem);
  margin: 0 1rem;
  ${MEDIA_QUERIES.desktopUp} {
  }
`

const HighlighterButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
`

const HighlighterSnippetText = styled(HighlighterSnippet)`
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
  }
`
