import {
  Pause,
  Play,
  SpeakerSimpleHigh,
  SpeakerSimpleLow,
  SpeakerSimpleX,
} from 'phosphor-react'
import styled from 'styled-components'

import { FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'

import { Text } from '../Text'
import { IconButton } from '../Button'

import { formatMMSS } from '../../helpers/time'

import ExpandIcon from '../../assets/expand-video.svg'

import { VideoScrubber } from './VideoScrubber'

type VideoControlsProps = {
  isPlaying: boolean
  onPlayPauseClick: (newPlayingState: boolean) => void
  currentTime: number
  scrubberValue: number
  onScrubberDrag: (value: number) => void
  expanded: boolean
  onScreenSizeClick: (newExpandedState: boolean) => void
  muted: boolean
  volume: number
  onVolumeClick: (newVolumeState: boolean) => void
  onVolumeScrubberDrag: (value: number) => void
  showVolume?: boolean
}

export const VideoControls = ({
  isPlaying,
  onPlayPauseClick,
  currentTime,
  scrubberValue,
  onScrubberDrag,
  expanded,
  onScreenSizeClick,
  muted,
  volume,
  onVolumeClick,
  onVolumeScrubberDrag,
  showVolume = true,
}: VideoControlsProps) => {
  const handlePlayPauseClick = () => {
    if (onPlayPauseClick) {
      onPlayPauseClick(!isPlaying)
    }
  }

  const handleScreenSizeOnClick = () => {
    if (onScreenSizeClick) {
      onScreenSizeClick(!expanded)
    }
  }

  const handleVolumeClick = () => {
    if (onVolumeClick) {
      onVolumeClick(!muted)
    }
  }

  const timestamp = formatMMSS(currentTime)

  return (
    <ControlBar>
      <ControlBarLeft>
        <PlayPauseButton
          onClick={handlePlayPauseClick}
          ariaLabel={isPlaying ? 'Pause video' : 'Play video'}
        >
          {isPlaying ? (
            <Pause size={16} color="var(--white)" weight="fill" />
          ) : (
            <Play size={16} color="var(--white)" weight="fill" />
          )}
        </PlayPauseButton>
        <Timestamp fontStyle={FONT_STYLE_SOFIA_11_400}>{timestamp}</Timestamp>
      </ControlBarLeft>
      <ControlBarCenter>
        <VideoScrubber
          onRangeKeyDown={handlePlayPauseClick}
          onScrubberDrag={onScrubberDrag}
          value={scrubberValue}
        />
      </ControlBarCenter>
      <ControlBarRight>
        {showVolume ? (
          <>
            <VolumeButton
              onClick={handleVolumeClick}
              ariaLabel={muted ? 'Turn on volume' : 'Mute video'}
            >
              {muted ? (
                <SpeakerSimpleX size={16} color="var(--white)" />
              ) : volume < 0.5 ? (
                <SpeakerSimpleLow size={16} color="var(--white)" />
              ) : (
                <SpeakerSimpleHigh size={16} color="var(--white)" />
              )}
            </VolumeButton>
            <VolumeScubber>
              <VideoScrubber
                value={muted ? 0 : volume}
                onRangeKeyDown={handleVolumeClick}
                onScrubberDrag={onVolumeScrubberDrag}
              />
            </VolumeScubber>
          </>
        ) : null}
        <ExpandButton
          ariaLabel={expanded ? 'Expand' : 'Minimize'}
          onClick={handleScreenSizeOnClick}
        >
          <ExpandIcon width={16} height={16} fill="var(--white)" />
        </ExpandButton>
      </ControlBarRight>
    </ControlBar>
  )
}

const ControlBar = styled.div`
  height: 4.6rem;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.0086472) 8.99%,
    rgba(0, 0, 0, 0.03551) 17.97%,
    rgba(0, 0, 0, 0.0816599) 26.96%,
    rgba(0, 0, 0, 0.147411) 35.94%,
    rgba(0, 0, 0, 0.231775) 44.93%,
    rgba(0, 0, 0, 0.331884) 53.91%,
    rgba(0, 0, 0, 0.442691) 62.9%,
    rgba(0, 0, 0, 0.557309) 71.88%,
    rgba(0, 0, 0, 0.668116) 80.87%,
    rgba(0, 0, 0, 0.768225) 89.86%,
    rgba(0, 0, 0, 0.852589) 98.84%,
    rgba(0, 0, 0, 0.91834) 107.83%,
    rgba(0, 0, 0, 0.96449) 116.81%,
    rgba(0, 0, 0, 0.991353) 125.8%,
    #000000 134.78%
  );

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.6rem;
`

const ControlBarLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PlayPauseButton = styled(IconButton)`
  padding: 0.4rem;
`

const Timestamp = styled(Text)`
  margin: 0 1.2rem;
  color: var(--white);
`

const ControlBarCenter = styled.div`
  height: 100%;
  flex: 1;
`

const ControlBarRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const VolumeButton = styled(IconButton)`
  padding: 0.4rem;
  margin-left: 0.8rem;
`

const VolumeScubber = styled.div`
  width: 10rem;
  margin-left: 0.8rem;
`

const ExpandButton = styled(IconButton)`
  padding: 0.4rem;
  margin-left: 0.8rem;
`
