import Link from 'next/link'
import styled from 'styled-components'
import {
  useState,
  useMemo,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react'
import { animated, useSprings } from '@react-spring/web'
import { DropdownCurrencySwitcher } from './HeaderCurrencySwitcher'
import { MagnifyingGlass, User } from 'phosphor-react'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import mergeRefs from 'react-merge-refs'

import Logo from '../../../assets/logo.svg'

import {
  aspectRatio,
  FONT_STYLE_SOFIA_16_400,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { useMouseOutside } from '@cellulargoods/hooks'
import { Sanity } from '@cellulargoods/types'
import { Media } from '../../Media'
import { ButtonTypes, Button } from '../../Button'
import { PortalGeneric } from '../../Portals/PortalChildren'
import { CartNumber } from './Ornaments/CartNumber'
import { NavLink, NavLinkIconWrap } from './Links/NavLink'
import { useHeaderState } from './HeaderContext'
import clsx from 'clsx'

export type DesktopNavBarProps = {
  menu?: Sanity.NavigationQueryResponse['navbar'] | null
  onCartClick?: () => void
  onAccountClick?: () => void
  onSearchClick?: () => void
  onMenuRest?: () => void
  cartQuantity?: number
  showRightSide?: boolean
}

export const DesktopNavBar = ({
  menu,
  onCartClick,
  onAccountClick,
  onSearchClick,
  cartQuantity,
  showRightSide = true,
  onMenuRest,
}: DesktopNavBarProps) => {
  const [activeStack, setActiveStack] = useState<number | null>(null)
  const menuRefs = useRef<HTMLUListElement[]>([])

  const [linksRef, linksBounds] = useMeasure({
    polyfill: ResizeObserver,
  })
  const headerDetectRef = useRef<HTMLUListElement>(null!)
  const prevStack = useRef<number | null>(null)

  const [{ theme, bannerHeight, headerHeight }, setHeaderState] =
    useHeaderState()
  const headerTotalHeight = bannerHeight + headerHeight

  const handleNavPointerOver = (key?: false | number) => () => {
    prevStack.current = activeStack

    if (prevStack.current === key) {
      return
    }

    if ((key || key === 0) && showRightSide) {
      setActiveStack(key)
    } else if (activeStack !== null) {
      setActiveStack(null)
    }
  }

  const handleCartClick = () => {
    if (onCartClick) {
      onCartClick()
    }
  }

  const subMenuCount = useMemo(
    () =>
      (menu ?? []).filter((item) => (item.children ?? []).length > 0).length,
    [menu]
  )

  const [springs, api] = useSprings(
    subMenuCount,
    () => ({
      from: {
        y: -1000,
      },
      config: {
        tension: 285,
        friction: 30,
      },
    }),
    [menu]
  )

  useEffect(() => {
    const { current: menus } = menuRefs

    const [base] = menus

    if (base) {
      const { height } = base.getBoundingClientRect()

      const hasMenu =
        typeof activeStack === 'number' && activeStack < api.current.length

      // we're opening the nav
      if (prevStack.current === null) {
        api.start((i) => ({
          to: {
            y: i === activeStack ? headerTotalHeight : -height,
          },
          onRest: () => {
            if (activeStack === null && onMenuRest) {
              onMenuRest()
            }
          },
        }))
      } else if (activeStack === null || !hasMenu) {
        // we're closing the nav
        api.start(() => ({
          to: {
            y: -height,
          },
          onRest: () => {
            if (activeStack === null && onMenuRest) {
              onMenuRest()
            } else if (!hasMenu) {
              setHeaderState((s) => ({ ...s, desktopMenuOpen: false }))
            }
          },
        }))
      } else {
        api.start((i) => {
          if (i === activeStack) {
            return {
              immediate: true,
              to: {
                y: headerTotalHeight,
              },
              onRest: () => {
                if (prevStack.current !== null) {
                  api.start((i) => {
                    if (i === prevStack.current) {
                      return {
                        immediate: true,
                        to: {
                          y: -height,
                        },
                      }
                    } else {
                      return {}
                    }
                  })
                }
              },
            }
          } else {
            return {}
          }
        })
      }
    }
  }, [activeStack, api, headerTotalHeight, onMenuRest])

  useEffect(() => {
    setHeaderState((s) => ({
      ...s,
      desktopMenuOpen: activeStack !== null && activeStack < api.current.length,
    }))
  }, [activeStack, api])

  /**
   * This runs initially to place the menus where they should be.
   */
  useEffect(() => {
    const { current: menus } = menuRefs

    const [base] = menus

    if (base) {
      const { height } = base.getBoundingClientRect()

      api.set({
        y: -height,
      })
    }
  }, [api, linksBounds])

  useMouseOutside(
    {
      current: [...menuRefs.current, headerDetectRef.current!],
    },
    () => {
      if (activeStack !== null) {
        setActiveStack(null)
      }
    }
  )

  const handleAnchorClick = () => {
    setActiveStack(null)
  }

  const handleSearchClick = () => {
    if (onSearchClick) {
      onSearchClick()
    }
  }

  const handleAccountClick = () => {
    if (onAccountClick) {
      onAccountClick()
    }
  }

  const [maxWidth, setMaxWidth] = useState<number | null>(null)

  const indexOfSubmenuWithMostChildren = useMemo(() => {
    const [submenuWithMostChildren] = [...(menu ?? [])].sort(
      (a, b) => (b.children?.length ?? 0) - (a.children?.length ?? 0)
    )

    return (menu ?? []).findIndex(
      (item) => item._key === submenuWithMostChildren._key
    )
  }, [menu])

  return (
    <DesktopNavList ref={mergeRefs([headerDetectRef])}>
      <li>
        <Link href="/" passHref>
          <a onPointerOver={handleNavPointerOver()}>
            <HeaderLogo
              fill={theme === 'black' ? 'var(--black)' : 'var(--white)'}
            />
          </a>
        </Link>
      </li>
      <FlexLeft ref={linksRef}>
        {(menu ?? []).map((menuItem, index) => (
          <li key={menuItem._key}>
            <Link href={`${menuItem.link}`} passHref>
              <NavLink
                as="a"
                onPointerOver={handleNavPointerOver(
                  Boolean(menuItem.children) && index
                )}
                className={clsx(activeStack === index && 'hovered')}
                theme={theme}
              >
                <span>{menuItem.label}</span>
              </NavLink>
            </Link>
            <PortalGeneric id="header-portal">
              {Array.isArray(menuItem.children) &&
                menuItem.children.length > 0 && (
                  <SubMenuList
                    aria-hidden={activeStack !== index}
                    ref={(ref) =>
                      (menuRefs.current[index] = ref as HTMLUListElement)
                    }
                    style={{
                      ...springs[index],
                    }}
                  >
                    {menuItem.children.map((item) => (
                      <SubMenuItem key={item._key}>
                        <SubmenuItem
                          {...{
                            item,
                            handleAnchorClick,
                            maxWidth:
                              indexOfSubmenuWithMostChildren === index
                                ? 'auto'
                                : maxWidth,
                            setMaxWidth:
                              indexOfSubmenuWithMostChildren === index
                                ? setMaxWidth
                                : null,
                          }}
                        />
                      </SubMenuItem>
                    ))}
                  </SubMenuList>
                )}
            </PortalGeneric>
          </li>
        ))}
        <li>
          <AskCelLink
            href="https://chat.flexlabs.ai/cellular-goods"
            target="_blank"
            rel="noopener noreferrer"
            onPointerOver={handleNavPointerOver()}
          >
            Ask Cel
          </AskCelLink>
        </li>
      </FlexLeft>
      <Flex>
        {showRightSide && (
          <>
            <SelectLi>
              <DropdownCurrencySwitcher theme={theme} />
            </SelectLi>
            <li>
              <NavLink
                onClick={handleSearchClick}
                onPointerOver={handleNavPointerOver()}
                theme={theme}
                aria-label="Search"
              >
                <MagnifyingGlass size={26} weight="light" />
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={handleAccountClick}
                onPointerOver={handleNavPointerOver()}
                theme={theme}
                aria-label="Account"
              >
                <User size={26} weight="light" />
              </NavLink>
            </li>
            <li>
              <NavLinkIconWrap>
                <NavLink
                  onPointerOver={handleNavPointerOver()}
                  onClick={handleCartClick}
                  theme={theme}
                  aria-label="Bag"
                >
                  <CartNumber quantity={cartQuantity ?? 0} />
                </NavLink>
              </NavLinkIconWrap>
            </li>
          </>
        )}
      </Flex>
    </DesktopNavList>
  )
}

interface SubmenuItemProps {
  item: Sanity.NavBarChild
  handleAnchorClick: () => void
  maxWidth: number | null | string
  setMaxWidth: Dispatch<SetStateAction<number | null>> | null
}

const SubmenuItem = ({
  item,
  handleAnchorClick,
  maxWidth,
  setMaxWidth,
}: SubmenuItemProps) => {
  const [measureRef, { width }] = useMeasure({
    polyfill: ResizeObserver,
  })

  useEffect(() => {
    if (setMaxWidth && width && width > 0) {
      setMaxWidth(width)
    }
  }, [setMaxWidth, width])

  const renderContent = () => (
    <>
      <MediaContainer
        ref={measureRef}
        style={{ maxWidth: maxWidth ? `${maxWidth}px` : 'auto' }}
      >
        {item.comingSoon ? <ComingSoon>{`Coming Soon`}</ComingSoon> : null}
        {item.media ? <Media {...item.media} /> : null}
      </MediaContainer>
      <SubMenuLinkContainer $comingSoon={Boolean(item.comingSoon)}>
        <SubMenuLink variant={ButtonTypes.SECONDARY} tag="div">
          {item.label}
        </SubMenuLink>
      </SubMenuLinkContainer>
    </>
  )

  if (item.comingSoon) {
    return renderContent()
  }

  switch (item.linkType) {
    case 'external':
      return (
        <Anchor
          href={item.link ?? ''}
          rel="noopener noreferrer"
          target="_blank"
        >
          {renderContent()}
        </Anchor>
      )
    case 'internal':
      return (
        <Link href={item.link ?? ''} shallow={false}>
          <Anchor onClick={handleAnchorClick}>{renderContent()}</Anchor>
        </Link>
      )
  }
}

const DesktopNavList = styled(animated.ul)`
  display: none;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3.2rem;
    position: relative;
    z-index: 1;
  }
`

const HeaderLogo = styled(Logo)`
  width: 3.9rem;
  height: 2.6rem;
`

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
`

const FlexLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4.6rem;
  margin-left: 5.8rem;
  width: 100%;
`

const AskCelLink = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)};
  padding: 1rem 1.3rem 1.4rem 1.3rem;
  margin: 0;
  border: none;
  background-color: var(--white);
  min-width: 8rem;
  border-radius: 2.5rem;
  cursor: pointer;
  transition: background-color 300ms ease-out;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      background-color: var(--lightBrown);
      transition: background-color 300ms ease-out;
    }
  }
`

const SubMenuList = styled(animated.ul)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: var(--white);
  display: flex;
  border-bottom: solid 1px var(--black);
  z-index: 0;
  pointer-events: auto;
`

const SubMenuItem = styled.li`
  flex: 1;
  padding: 2.5rem 2.5rem 3rem;

  & + & {
    border-left: solid 1px var(--black);
  }
`

const MediaContainer = styled.div`
  ${aspectRatio(310, 204)};
  margin: 0 auto;
`

const SubMenuLinkContainer = styled.div<{ $comingSoon: boolean }>`
  display: flex;
  justify-content: center;
  pointer-events: ${(props) => (props.$comingSoon ? 'none' : 'auto')};
`

const SubMenuLink = styled(Button)`
  margin-top: 2.5rem;
  text-decoration: none;
`

const Anchor = styled.a`
  text-decoration: none;
  display: block;

  @media (hover: hover) {
    &:hover {
      ${SubMenuLink} span:before {
        right: unset;
        left: 0;
        width: 100%;
      }
    }
  }
`

const ComingSoon = styled.span`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  color: var(--electricBlue);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const SelectLi = styled.li`
  display: flex;
  align-items: center;
`
