import { X } from 'phosphor-react'

import { IconButton } from './IconButton'

interface CloseButtonProps {
  className?: string
  onClick: () => void
  size?: number
  color?: string
}

export const CloseBtn = ({
  className,
  onClick,
  size = 20,
  color = 'var(--black)',
}: CloseButtonProps) => {
  return (
    <IconButton className={className} ariaLabel="Close" onClick={onClick}>
      <X size={size} weight="thin" color={color} />
    </IconButton>
  )
}
