import { COLORS } from './colors'

export const CSS_GLOBAL = /* css */ `
  *,
  *:before,
  *:after {
    box-sizing: border-box; 
  }

  html {
    display: flex;
    scroll-behavior: smooth;
  }

  body {
    flex: 1;
    flex-basis: 100%;
  }

  html, body {
    font-size: 62.5%;
    font-family: Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* overflow: auto; */
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    min-height: 100%;
    color: var(--black);
  }

  :root {
    --white: ${COLORS.white};
    --black: ${COLORS.black};
    --accessibleGrey: ${COLORS.accessibleGrey};
    --lightGrey: ${COLORS.lightGrey};
    --lightGrey2: ${COLORS.lightGrey2};
    --softGrey: ${COLORS.softGrey};
    --midGrey: ${COLORS.midGrey};
    --darkGrey1: ${COLORS.darkGrey1};
    --darkGrey2: ${COLORS.darkGrey2};
    --electricGreen: ${COLORS.electricGreen};
    --lightBrown: ${COLORS.lightBrown};
    --electricBlue: ${COLORS.electricBlue};
    --lightElectricBlue: ${COLORS.lightElectricBlue};
    --darkElectricBlue: ${COLORS.darkElectricBlue};
    --validationError: ${COLORS.validationError};
    --validationSuccess: ${COLORS.validationSuccess};
    --lightBrown: ${COLORS.lightBrown};
    --header-height-mobile: 8rem;
    --header-height-tablet: 10.6rem;
  }

  button {
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a {
    color: inherit;
  }

  #__next {
    min-height: 100vh;
  }
`
