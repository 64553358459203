import { useMemo, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { Yotpo } from '@cellulargoods/types'

import { capitalizeFirstLetter } from '../../helpers/strings'

import { Text, Heading } from '../Text'

import {
  FONT_STYLE_SOFIA_14_500,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_18_500,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'
import { ReviewStarRating } from './ReviewStarRating'

export type ReviewsSnippetProps = Yotpo.Review

export const ReviewsSnippet = ({
  score,
  title,
  content,
  customFields,
  id,
  user,
  createdAt,
}: ReviewsSnippetProps) => {
  const [hasSubmittedVote, setHasSubmittedVote] = useState(false)

  const { name, location } = useMemo<{
    name?: string
    location?: string
  }>(() => {
    const customObjects = Object.values(customFields ?? {})
    return {
      name: user.name,
      location: customObjects.find((obj) => obj.title === 'Location')
        ?.value as string,
    }
  }, [customFields, user])

  const taggedProps = useMemo(
    () =>
      Object.values(customFields ?? {})
        .filter(
          (field) =>
            field.field_type === Yotpo.CustomFieldTypes.SingleChoice ||
            field.field_type === Yotpo.CustomFieldTypes.MultipleChoice ||
            field.field_type === Yotpo.CustomFieldTypes.Rating
        )
        .map(({ title, value }) => ({
          title,
          value,
        })),
    [customFields]
  )

  const handleInputClick = (value: string, reviewId: string) => async () => {
    setHasSubmittedVote(true)

    try {
      await axios('/api/reviews/vote', {
        method: 'POST',
        data: {
          reviewId,
          type: value === 'Yes' ? 'up' : 'down',
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Snippet>
      <SnippetLeft>
        {name ? (
          <Heading tag="h3" fontStyle={FONT_STYLE_SOFIA_16_500}>
            {capitalizeFirstLetter(name)}
          </Heading>
        ) : null}
        {createdAt ? (
          <SnippetLocation tag="h4" fontStyle={FONT_STYLE_SOFIA_16_400}>
            {createdAt}
          </SnippetLocation>
        ) : null}
        {location ? (
          <SnippetLocation tag="h4" fontStyle={FONT_STYLE_SOFIA_16_400}>
            {location}
          </SnippetLocation>
        ) : null}
        <SnippetTagList>
          {taggedProps.map(({ title, value }, index) => (
            <SnippetTagItem key={index}>
              <dt>{title}</dt>
              <dd>{value}</dd>
            </SnippetTagItem>
          ))}
        </SnippetTagList>
        <SnippetActions>
          <SnippetActionTitle>Helpful?</SnippetActionTitle>
          <SnippetActionButtons
            $visible={!hasSubmittedVote}
            disabled={hasSubmittedVote}
          >
            {['Yes', 'No'].map((val) => (
              <SnippetAction
                key={val}
                type="submit"
                onClick={handleInputClick(val, id.toString())}
              >
                <span>{val}</span>
              </SnippetAction>
            ))}
          </SnippetActionButtons>
          {hasSubmittedVote ? (
            <SnippetActionResponse fontStyle={FONT_STYLE_SOFIA_16_400}>
              {
                'Thank you for your feedback. We use it to continually improve our products and services.'
              }
            </SnippetActionResponse>
          ) : null}
        </SnippetActions>
      </SnippetLeft>
      <SnippetRight>
        <ReviewStarRating rating={score} />
        <SnippetTitle tag="h5" fontStyle={FONT_STYLE_SOFIA_18_500}>
          {title}
        </SnippetTitle>
        <SnippetText>{content}</SnippetText>
      </SnippetRight>
    </Snippet>
  )
}

const Snippet = styled.div`
  padding: 4rem 0;
  margin: 0 2rem;
  border-top: solid 1px rgba(0, 0, 0, 0.2);

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    align-items: flex-start;
    padding: 4rem 0 5rem 0;
    margin: 0 4rem;

    & > * {
      flex: 1 0 50%;
    }
  }
`

const SnippetLeft = styled.div``

const SnippetLocation = styled(Heading)`
  color: var(--accessibleGrey);
`

const SnippetTagList = styled.dl`
  margin: 0;
  margin-top: 2rem;
`

const SnippetTagItem = styled.span`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}

  display: flex;

  & > dt {
    color: var(--accessibleGrey);
  }

  & > dt::after {
    content: ': ';
  }

  & > dd {
    margin-left: 0.2em;
  }
`

const SnippetActions = styled.fieldset`
  margin: 0;
  margin-top: 2rem;
  border: none;
  padding: 0;
`

const SnippetActionTitle = styled.legend`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  padding: 0;
`

const SnippetActionButtons = styled.div<{
  disabled: boolean
  $visible: boolean
}>`
  margin-top: 1rem;
  display: ${(props) => (props.$visible ? 'block' : 'none')};

  ${(props) =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 0.2;
  `}
`

const SnippetAction = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  padding: 0.5rem 1.8rem;
  background-color: var(--white);
  border: solid 1px var(--black);
  cursor: pointer;
  position: relative;

  & > span {
    position: relative;
    bottom: 0.2rem;
  }

  & + & {
    margin-left: 1rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    background-color: var(--lightBrown);
    border-top: none;
    width: 100%;
    height: 0;
    transition: height 200ms ease-out;
  }

  @media (hover: hover) {
    &:hover:before {
      height: 100%;
    }
  }
`

const SnippetActionResponse = styled(Text)`
  max-width: 34rem;
  margin-top: 2rem;
`

const SnippetRight = styled.div`
  margin-top: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
  }
`

const SnippetTitle = styled(Heading)`
  margin-top: 3rem;
`

const SnippetText = styled(Text)`
  margin-top: 2rem;
`
