import { Sanity } from '@cellulargoods/types'
import Link from 'next/link'
import styled from 'styled-components'

import { Media } from '../Media'
import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'

import { createSlugFromQuery } from '../../helpers/links'

import { ContentHeaderText } from './ContentHeaderText'
import {
  aspectRatio,
  MEDIA_QUERIES,
  FONT_STYLE_SOFIA_22_500,
} from '@cellulargoods/styles'

export type ThreeLinkGridProps = Sanity.ThreeLinkGrid

export const ContentThreeLinkGrid = ({ header, items }: ThreeLinkGridProps) => {
  return (
    <ThreeGrid>
      {header ? <ThreeLinkHeader {...header} isSubmodule /> : null}
      {Array.isArray(items) ? (
        <ThreeLinkItems>
          {items.map((item) => (
            <ThreeLinkItem key={item._key}>{renderItem(item)}</ThreeLinkItem>
          ))}
        </ThreeLinkItems>
      ) : null}
    </ThreeGrid>
  )
}

const renderItem = (item: Sanity.ThreeLinkGridItem) => {
  const { link, media, title, copy } = item
  const renderInner = () => (
    <>
      <MediaContainer>{media ? <Media {...media[0]} /> : null}</MediaContainer>
      {title ? (
        <CardHeading tag="h3" fontStyle={FONT_STYLE_SOFIA_22_500}>
          {title}
        </CardHeading>
      ) : null}
      {copy ? <CardCopy>{copy}</CardCopy> : null}
      {link && link.link ? (
        <CardCta variant={ButtonTypes.SECONDARY}>{link.label}</CardCta>
      ) : null}
    </>
  )

  if (link && link.link && link.linkType === 'external') {
    return (
      <Anchor href={link.link} target="_blank" rel="noopener noreferrer">
        {renderInner()}
      </Anchor>
    )
  } else if (link && link.link && link.linkType === 'internal') {
    const actualLink = createSlugFromQuery(
      link.linkType,
      link.link,
      link.referenceType,
      link.category,
      true
    )
    return (
      <Link href={actualLink ?? ''} passHref>
        <Anchor>{renderInner()}</Anchor>
      </Link>
    )
  } else {
    return renderInner()
  }
}

const ThreeGrid = styled.section`
  padding: 0 2rem;
  position: relative;
  background-color: var(--lightGrey);

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem;
  }
`

const ThreeLinkHeader = styled(ContentHeaderText)`
  padding: 4rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 0;

    h2,
    h3,
    p {
      text-align: center;
    }
  }
`

const ThreeLinkItems = styled.ul`
  padding-bottom: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    gap: 2rem;
    padding-bottom: 5rem;
  }
`

const ThreeLinkItem = styled.li`
  & + & {
    margin-top: 4rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex: 1 1;
    & + & {
      margin-top: 0;
    }
  }
`

const CardCta = styled(Button)`
  margin-top: 3rem;
`

const Anchor = styled.a`
  display: block;
  position: relative;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      ${CardCta} span:before {
        right: unset;
        left: 0;
        width: 100%;
      }

      img {
        transform: scale(1.04);
      }
    }
  }
`

const MediaContainer = styled.div`
  ${aspectRatio(1, 1)}
  display: block;
  background-color: var(--softGrey);

  img {
    transition: transform 400ms ease-out;
  }
`

const CardHeading = styled(Heading)`
  margin-top: 2.5rem;
`

const CardCopy = styled(Text)`
  margin-top: 2rem;
`
