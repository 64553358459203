import { checkForHttps } from './links'

export const ensurePriceHasDecimals = (
  amount: number | string,
  addCurrency = true
) => {
  return new Intl.NumberFormat('en', {
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: addCurrency ? 'currency' : 'decimal',
  }).format(amount as number)
}

export const capitalizeFirstLetter = (str: string | null) => {
  if (!str) {
    return str
  } else {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
  }
}

export const seperateTextByLineBreaks = (string: string): string[] =>
  string.split('\n').filter((sample) => Boolean(sample))

export const slugify = (string: string) =>
  string.toString().toLowerCase().split(' ').join('-')

export const makeStringRelative = (str?: string): string => {
  if (!str) {
    return ''
  }

  const hasRelativeSlash = str.charAt(0) === '/'

  if (hasRelativeSlash || checkForHttps(str) || str.includes('mailto')) {
    return str
  } else {
    return `/${str}`
  }
}

export const getQueries = (str: string): Record<string, string> => {
  const [_, urlQuery] = str.split('?')

  if (!urlQuery) {
    return {}
  }

  const queries = urlQuery.split('&').reduce((acc, curr) => {
    const [key, value] = curr.split('=')
    acc[key] = value

    return acc
  }, {} as Record<string, string>)

  return queries
}
