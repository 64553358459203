import { Fragment, useEffect, useMemo, useState } from 'react'
import { X, ArrowElbowRightDown } from 'phosphor-react'
import styled from 'styled-components'
import Link from 'next/link'
import { animated, useSprings } from '@react-spring/web'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  MEDIA_QUERIES,
  getFontStyles,
  FONT_STYLE_SOFIA_14_500,
} from '@cellulargoods/styles'
import { useCurrency, useDisableScroll } from '@cellulargoods/hooks'
import { NonNullSkipArray, Sanity } from '@cellulargoods/types'

import { IconButton } from '../../Button'

import BackArrow from '../../../assets/backArrow.svg'

import SectionListTitle from './Sections/SectionListTitle'

type MobileSubNav = (Omit<
  NonNullSkipArray<Sanity.NavigationQueryResponse['mobileSubNav']>,
  'slug'
> & {
  href: string
  rel: string
  target: string
})[][]

interface MobileNavMenuProps {
  isOpen: boolean
  onCloseClick: () => void
  onAccountClick?: () => void
  onSearchClick?: () => void
  menu?: Sanity.NavigationQueryResponse['navbar'] | null
  subNav: MobileSubNav
  showRightSide?: boolean
}

const TOOLBAR_HEIGHT = 97

const MobileNavMenu = ({
  isOpen,
  onCloseClick,
  onAccountClick,
  onSearchClick,
  menu,
  subNav,
  showRightSide = true,
}: MobileNavMenuProps) => {
  const [activeStack, setActiveStack] = useState<number | null>(null)

  useDisableScroll(isOpen)

  const subMenuCount = useMemo(
    () => (menu ?? []).filter((item) => item.children).length,
    [menu]
  )

  const [springs, api] = useSprings(
    subMenuCount,
    () => ({
      from: {
        visibility: 'hidden',
        translateX: '100%',
      },
      config: {
        tension: 285,
        friction: 30,
      },
    }),
    [menu]
  )

  const [currency, setCurrency, currencyOptions] = useCurrency()

  useEffect(() => {
    api.set({
      visibility: 'visible',
    })

    api.start((i) => ({
      to: {
        translateX: i === activeStack ? '0%' : '100%',
      },
      onRest: () => {
        api.set({
          visibility: i === activeStack ? 'visible' : 'hidden',
        })
      },
    }))
  }, [activeStack, api])

  const handleNavClick = (menuIndex: number) => () => {
    setActiveStack(menuIndex)
  }

  const handleBackClick = () => {
    setActiveStack(null)
  }

  const handleCloseClick = () => {
    setActiveStack(null)

    if (onCloseClick) {
      onCloseClick()
    }
  }

  const handleAnchorClick = () => {
    setActiveStack(null)

    if (onCloseClick) {
      onCloseClick()
    }
  }

  const handleSearchClick = () => {
    if (onSearchClick) {
      onSearchClick()
    }
    handleCloseClick()
  }

  const handleAccountClick = () => {
    if (onAccountClick) {
      onAccountClick()
    }
  }

  return (
    <Wrap $isVisible={isOpen}>
      <TopBar>
        <BackButton
          ariaLabel="Go back"
          $isVisible={activeStack !== null}
          onClick={handleBackClick}
        >
          <BackArrow size={32} />
        </BackButton>
        <IconButton ariaLabel="Close" onClick={handleCloseClick}>
          <X size={32} weight="thin" />
        </IconButton>
      </TopBar>
      <MenuContainer>
        <MainMenuList>
          {(menu ?? []).map((menuItem, index) => (
            <MainMenuItem key={menuItem._key}>
              {!showRightSide || !(menuItem.children!.length > 0) ? (
                <Link href={`${menuItem.link}`} passHref>
                  <MenuHeader onClick={onCloseClick} as="a">
                    {menuItem.label}
                  </MenuHeader>
                </Link>
              ) : (
                <MenuHeader onClick={handleNavClick(index)}>
                  {menuItem.label}
                  <ArrowElbowRightDown size={24} weight="thin" />
                </MenuHeader>
              )}

              {showRightSide &&
                Array.isArray(menuItem.children) &&
                menuItem.children.length > 0 && (
                  <SubMenuList
                    aria-hidden={activeStack !== index}
                    style={{
                      // height: `${subMenuHeight}px`,
                      translateX: springs[index].translateX,
                      // @ts-expect-error fudge ts
                      visibility: springs[index].visibility.to((x) => x),
                    }}
                  >
                    <SubMenuListTitle>{menuItem.label}</SubMenuListTitle>
                    {menuItem.children.map((item) => (
                      <SubMenuItem key={item._key}>
                        <SectionListTitle
                          title={item.label ?? ''}
                          href={item.link ?? ''}
                          comingSoon={Boolean(item.comingSoon)}
                          onClick={handleAnchorClick}
                        />
                      </SubMenuItem>
                    ))}
                  </SubMenuList>
                )}
            </MainMenuItem>
          ))}
          {showRightSide && (
            <>
              <MainMenuItem>
                <MenuHeader as="button" onClick={handleSearchClick}>
                  Search
                </MenuHeader>
              </MainMenuItem>
              <MainMenuItem>
                <Link href="https://chat.flexlabs.ai/cellular-goods" passHref>
                  <MenuHeader as="a">Ask Cel</MenuHeader>
                </Link>
              </MainMenuItem>
              <TopBar />

              <MainMenuItem style={{ paddingTop: '3rem' }}>
                <CurrencyHeader style={{ marginBottom: '8px' }}>
                  Currency:
                </CurrencyHeader>
                <CurrencyButtonWrapper>
                  {activeStack === null
                    ? currencyOptions.map((c) => (
                        <CurrencyButton
                          selected={c === currency}
                          key={c}
                          onClick={() => setCurrency(c as 'GBP' | 'USD')}
                        >
                          {c}
                        </CurrencyButton>
                      ))
                    : null}
                </CurrencyButtonWrapper>
                <TopBar />
              </MainMenuItem>
            </>
          )}
        </MainMenuList>

        <SecondMenuList>
          {subNav.map((col, i) => (
            <SecondCol key={i}>
              {col.map(({ _key, title, ...restProps }, j) => (
                <Fragment key={_key}>
                  {j === 0 && i === 0 && showRightSide ? (
                    <SecondMenuItem>
                      <SecondMenuLink as="button" onClick={handleAccountClick}>
                        Account
                      </SecondMenuLink>
                    </SecondMenuItem>
                  ) : null}
                  <SecondMenuItem
                    $needsExtraMargin={i === 0 && j === 0 && !showRightSide}
                  >
                    <SecondMenuLink onClick={handleAnchorClick} {...restProps}>
                      {title}
                    </SecondMenuLink>
                  </SecondMenuItem>
                </Fragment>
              ))}
            </SecondCol>
          ))}
        </SecondMenuList>
      </MenuContainer>
    </Wrap>
  )
}

export default MobileNavMenu

const Wrap = styled.div<{
  $isVisible: boolean
}>`
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1;
  max-height: 100%;
  ${(props) => (props.$isVisible ? 'height: 100vh;' : '')}
  overflow: hidden;

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: solid 1px var(--lightGrey);
`

const BackButton = styled(IconButton)<{
  $isVisible: boolean
}>`
  visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
`

const MenuContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 999;
  height: ${`calc(100vh - env(safe-area-inset-bottom) - ${TOOLBAR_HEIGHT}px)`};
`

const MainMenuList = styled.ul`
  margin-top: 4rem;
`

const MainMenuItem = styled.li`
  padding: 0 2rem;
  & + & {
    margin-top: 3rem;
  }
`

const MenuHeader = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_22_500)};
  color: var(--black);
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
`

const SubMenuListTitle = styled.h2`
  ${getFontStyles(FONT_STYLE_SOFIA_22_500)};
  margin-bottom: 2rem;
`

const SubMenuList = styled(animated.ul)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--white);
  padding: 4rem 2rem;
  height: ${`calc(100vh - env(safe-area-inset-bottom) - ${TOOLBAR_HEIGHT}px)`};
  overflow-x: hidden;
  overflow-y: scroll;
`

const SubMenuItem = styled.li`
  & + & {
    margin-top: 1rem;
  }
`

const SecondMenuList = styled.ul`
  display: flex;
  width: 100%;
  padding: 0 2rem 3rem 2rem;
  margin-top: 8rem;
`

const SecondCol = styled.div`
  width: 159px;
`

const SecondMenuItem = styled.li<{ $needsExtraMargin?: boolean }>`
  margin-top: ${(props) => (props.$needsExtraMargin ? '4.2rem' : '')};

  & + & {
    margin-top: 1.6rem;
  }
`

const SecondMenuLink = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`

const CurrencyHeader = styled.span`
  display: block;
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
`

const CurrencyButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
`

const CurrencyButton = styled.button<{ selected?: boolean }>`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  border: none;
  background-color: transparent;
  ${(props) => (props.selected ? 'opacity: 1;' : 'opacity: 0.5;')}
`
