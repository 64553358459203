import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { NonNullSkipArray, PickType, Sanity } from '@cellulargoods/types'

import {
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_14_500,
  FONT_STYLE_SOFIA_16_500,
  aspectRatio,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Text, Heading } from '../Text'
import { Button, ButtonTypes } from '../Button'
import { PortalUGCReadMore } from '../Portals'

import { createSlug } from '../../helpers/links'
import { slugify } from '../../helpers/strings'

import { SlugType } from '../../references/constants'

import IconInstagram from '../../assets/instagram.svg'
import { generateSrcSetSizes } from '../Image'
import { ReviewStarRating } from '../Reviews'

export type UGCCardProps = NonNullSkipArray<
  PickType<Sanity.UGCCarouselWithYotpo, 'images'>
> & {
  className?: string
}

export const UGCCard = (props: UGCCardProps) => {
  const {
    lowResImage,
    imageUrl,
    source,
    username,
    product,
    profilePhoto,
    stars,
    content,
    className,
  } = props

  const [isActive, setIsActive] = useState(false)

  const handleCloseClick = () => {
    if (isActive) {
      setIsActive(false)
    }
  }

  const handleReadMoreClick = () => {
    setIsActive(true)
  }

  return (
    <CardContainer className={className}>
      <CardImage>
        {source === 'instagram' && (
          <InstaLogo>
            <IconInstagram width={24} height={24} />
          </InstaLogo>
        )}
        <CardMedia>
          <Image
            blurDataURL={lowResImage}
            placeholder="blur"
            src={imageUrl}
            objectFit="cover"
            layout="fill"
            draggable={false}
            sizes={generateSrcSetSizes(['65vw', null, null, '30vw'])}
          />
        </CardMedia>
        {product && (
          <Link
            passHref
            href={createSlug(SlugType.FUNCTIONAL_PDP, [
              product.category,
              slugify(product.name),
            ])}
          >
            <CardLabel>
              <CardText tag="span" fontStyle={FONT_STYLE_SOFIA_11_500}>
                {product.name}
              </CardText>
            </CardLabel>
          </Link>
        )}
      </CardImage>
      <CardContent>
        <CardHead>
          <div>
            {profilePhoto ? (
              <CardPhoto>
                <img src={profilePhoto} alt={`${username} profile photo`} />
              </CardPhoto>
            ) : null}
            {source === 'instagram' ? (
              <Button
                tag="a"
                href={`https://www.instagram.com/${username}`}
                isExternal
                variant={ButtonTypes.TERTIARY}
              >
                <Heading tag="h3" fontStyle={FONT_STYLE_SOFIA_16_500}>
                  {`@${username}`}
                </Heading>
              </Button>
            ) : (
              <Heading tag="h3" fontStyle={FONT_STYLE_SOFIA_16_500}>
                {username}
              </Heading>
            )}
          </div>
          {stars ? <ReviewStarRating rating={stars} /> : null}
        </CardHead>
        <CardCopy>{content}</CardCopy>
        <CardCta variant={ButtonTypes.TERTIARY} onClick={handleReadMoreClick}>
          Read More
        </CardCta>
        <PortalUGCReadMore
          {...props}
          id="portal-root"
          isActive={isActive}
          onCloseClick={handleCloseClick}
        />
      </CardContent>
    </CardContainer>
  )
}

const CardContainer = styled.div`
  background-color: var(--softGrey);
`

const CardImage = styled.div`
  position: relative;
  z-index: 0;
`

const CardMedia = styled.div`
  ${aspectRatio(1, 1, false)};
`

const InstaLogo = styled(IconInstagram)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: var(--white);
  width: 3.2rem;
  height: 3.2rem;
`

const CardLabel = styled.a`
  padding: 1rem;
  display: inline-block;
  background-color: var(--darkElectricBlue);
  border-radius: 0.1rem;
  text-decoration: none;
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  & > * {
    color: var(--white);
  }
`

const CardText = styled(Text)`
  position: relative;
  bottom: 0.1rem;
`

const CardContent = styled.div`
  padding: 2rem;
`

const CardHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > h3 {
      position: relative;
      bottom: 0.2rem;
    }
  }
`

const CardPhoto = styled.div`
  ${aspectRatio(1, 1)};

  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;

  margin-right: 1rem;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const CardCopy = styled(Text)`
  margin-top: 2rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;

  ${MEDIA_QUERIES.desktopUp} {
    -webkit-line-clamp: 2;
  }
`

const CardCta = styled(Button)`
  margin-top: 1rem;

  & > span {
    ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  }
`
