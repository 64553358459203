import { ChangeEvent, MouseEvent, useState, useRef } from 'react'
import styled from 'styled-components'
import { CaretLeft, CaretRight } from 'phosphor-react'
import clsx from 'clsx'
import { Sanity } from '@cellulargoods/types'
import { styleVisuallyHidden } from '@cellulargoods/styles'

import { SmallTicker } from '../Ornaments/SmallTicker'

import { clamp } from '../../helpers/math'

import { Image } from './Image'

export type ImageSliderProps = Sanity.Keyed<Sanity.ImageSlider> & {
  className?: string
  leftText?: string
  rightText?: string
}

const HANDLE_WIDTH = 8.4

export const ImageSlider = ({
  leftText = 'Before',
  rightText = 'After',
  right_image: rightImage,
  left_image: leftImage,
  slider_start_point: sliderStartPoint = 0,
  className,
}: ImageSliderProps) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const isMovingRef = useRef(false)
  const [focussed, setFocussed] = useState(false)
  const [sliderValue, setSliderValue] = useState(Number(sliderStartPoint))

  if (!leftImage || !rightImage) {
    return null
  }

  const handleSliderChange = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(currentTarget.value))
  }

  const handleImageContainerClick = ({
    currentTarget,
    clientX,
  }: MouseEvent) => {
    const { width, left } = currentTarget.getBoundingClientRect()
    const x = clientX - left

    const value = Number(((x / width) * 100).toFixed(0))

    setSliderValue(value)
  }

  const handleSliderPointerDown = () => {
    isMovingRef.current = true

    document.documentElement.style.overflow = 'hidden'
    document.body.style.overflow = 'hidden'

    window.addEventListener('pointermove', handlePointerMove)
    window.addEventListener('pointerup', handlePointerUp)
  }

  const handlePointerMove = (e: PointerEvent) => {
    const { current: node } = nodeRef
    if (isMovingRef.current && nodeRef) {
      const { width, left } = node!.getBoundingClientRect()
      const x = e.clientX - left

      const value = clamp(Number(((x / width) * 100).toFixed(0)), 0, 100)

      setSliderValue(value)
    }
  }

  const handlePointerUp = () => {
    isMovingRef.current = false

    document.documentElement.style.overflow = ''
    document.body.style.overflow = ''

    window.removeEventListener('pointermove', handlePointerMove)
    window.removeEventListener('pointerup', handlePointerUp)
  }

  const handleSliderFocus = () => {
    setFocussed(true)
  }

  const handleSliderBlur = () => {
    setFocussed(false)
  }

  return (
    <ImageSliderContainer className={className}>
      <Images ref={nodeRef} onClick={handleImageContainerClick}>
        <ImageLeft
          style={{
            clipPath: `polygon(0 0, ${sliderValue}% 0%, ${sliderValue}% 100%, 0 100%)`,
          }}
        >
          <Image
            image={leftImage}
            layout="fill"
            objectFit="cover"
            sizes={['100vw', null, null, '50vw']}
          />
        </ImageLeft>
        <ImageRight>
          <Image
            image={rightImage}
            layout="fill"
            objectFit="cover"
            sizes={['100vw', null, null, '50vw']}
          />
        </ImageRight>
        <Slider
          style={{
            left: `calc(${sliderValue}% - ${HANDLE_WIDTH / 2}rem - 1px)`,
          }}
          onPointerDown={handleSliderPointerDown}
        >
          <SliderLeft
            className={clsx({
              focussed,
            })}
            weight="thin"
            size={32}
            color="white"
          />
          <SliderBar
            className={clsx({
              focussed,
            })}
          />
          <SliderRight
            className={clsx({
              focussed,
            })}
            weight="thin"
            size={32}
            color="white"
          />
          <HiddenInput
            type="range"
            min="0"
            max="100"
            step={5}
            value={sliderValue}
            onChange={handleSliderChange}
            onFocus={handleSliderFocus}
            onBlur={handleSliderBlur}
          />
        </Slider>
      </Images>
      <ImageSliderBanner>
        <SmallTickerLeft
          move={false}
          style={{
            clipPath: `polygon(0 0, ${sliderValue}% 0%, ${sliderValue}% 100%, 0 100%)`,
          }}
        >
          {leftText}
        </SmallTickerLeft>
        <SmallTickerRight move={false}>{rightText}</SmallTickerRight>
      </ImageSliderBanner>
    </ImageSliderContainer>
  )
}

const ImageSliderContainer = styled.div``

const Images = styled.div`
  position: relative;
  overflow: hidden;
  user-select: none;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc((418 / 335) * 100%);
  }

  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

const ImageLeft = styled(Images)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`

const ImageRight = styled(Images)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

const Slider = styled.div`
  height: 100%;
  width: ${HANDLE_WIDTH}rem;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 3;
  cursor: grab;

  &:active {
    cursor: ew-resize;
  }

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    &:first-child {
      left: 0;
      right: unset;
    }
  }
`

const SliderBar = styled.div`
  height: 100%;
  width: 0.2rem;
  background-color: var(--white);

  &.focussed {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const SliderLeft = styled(CaretLeft)`
  &.focussed {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const SliderRight = styled(CaretRight)`
  &.focussed {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`

const HiddenInput = styled.input`
  ${styleVisuallyHidden}
`

const ImageSliderBanner = styled.div`
  position: relative;
`

const SmallTickerLeft = styled(SmallTicker)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
`

const SmallTickerRight = styled(SmallTicker)`
  z-index: 1;
  width: 100%;
`
