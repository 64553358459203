import { SetStateAction, Dispatch, FC, useState } from 'react'
import { createContext, useContextSelector } from 'use-context-selector'

type VideoPlayingState = Record<string, boolean>

type VideoPlayingContext = [
  state?: VideoPlayingState,
  setState?: Dispatch<SetStateAction<VideoPlayingState>>
]

const videoPlayingContext = createContext<VideoPlayingContext>([])

export const useVideoPlayingContext = <TSelected,>(
  selector: (value: VideoPlayingContext) => TSelected
) => useContextSelector(videoPlayingContext, selector)

export const VideoPlayingProvider: FC = ({ children }) => {
  const [videoPlayingState, setVideoPlayingState] = useState({})

  return (
    <videoPlayingContext.Provider
      value={[videoPlayingState, setVideoPlayingState]}
    >
      {children}
    </videoPlayingContext.Provider>
  )
}
