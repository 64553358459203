import Link from 'next/link'
import styled from 'styled-components'

import { Button, ButtonTypes } from '../Button'
import { Media } from '../Media'

import { NonNullSkipArray, PickType, Sanity } from '@cellulargoods/types'

import { MEDIA_QUERIES, aspectRatio } from '@cellulargoods/styles'

import { getUrlFromReference } from '../../helpers/links'

export type RelatedLinkCardProps = Pick<
  Sanity.RelatedLinkCard,
  'label' | 'reference' | 'link'
> & {
  className?: string
  media: NonNullSkipArray<PickType<Sanity.RelatedLinkCard, 'media'>> | null
}

export const RelatedLinkCard = ({
  className,
  media,
  label,
  link,
  reference,
}: RelatedLinkCardProps) => {
  const url = getUrlFromReference(reference) || link?.current

  return (
    <Link href={url ?? ''} passHref>
      <RelatedLink className={className}>
        {media && media._type ? (
          <RelatedLinkMedia>
            <Media {...media} sizes={['100vw', null, null, '50vw']} />
          </RelatedLinkMedia>
        ) : null}
        <RelatedLinkCtaWrapper>
          <RelatedLinkCta variant={ButtonTypes.SECONDARY} tag="div">
            {label}
          </RelatedLinkCta>
        </RelatedLinkCtaWrapper>
      </RelatedLink>
    </Link>
  )
}

const RelatedLink = styled.a`
  position: relative;
  display: block;
  text-decoration: none;

  img {
    transition: transform 400ms ease-out;
  }

  @media (hover: hover) {
    &:hover img {
      transform: scale(1.04);
    }
  }
`

const RelatedLinkMedia = styled.div`
  ${aspectRatio(335, 220)};
  z-index: 0;
`

const RelatedLinkCtaWrapper = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 1;

    padding: 1.5rem 3.5rem 1.7rem 3.5rem;
    background-color: var(--white);
    border: 1px solid var(--darkGrey1);
    display: flex;
    justify-content: center;
  }
`

const RelatedLinkCta = styled(Button)`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
  }
`
