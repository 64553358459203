import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { Heading } from '../Text/Heading'
import { Text } from '../Text/Text'
import { Button, ButtonTypes } from '../Button'
import { Accordion } from '../Accordion'

import { FONT_STYLE_SOFIA_32_500, MEDIA_QUERIES } from '@cellulargoods/styles'

export type FAQAccordionProps = Sanity.Keyed<Sanity.FaqAccordion>

export const ContentFAQAccordion = ({
  HeaderText: header,
  items,
}: FAQAccordionProps) => {
  const { title, body, CTAButton: cta } = header ?? {}
  return (
    <FAQContainer>
      <FAQHeader>
        <FAQTitle fontStyle={FONT_STYLE_SOFIA_32_500}>{title}</FAQTitle>
        <FAQCopy>{body}</FAQCopy>
        {cta && cta.label && cta.url ? (
          <Button
            variant={ButtonTypes.SECONDARY}
            tag="a"
            isExternal={Boolean(cta?.isExternal)}
            href={cta.url}
          >
            {cta.label}
          </Button>
        ) : null}
      </FAQHeader>
      <FAQs>
        {items && items.map((item, i) => <FAQAccord key={i} item={item} />)}
      </FAQs>
    </FAQContainer>
  )
}

const FAQContainer = styled.section`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    padding: 8rem 4rem;

    & > * {
      flex-basis: 50%;
    }
  }
`

const FAQHeader = styled.div`
  padding: 4rem 2rem 5.1rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
    padding-right: 13.5rem;
  }
`

const FAQTitle = styled(Heading)`
  margin-bottom: 2rem;
`

const FAQCopy = styled(Text)`
  margin-bottom: 3rem;
  max-width: 55rem;
`

const FAQs = styled.div`
  padding: 0 2rem 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
  }
`

const FAQAccord = styled(Accordion)`
  padding: 0;
`
