import { useCallback } from 'react'
import axios from 'axios'

import { AwaitedReturn, Klaviyo } from '@cellulargoods/types'

type UseKlaviyo = () => Klaviyo.KlaviyoApi

const baseAxios = axios.create({
  baseURL: '/api/newsletter',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const useKlaviyo: UseKlaviyo = () => {
  const subscribeProfileToList = useCallback(
    async (args: Parameters<Klaviyo.SubscribeProfileToList>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.SubscribeProfileToList>
      >('/subscribeProfileToList', args)

      return response.data
    },
    []
  )
  const subscribeProfileToLists = useCallback(
    async (args: Parameters<Klaviyo.SubscribeProfileToLists>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.SubscribeProfileToLists>
      >('/subscribeProfileToLists', args)

      return response.data
    },
    []
  )
  const addEmailToList = useCallback(
    async (args: Parameters<Klaviyo.AddEmailToList>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.AddEmailToList>
      >('/addEmailToList', args)

      return response.data
    },
    []
  )
  const removeEmailFromList = useCallback(
    async (args: Parameters<Klaviyo.RemoveEmailFromList>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.RemoveEmailFromList>
      >('/removeEmailFromList', args)

      return response.data
    },
    []
  )
  const subscribeEmailForProductUpdates = useCallback(
    async (args: Parameters<Klaviyo.SubscribeEmailForProductUpdates>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.SubscribeEmailForProductUpdates>
      >('/subscribeEmailForProductUpdates', args)

      return response.data
    },
    []
  )
  const isEmailUnique = useCallback(
    async (args: Parameters<Klaviyo.IsEmailUnique>[0]) => {
      const response = await baseAxios.post<
        AwaitedReturn<Klaviyo.IsEmailUnique>
      >('/checkEmailIsUnique', args)

      return response.data
    },
    []
  )

  const getProfile = useCallback(
    async (args: Parameters<Klaviyo.GetProfile>[0]) => {
      const response = await baseAxios.get<AwaitedReturn<Klaviyo.GetProfile>>(
        `/getProfile?email=${args.email}`
      )
      return response.data
    },
    []
  )

  const updateProfile = useCallback(
    async (args: Parameters<Klaviyo.UpdateProfile>[0]) => {
      const response = await baseAxios.put<
        AwaitedReturn<Klaviyo.UpdateProfile>
      >('/updateProfile', args)
      return response.data
    },
    []
  )

  return {
    subscribeProfileToList,
    subscribeProfileToLists,
    addEmailToList,
    removeEmailFromList,
    subscribeEmailForProductUpdates,
    isEmailUnique,
    getProfile,
    updateProfile,
  }
}

export { useKlaviyo }
