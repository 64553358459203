import BlockContent from '@sanity/block-content-to-react'

export const RichText = ({
  blocks,
  serializers,
}: {
  blocks: any
  serializers?: any
}) => {
  return <BlockContent blocks={blocks} serializers={serializers} />
}
