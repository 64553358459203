import styled from 'styled-components'
import { useMemo, useEffect, useCallback } from 'react'
import mergeRefs from 'react-merge-refs'
import { animated, useSpring, config } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'

import { Sanity, PickType } from '@cellulargoods/types'
import {
  useDisableScroll,
  useScrollDirection,
  useWindowResize,
} from '@cellulargoods/hooks'

import { SlugType, ECOMMERCE_DOMAIN } from '../../../references/constants'

import { SiteBanner, SiteBannerProps } from '../../Banners'

import MobileNavbar from './MobileNavbar'
import MobileNavMenu from './MobileNavMenu'
import { DesktopNavBar } from './DesktopNavBar'

import { createSlug, checkForHttps } from '../../../helpers/links'
import { chunkArray } from '../../../helpers/arrays'

import { useHeaderState } from './HeaderContext'
import { WIDTHS } from '@cellulargoods/styles'

export type SiteHeaderProps = Sanity.NavigationQueryResponse & {
  siteBanner: PickType<
    PickType<Sanity.GetLayoutDataPayload, 'siteSettings'>,
    'SiteBanner'
  >
  onCartClick?: () => void
  onAccountClick?: () => void
  onSearchClick?: () => void
  cartQuantity?: number
  transparent: boolean
  theme: string
  showRightSide?: boolean
}

export const SiteHeader = ({
  onCartClick,
  onAccountClick,
  onSearchClick,
  mobileSubNav,
  siteBanner,
  theme,
  transparent,
  cartQuantity,
  showRightSide,
  navbar,
}: SiteHeaderProps) => {
  const [bannerRef, { height: bannerHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })
  const [headerRef, { height: headerHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })
  const [headerState, setHeaderState] = useHeaderState()

  const { width } = useWindowResize()

  const toggleMobileNavMenu = () => {
    setHeaderState((s) => ({
      ...s,
      mobileOpen: !s.mobileOpen,
    }))
  }

  const menu = useMemo(
    () =>
      navbar?.map((nav) => ({
        _key: nav._key,
        label: nav.label,
        link: createSlugFromQuery(
          nav.linkType,
          nav.link,
          nav.referenceType,
          nav.category,
          showRightSide
        ),
        linkType: nav.linkType,
        children:
          nav.children?.map((child) => ({
            _key: child._key,
            label: child.label,
            linkType: child.linkType,
            link: createSlugFromQuery(
              child.linkType,
              child.link,
              child.referenceType,
              child.category,
              showRightSide
            ),
            media: child.media,
            comingSoon: child.comingSoon,
          })) ?? [],
      })),
    [navbar, showRightSide]
  )

  const subNav = useMemo(
    () =>
      chunkArray(
        mobileSubNav.map((item) => {
          const isExternal = checkForHttps(item.slug)

          return {
            _key: item._key,
            _type: item._type,
            title: item.title,
            href: item.slug
              .replace(!isExternal ? /\// : '', '')
              .replace(
                /^/,
                !showRightSide && !isExternal
                  ? ECOMMERCE_DOMAIN
                  : !isExternal
                  ? '/'
                  : ''
              ),
            rel: isExternal ? 'noopener noreferrer' : '',
            target: isExternal ? '_blank' : '',
          }
        }),
        4
      ),
    [mobileSubNav, showRightSide]
  )

  const handleNavPointerOver = () => {
    if (width < WIDTHS.desktop) {
      return
    }

    setHeaderState((s) => ({
      ...s,
      desktopOpen: true,
      transparent: false,
      theme: 'black',
    }))
  }

  const handleNavPointerOut = () => {
    if (headerState.desktopMenuOpen || width < WIDTHS.desktop) {
      return
    }

    setHeaderState((s) => ({
      ...s,
      desktopOpen: false,
      theme,
      transparent,
    }))
  }

  const handleMenuRest = useCallback(() => {
    setHeaderState((s) => ({
      ...s,
      theme,
      transparent,
      desktopOpen: false,
    }))
  }, [setHeaderState, theme, transparent])

  const scrollDirectionActive =
    !headerState.desktopOpen && !headerState.mobileOpen
  const { direction: lastDirection, value: scrollPos } =
    useScrollDirection({
      active: scrollDirectionActive,
      threshold: 20,
      yOffset: 0,
    }) ?? {}

  const [positionSprings, positionApi] = useSpring(() => ({
    from: {
      y: 0,
    },
  }))

  const [colorSprings, colorApi] = useSpring(() => ({
    backgroundColor: headerState.transparent
      ? 'rgba(255,255,255,0)'
      : 'rgba(255,255,255,1)',
    borderOpacity: 0,
    config: config.stiff,
  }))

  /**
   * handles moving the header up and down
   */
  useEffect(() => {
    if (lastDirection === 'down') {
      positionApi.start({
        to: {
          y: -headerHeight,
        },
      })
    }
    if (lastDirection === 'up') {
      positionApi.start({
        to: {
          y: 0,
        },
      })
    }
  }, [positionApi, headerHeight, lastDirection])

  useEffect(() => {
    if (lastDirection === 'up' && Number(scrollPos) > 10) {
      colorApi.set({
        backgroundColor: 'rgba(255,255,255,1)',
        borderOpacity: 1,
      })

      if (headerState.theme === 'white') {
        setHeaderState((s) => ({ ...s, theme: 'black' }))
      }
    } else {
      colorApi.start({
        backgroundColor: headerState.transparent
          ? 'rgba(255,255,255,0)'
          : 'rgba(255,255,255,1)',
        borderOpacity: headerState.desktopOpen ? 1 : 0,
      })

      if (headerState.theme !== theme && !headerState.desktopOpen) {
        setHeaderState((s) => ({ ...s, theme }))
      }
    }
  }, [
    colorApi,
    headerState.desktopOpen,
    headerState.theme,
    headerState.transparent,
    lastDirection,
    scrollPos,
    setHeaderState,
    theme,
  ])

  useEffect(() => {
    setHeaderState((s) => ({
      ...s,
      bannerHeight,
      headerHeight: headerHeight - bannerHeight,
    }))
  }, [bannerHeight, headerHeight])

  useDisableScroll(headerState.mobileOpen)

  return (
    <>
      <Header
        ref={mergeRefs([headerRef])}
        style={{
          backgroundColor: colorSprings.backgroundColor,
          ...positionSprings,
        }}
      >
        <SiteBanner ref={bannerRef} {...(siteBanner as SiteBannerProps)} />
        <Nav
          onPointerOver={handleNavPointerOver}
          onPointerLeave={handleNavPointerOut}
          style={{
            backgroundColor: colorSprings.backgroundColor,
            borderBottom: colorSprings.borderOpacity.to(
              (val) => `solid 1px rgba(0,0,0,${val})`
            ),
          }}
        >
          <DesktopNavBar
            menu={menu}
            onCartClick={onCartClick}
            onAccountClick={onAccountClick}
            onSearchClick={onSearchClick}
            onMenuRest={handleMenuRest}
            cartQuantity={cartQuantity}
            showRightSide={showRightSide}
          />
          <MobileNavbar
            onMenuClick={toggleMobileNavMenu}
            onCartClick={onCartClick}
            cartQuantityTotal={cartQuantity}
            showRightSide={showRightSide}
          />
          <MobileNavMenu
            isOpen={headerState.mobileOpen}
            onCloseClick={toggleMobileNavMenu}
            onAccountClick={onAccountClick}
            onSearchClick={onSearchClick}
            menu={menu}
            subNav={subNav}
            showRightSide={showRightSide}
          />
        </Nav>
        <DesktopNavBackground $isVisible={headerState.desktopOpen} />
        <NavPortal id="header-portal" />
      </Header>
    </>
  )
}

const createSlugFromQuery = (
  type: 'internal' | 'external',
  slug?: string | null,
  referenceType?: string | null,
  category?: string | null,
  showRightSide = false
) => {
  if (!slug) {
    return
  } else if (type === 'external') {
    return slug
  } else {
    let unAppendedSlug = slug
    if (referenceType) {
      switch (referenceType) {
        case 'learnHome':
          unAppendedSlug = `/${slug}`
          break
        case 'learnPage':
          unAppendedSlug = createSlug(SlugType.LEARN_SINGLETON, [slug])
          break
        case 'functionalProductDisplayPage':
          unAppendedSlug = createSlug(SlugType.FUNCTIONAL_PDP, [category, slug])
          break
        case 'enhancedProductDisplayPage':
          unAppendedSlug = createSlug(SlugType.ENHANCED_PDP, [category, slug])
          break
        case 'internalArticle':
        case 'externalArticle':
          unAppendedSlug = createSlug(SlugType.LEARN_ARTICLE, [category, slug])
          break
        default:
          unAppendedSlug = slug
      }
    }

    return unAppendedSlug.replace(
      /^/,
      !showRightSide
        ? ECOMMERCE_DOMAIN
        : !unAppendedSlug.startsWith('/')
        ? '/'
        : ''
    )
  }
}

export * from './HeaderContext'

const Header = styled(animated.header)`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`

const Nav = styled(animated.nav)`
  position: relative;
  z-index: 20;
`

const DesktopNavBackground = styled.div<{
  $isVisible: boolean
}>`
  display: block;
  position: absolute;
  pointer-events: none;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--black);
  opacity: ${(props) => (props.$isVisible ? 0.2 : 0)};
  z-index: 0;
  transition: opacity 400ms ease-out;
`

const NavPortal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 5;
  pointer-events: none;
`
