import styled from 'styled-components'
import {
  CreditCard,
  Package,
  ClockClockwise,
  ChatCenteredDots,
} from 'phosphor-react'
import { Sanity } from '@cellulargoods/types'

import { Text } from '../Text'

import { MEDIA_QUERIES, FONT_STYLE_SOFIA_16_400 } from '@cellulargoods/styles'

const ICONS = [CreditCard, Package, ClockClockwise, ChatCenteredDots]

export type ShoppingDirectBannerProps = Sanity.ShoppingDirectBanner & {}

export const ShoppingDirectBanner = ({
  shoppingDirectBannerItems: items,
}: ShoppingDirectBannerProps) => {
  return (
    <Banner>
      <ItemList>
        {items?.map((item, index) => {
          const Icon = ICONS[index]
          return (
            <Item key={index}>
              <ItemText fontStyle={FONT_STYLE_SOFIA_16_400}>
                <Icon size="20" />
                {item}
              </ItemText>
            </Item>
          )
        })}
      </ItemList>
    </Banner>
  )
}

const Banner = styled.section`
  background-color: var(--softGrey);
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 2.5rem 4rem 3rem 4rem;
  }
`

const ItemList = styled.ul`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    justify-content: center;
  }
`

const Item = styled.li`
  margin-bottom: 1.4rem;

  svg {
    margin-right: 1rem;

    ${MEDIA_QUERIES.desktopUp} {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 0;
    width: 32.5rem;

    & + & {
      margin-left: 2rem;
    }
  }
`

const ItemText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: column;
    text-align: center;
  }
`
