import {
  FONT_STYLE_SOFIA_14_500,
  getFontStyles,
  styleVisuallyHidden,
} from '@cellulargoods/styles'
import {
  ChangeEvent,
  DetailedHTMLProps,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

type CheckboxNewsletterProps = {
  children: string
  onChange?: (checked: boolean, name?: string) => void
  isPreferences?: boolean
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'ref'
>

export const CheckboxNewsletter = ({
  value,
  children,
  checked,
  onChange,
  name,
  className,
  disabled = false,
  defaultChecked = false,
  isPreferences = false,
  ...restProps
}: CheckboxNewsletterProps) => {
  const [internalChecked, setInternalChecked] = useState(defaultChecked)
  const hasChanged = useRef(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked, name)
    }
    hasChanged.current = true
    setInternalChecked(e.target.checked)
  }

  useEffect(() => {
    hasChanged.current = false
    setInternalChecked(defaultChecked)
  }, [defaultChecked])

  const actualChecked = checked !== undefined ? checked : internalChecked

  return (
    <CheckboxNewsletterLabel
      className={className}
      $disabled={disabled}
      $checked={actualChecked}
      $isPreferences={isPreferences}
      $default={defaultChecked && !hasChanged.current}
    >
      <span>{children}</span>
      <CheckboxNewsletterInput
        type="checkbox"
        name={name}
        checked={actualChecked}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        {...restProps}
      />
    </CheckboxNewsletterLabel>
  )
}

const CheckboxNewsletterLabel = styled.label<{
  $checked: boolean
  $disabled: boolean
  $isPreferences: boolean
  $default: boolean
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  position: relative;
  display: inline-block;
  padding: 0.5rem 1.8rem;
  border: solid 1px var(--black);
  opacity: ${(props) => (props.$disabled ? 0.35 : 1)};
  cursor: pointer;
  color: ${(props) =>
    props.$checked && props.$isPreferences && !props.$default
      ? 'var(--white)'
      : 'var(--darkGrey)'};

  & > span {
    position: relative;
    top: -0.2rem;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    background-color: ${(props) =>
      props.$isPreferences && !props.$default
        ? 'var(--black)'
        : 'var(--lightBrown)'};
    border-top: none;
    width: 100%;
    height: ${(props) => (props.$checked ? '100%' : '0')};
    transition: height 200ms ease-out;
  }
`

const CheckboxNewsletterInput = styled.input`
  ${styleVisuallyHidden}
`
