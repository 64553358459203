import { MouseEvent } from 'react'
import styled from 'styled-components'

import { ButtonGenericProps } from './types'

import { FONT_STYLE_SOFIA_16_500, getFontStyles } from '@cellulargoods/styles'

export enum ButtonTertiaryTheme {
  BLACK = 'black',
  WHITE = 'white',
}

export type ButtonTertiaryProps = ButtonGenericProps & {
  theme?: ButtonTertiaryTheme
}

export const ButtonTertiary = ({
  theme = ButtonTertiaryTheme.BLACK,
  children,
  label,
  onClick,
  href,
  className,
  tag = 'button',
  isExternal,
  type = 'button',
  ...restProps
}: ButtonTertiaryProps) => {
  const anchorProps = {
    href,
    rel: isExternal ? 'noopener noreferrer' : '',
    target: isExternal ? '_blank' : '',
  }
  const handleOnClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <>
      {/* @ts-expect-error no idea, and got no time. – https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117 */}
      <Button
        type={type}
        as={tag}
        className={className}
        onClick={handleOnClick}
        {...(tag === 'a' ? anchorProps : {})}
        theme={theme}
        {...restProps}
      >
        <span>{label ?? children}</span>
      </Button>
    </>
  )
}

const Button = styled.button<{
  as: string
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  display: inline-flex;
  justify-content: flex-start;
  color: ${(props) => `var(--${props.theme})`};
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  border-bottom: ${(props) => `solid 1px var(--${props.theme})`};
  cursor: pointer;
  text-decoration: none;

  & > svg {
    margin-top: 0.2rem;
  }

  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`
