import {
  FONT_FAMILIES,
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_11_600,
  FONT_STYLE_SOFIA_14_400,
  FONT_STYLE_SOFIA_14_500,
  FONT_STYLE_SOFIA_14_600,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_16_600,
  FONT_STYLE_SOFIA_18_400,
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_22_400,
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_28_400,
  FONT_STYLE_SOFIA_28_500,
  FONT_STYLE_SOFIA_32_400,
  FONT_STYLE_SOFIA_32_500,
  FONT_STYLE_SOFIA_40_400,
  FONT_STYLE_SOFIA_40_500,
  FONT_STYLE_SOFIA_48_400,
  FONT_STYLE_SOFIA_48_500,
} from './fonts'
import { MEDIA_QUERIES } from './mediaQueries'

// import { MEDIA_QUERIES } from './mediaQueries'
// import { WIDTHS } from './dimensions'

export interface FontDataType {
  'font-family'?: string
  'font-weight'?: string
  'text-transform'?: string
  'letter-spacing'?: string | null
  size?: string
}

const getFontData = (type: string): FontDataType => {
  const lowerCaseType = type.toLowerCase()
  const arrayType = lowerCaseType.split('_')
  const typeProps = arrayType.splice(2, arrayType.length - 1)

  const data: FontDataType = {}

  typeProps.forEach((prop, i) => {
    switch (i) {
      // FONT-FAMILY
      case 0:
        data['font-family'] = FONT_FAMILIES.filter(
          (fam) => fam.name === prop
        )[0].value
        break
      // FONT-SIZE
      case 1:
        data.size = prop
        break
      // FONT-WEIGHT
      case 2:
        data['font-weight'] = prop
        break
      // TEXT-TRANSFORM
      case 3:
        data['text-transform'] = prop
        break
    }
  })

  return data
}

export const getFontStyles = (type: string): string => {
  if (!type) {
    console.error('CANNOT RENDER FONT: MISSING TYPE VARIABLE')
    return ''
  }

  let fontProps = ''

  // Get font data based on the type variable
  const fontData = getFontData(type)

  for (const [key, value] of Object.entries(fontData)) {
    // Size is too bespoke on this website depending on application and device that we cannot set it straight away
    if (key === 'size') continue
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    fontProps += `${key}: ${value};`
  }

  switch (type) {
    case FONT_STYLE_SOFIA_11_400:
    case FONT_STYLE_SOFIA_11_500:
    case FONT_STYLE_SOFIA_11_600:
      fontProps += `
            font-size: 1.1rem;
            line-height: 1.3rem;
          `
      break
    case FONT_STYLE_SOFIA_14_400:
    case FONT_STYLE_SOFIA_14_500:
    case FONT_STYLE_SOFIA_14_600:
      fontProps += `
            font-size: 1.4rem;
            line-height: 2rem;
          `
      break
    case FONT_STYLE_SOFIA_16_400:
    case FONT_STYLE_SOFIA_16_500:
    case FONT_STYLE_SOFIA_16_600:
      fontProps += `
            font-size: 1.6rem;
            line-height: 2.6rem;
          `
      break
    case FONT_STYLE_SOFIA_18_400:
    case FONT_STYLE_SOFIA_18_500:
      fontProps += `
            font-size: 1.8rem;
            line-height: 2.3rem;
          `
      break
    case FONT_STYLE_SOFIA_22_400:
    case FONT_STYLE_SOFIA_22_500:
      fontProps += `
            font-size: 2.2rem;
            line-height: 2.9rem;

            ${MEDIA_QUERIES.desktopUp}{
              font-size: 2.4rem;
              line-height: 3.1rem;
            }
          `
      break
    case FONT_STYLE_SOFIA_28_400:
    case FONT_STYLE_SOFIA_28_500:
      fontProps += `
            font-size: 2.8rem;
            line-height: 3.4rem;

            ${MEDIA_QUERIES.desktopUp}{
              font-size: 3.2rem;
              line-height: 3.8rem;
            }
          `
      break
    case FONT_STYLE_SOFIA_32_400:
    case FONT_STYLE_SOFIA_32_500:
      fontProps += `
            font-size: 3.2rem;
            line-height: 3.8rem;

            ${MEDIA_QUERIES.desktopUp}{
              font-size: 4rem;
              line-height: 4.8rem;
            }
          `
      break
    case FONT_STYLE_SOFIA_40_400:
    case FONT_STYLE_SOFIA_40_500:
      fontProps += `
            font-size: 4rem;
            line-height: 4.8rem;

            ${MEDIA_QUERIES.desktopUp}{
              font-size: 4.8rem;
              line-height: 5.8rem;
            }
          `
      break
    case FONT_STYLE_SOFIA_48_400:
    case FONT_STYLE_SOFIA_48_500:
      fontProps += `
          font-size: 4.8rem;
          line-height: 5.8rem;

            ${MEDIA_QUERIES.desktopUp}{
              font-size: 6.4rem;
              line-height: 7.7rem;
            }
          `
      break
  }

  return fontProps
}
