import { Equals } from 'phosphor-react'
import styled from 'styled-components'
import Link from 'next/link'

import {
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import Logo from '../../../assets/logo.svg'

import { CartNumber } from './Ornaments/CartNumber'
import { useHeaderState } from './HeaderContext'

interface Props {
  onMenuClick: () => void
  onCartClick?: () => void
  cartQuantityTotal?: number
  showRightSide?: boolean
}

const MobileNavbar = ({
  onMenuClick,
  onCartClick,
  cartQuantityTotal,
  showRightSide = true,
}: Props) => {
  const [{ theme }] = useHeaderState()

  return (
    <Wrap>
      <Link href="/" passHref>
        <Anchor>
          <Logo
            fill={theme === 'black' ? 'var(--black)' : 'var(--white)'}
            width="3.9rem"
            height="26px"
          />
        </Anchor>
      </Link>
      <UtilityWrap>
        {showRightSide && (
          <CartNumberButton theme={theme} onClick={onCartClick}>
            <CartNumber quantity={cartQuantityTotal ?? 0} />
          </CartNumberButton>
        )}
        <MenuButton onClick={onMenuClick}>
          <Equals
            color={theme === 'black' ? 'var(--black)' : 'var(--white)'}
            size={20}
          />
        </MenuButton>
      </UtilityWrap>
    </Wrap>
  )
}

export default MobileNavbar

const Wrap = styled.div`
  padding: 30px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const Anchor = styled.a``

const UtilityWrap = styled.div`
  display: flex;
  justify-content: space-between;
`

const CartNumberButton = styled.button<{ theme: string }>`
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  color: ${(props) =>
    props.theme === 'white' ? 'var(--white)' : 'var(--black)'};
  cursor: pointer;
  position: relative;

  & > span {
    top: 49%;
  }
`

const MenuButton = styled.button`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: var(--black);
  margin-left: 20px;
  display: flex;
  align-items: center;
`
