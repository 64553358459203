import { useRef, useState, KeyboardEvent, useEffect } from 'react'
import { CaretDown, CaretUp } from 'phosphor-react'
import styled from 'styled-components'
import { useClickOutside } from '@cellulargoods/hooks'

import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
} from '@cellulargoods/styles'

import { Text } from '../Text'

export interface DropdownProps {
  options: string[]
  placeholder: string
  value: string | null
  className?: string
  onChange?: (value: string) => void
  error?: string
  children?: (option: string, index: number) => JSX.Element
  renderValue?: (value: string) => JSX.Element
  containerClass?: string
}

type DropdownState = {
  isOpen: boolean
  selectedIndex: null | number
}

enum VALID_KEYS {
  ENTER = 'Enter',
  SPACE = ' ',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  ESCAPE = 'Escape',
}

const DEFAULT_MENU_HEIGHT = 240

export const Dropdown = ({
  className,
  onChange,
  options,
  placeholder,
  value,
  error,
  children,
  renderValue,
  containerClass,
}: DropdownProps) => {
  const [{ isOpen, selectedIndex }, setState] = useState<DropdownState>({
    isOpen: false,
    selectedIndex: null,
  })
  const [menuHeight, setMenuHeight] = useState(DEFAULT_MENU_HEIGHT)

  const selectRef = useRef<HTMLDivElement>(null!)
  const optionsListRef = useRef<HTMLUListElement>(null!)
  const optionRefs = useRef<HTMLLIElement[]>([])

  const handleSelectClick = () => {
    setState((s) => ({
      ...s,
      isOpen: !isOpen,
    }))
  }

  const handleOptionClick = (value: string, index: number) => () => {
    setState((s) => ({
      ...s,
      isOpen: false,
      selectedIndex: index,
    }))

    if (onChange) {
      onChange(value)
    }
  }

  const handleSelectKeydown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === VALID_KEYS.ENTER || e.key === VALID_KEYS.SPACE) {
      setState((s) => ({
        ...s,
        isOpen: !s.isOpen,
      }))
    } else if (
      e.key === VALID_KEYS.ARROW_UP ||
      e.key === VALID_KEYS.ARROW_DOWN
    ) {
      setState((s) => ({
        ...s,
        isOpen: true,
        selectedIndex: s.isOpen && e.key === VALID_KEYS.ARROW_DOWN ? 0 : null,
      }))

      if (isOpen && e.key === VALID_KEYS.ARROW_DOWN) {
        optionRefs.current[0].focus()
      }
    }
  }

  const handleOptionKeyDown =
    (value: string, index: number) => (e: KeyboardEvent<HTMLLIElement>) => {
      if (e.key === VALID_KEYS.ENTER || e.key === VALID_KEYS.SPACE) {
        setState({
          selectedIndex: null,
          isOpen: false,
        })
        selectRef.current.focus()
        if (onChange) {
          onChange(value)
        }
      } else if (e.key === VALID_KEYS.ESCAPE) {
        setState((s) => ({
          ...s,
          selectedIndex: null,
          isOpen: false,
        }))
        selectRef.current.focus()
      } else if (
        e.key === VALID_KEYS.ARROW_DOWN &&
        index + 1 <= options.length - 1
      ) {
        setState((s) => ({
          ...s,
          selectedIndex: index + 1,
        }))
        optionRefs.current[index + 1].focus()
      } else if (e.key === VALID_KEYS.ARROW_UP && index - 1 >= 0) {
        setState((s) => ({
          ...s,
          selectedIndex: index - 1,
        }))
        optionRefs.current[index - 1].focus()
      } else {
        setState((s) => ({
          ...s,
          selectedIndex: null,
        }))
        selectRef.current.focus()
      }
    }

  useClickOutside(
    {
      current: [selectRef.current, ...optionRefs.current],
    },
    () => {
      if (isOpen) {
        setState((s) => ({
          ...s,
          isOpen: false,
        }))
      }
    }
  )

  useEffect(() => {
    if (isOpen) {
      optionsListRef.current.scrollTop = 0

      if (containerClass) {
        /**
         * I don't think this is the best way to do
         * this, but, it does work...
         */
        const el = document.querySelector(`.${containerClass}`)!
        const { height: containerHeight, y: containerY } =
          el.getBoundingClientRect()

        const { height, y } = optionsListRef.current.getBoundingClientRect()

        const containerEndY = containerHeight + containerY
        const optionsEndY = height + y

        if (containerEndY < optionsEndY) {
          setMenuHeight(DEFAULT_MENU_HEIGHT - (optionsEndY - containerEndY))
        }
      }
    }
  }, [isOpen, containerClass])

  return (
    <SelectWrap className={className}>
      <SelectContainer>
        <Select
          ref={selectRef}
          tabIndex={0}
          role="button"
          isOpen={isOpen}
          aria-pressed={isOpen}
          aria-expanded={isOpen}
          onClick={handleSelectClick}
          onKeyDown={handleSelectKeydown}
          isInvalid={!!error}
        >
          <SelectText
            tag="span"
            hasValue={Boolean(value)}
            fontStyle={FONT_STYLE_SOFIA_16_500}
            isInvalid={!!error}
          >
            {renderValue && value
              ? renderValue(value)
              : value
              ? value
              : placeholder}
          </SelectText>
          {isOpen ? <CaretUp size={16} /> : <CaretDown size={16} />}
        </Select>
        <Options
          ref={optionsListRef}
          isOpen={isOpen}
          isInvalid={!!error}
          style={{ maxHeight: menuHeight }}
        >
          {options.map((option, index) => (
            <OptionsItemLi
              key={option}
              tabIndex={0}
              role="option"
              ref={(ref) => (optionRefs.current[index] = ref!)}
              onClick={handleOptionClick(option, index)}
              onKeyDown={handleOptionKeyDown(option, index)}
              aria-selected={index === selectedIndex}
              aria-posinset={index}
              aria-setsize={options.length}
            >
              <Text tag="span" fontStyle={FONT_STYLE_SOFIA_16_500}>
                {children ? children(option, index) : option}
              </Text>
            </OptionsItemLi>
          ))}
        </Options>
      </SelectContainer>
      {error && error !== '' && (
        <ErrorText testId="input-error">{error}</ErrorText>
      )}
    </SelectWrap>
  )
}

const SelectWrap = styled.div`
  width: 100%;
`

const SelectContainer = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
`

const Select = styled.div<{
  isOpen: boolean
  isInvalid: boolean
}>`
  padding: 11px 20px;
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  border: 1px solid var(--darkGrey1);
  border-bottom: ${({ isOpen }) =>
    `1px solid ${isOpen ? 'transparent' : 'var(--darkGrey1)'}`};
  cursor: pointer;
  overflow: hidden;

  ${({ isInvalid, isOpen }) =>
    isInvalid &&
    `
    border: 1px solid var(--validationError);
    border-bottom: 1px solid ${
      isOpen ? 'transparent' : 'var(--validationError)'
    };

  `}
`

const Options = styled.ul<{
  isOpen: boolean
  isInvalid: boolean
}>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: white;
  width: 100%;
  border-left: 1px solid var(--darkGrey1);
  border-right: 1px solid var(--darkGrey1);
  border-bottom: 1px solid var(--darkGrey1);
  z-index: 5;
  height: fit-content;
  cursor: pointer;
  overflow: scroll;

  ${({ isInvalid }) =>
    isInvalid &&
    `
      border-left: 1px solid var(--validationError);
      border-right: 1px solid var(--validationError);
      border-bottom: 1px solid var(--validationError);
  `}
`

const OptionsItemLi = styled.li`
  width: 100%;
  padding: 1.1rem 2rem;
  text-align: left;
  color: var(--accessibleGrey);

  @media (hover: hover) {
    &:hover {
      color: var(--black);
      background: var(--softGrey);
    }
  }
`

const SelectText = styled(Text)<{
  hasValue: boolean
  isInvalid: boolean
}>`
  ${(props) => !props.hasValue && `color: var(--accessibleGrey)`};
  position: relative;
  bottom: 0.2rem;
  user-select: none;
  ${(props) =>
    props.isInvalid ? 'var(--validationError)' : 'var(--darkGrey1)'}
`

const ErrorText = styled(Text)`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  color: var(--validationError);
  margin-top: 10px;
`
