import { useRef, useState, useEffect, ReactNode } from 'react'
import { createPortal } from 'react-dom'

type PortalGenericProps = {
  children: ReactNode
  id: string
}

export const PortalGeneric = ({ children, id }: PortalGenericProps) => {
  const ref = useRef<HTMLElement>(null!)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const el = document.getElementById(id)
    ref.current = el!
    if (el) {
      setMounted(true)
    }
  }, [])

  return mounted ? createPortal(children, ref.current) : null
}
