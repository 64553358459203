import Script from 'next/script'

export const TrackingOptimize = () => {
  if (!process.env.NEXT_PUBLIC_GO_ID) {
    return null
  }

  return (
    <Script
      src={`https://www.googleoptimize.com/optimize.js?id=${process.env.NEXT_PUBLIC_GO_ID}`}
    />
  )
}
