import { useState, ChangeEvent, FormEvent } from 'react'
import { MagnifyingGlass } from 'phosphor-react'
import styled from 'styled-components'

import { IconButton } from '../Button'

import {
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
  styleVisuallyHidden,
} from '@cellulargoods/styles'

export type SearchInputProps = {
  className?: string
  disabled?: boolean
  onSubmit?: (searchValue: string) => Promise<boolean | undefined>
  placeholder?: string
}

export const SearchInput = ({
  className,
  onSubmit,
  placeholder = 'Search',
}: SearchInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const [disabled, setDisabled] = useState(false)

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (onSubmit) {
      setDisabled(true)

      onSubmit(inputValue)
        .then((success) => {
          if (success) {
            setInputValue('')
          }
        })
        .finally(() => setDisabled(false))
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  return (
    <SearchForm
      className={className}
      disabled={Boolean(disabled)}
      onSubmit={handleFormSubmit}
    >
      <SearchLabel>
        <VisiblyHidden>Search</VisiblyHidden>
        <Input
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          value={inputValue}
          onChange={handleInputChange}
        />
      </SearchLabel>
      <SearchAction
        ariaLabel={placeholder}
        type="submit"
        disabled={Boolean(disabled)}
      >
        <MagnifyingGlass size={16} />
      </SearchAction>
    </SearchForm>
  )
}

const SearchForm = styled.form<{ disabled: boolean }>`
  display: inline-flex;
  border: solid 1px var(--black);
  height: 4.8rem;
  background-color: var(--white);
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  width: 100%;
`

const SearchLabel = styled.label`
  display: block;
  height: 100%;
  width: 100%;
  flex: 1;
`

const VisiblyHidden = styled.span`
  ${styleVisuallyHidden}
`

const Input = styled.input`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding: 0.6rem 2rem 1.1rem;
  border-radius: 0;
`

const SearchAction = styled(IconButton)`
  width: 4.4rem;
  height: 100%;
  border-left: solid 1px var(--black);
  opacity: 1;

  @media (hover: hover) {
    &:hover {
      background-color: var(--lightGrey);
    }
  }
`
