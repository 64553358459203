import styled from 'styled-components'

import { Button, ButtonTypes } from '../Button'
import { Heading } from './Heading'
import { Text } from './Text'

import { Sanity } from '@cellulargoods/types'

import { FONT_STYLE_SOFIA_32_500 } from '@cellulargoods/styles'

export type TextGridItemProps = Sanity.Keyed<Sanity.TextGridItem> & {
  className?: string
}

export const TextGridItem = ({
  title,
  text,
  CTAButton,
  className,
}: TextGridItemProps) => {
  return (
    <TextGridContainer className={className}>
      {title && (
        <TextGridHeader tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
          {title}
        </TextGridHeader>
      )}
      {text &&
        text
          .split('\n')
          .filter((textum) => Boolean(textum))
          .map((textum, i) => <TextGridText key={i}>{textum}</TextGridText>)}
      {CTAButton && CTAButton.label && CTAButton.url && (
        <TextGridCta
          variant={ButtonTypes.SECONDARY}
          tag="a"
          isExternal={Boolean(CTAButton.isExternal)}
          href={CTAButton.url}
        >
          {CTAButton.label}
        </TextGridCta>
      )}
    </TextGridContainer>
  )
}

const TextGridContainer = styled.section``

const TextGridHeader = styled(Heading)``

const TextGridText = styled(Text)`
  margin-top: 2rem;
`

const TextGridCta = styled(Button)`
  margin-top: 3rem;
`
