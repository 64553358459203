import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'
import {
  aspectRatio,
  MEDIA_QUERIES,
  FONT_STYLE_SOFIA_16_500,
} from '@cellulargoods/styles'

import { Image } from '../Image'
import { Heading } from '../Text'

import { checkForHttps, hasSubdomain } from '../../helpers/links'

const LogoItem = (item: Sanity.Keyed<Sanity.LogoItem>) => {
  const { image, url, label, asset, type } = item

  if (!image) return null

  const img = <Image image={image} layout="fill" objectFit="contain" />

  let actualUrl = type === 'url' ? url : asset

  if (actualUrl) {
    if (!checkForHttps(actualUrl) && hasSubdomain(actualUrl)) {
      actualUrl = `https://${url}`
    }

    return (
      <ImageWrap
        key={item._key}
        as="a"
        href={actualUrl}
        target="_blank"
        rel="noopener noreferer"
        aria-label={label ? `Go to ${label}` : ''}
      >
        {img}
      </ImageWrap>
    )
  }

  return <ImageWrap key={item._key}>{img}</ImageWrap>
}

export type ContentLogosProps = Sanity.Keyed<Sanity.LogosBanner>

export const ContentLogos = ({
  items,
  title,
  hasBackground,
}: ContentLogosProps) => {
  if (!items) return null

  return (
    <Section $hasBackground={Boolean(hasBackground)}>
      {title ? (
        <SectionTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_16_500}>
          {title}
        </SectionTitle>
      ) : null}
      <Wrap>{items.map(LogoItem)}</Wrap>
    </Section>
  )
}

const Section = styled.section<{
  $hasBackground: boolean
}>`
  padding: 2rem;
  background-color: ${(props) =>
    !props.$hasBackground ? 'transparent' : 'var(--softGrey)'};

  ${MEDIA_QUERIES.desktopUp} {
    padding: 4rem;
  }
`

const SectionTitle = styled(Heading)`
  text-align: center;
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 4rem;
  }
`

const Wrap = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;

  ${MEDIA_QUERIES.desktopUp} {
    justify-content: space-between;
  }
`

const ImageWrap = styled.div`
  ${aspectRatio(159, 50, false)};
  max-height: 5rem;
  display: inline-block;
  flex: 1 0 15.4rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 15.4rem;
  }
`
