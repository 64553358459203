import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Media } from '../Media'
import { Button, ButtonTypes } from '../Button'
import { Heading } from '../Text'

import {
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_28_500,
  aspectRatio,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'

export type ContentStoryIntroProps = Sanity.Keyed<Sanity.StoryIntro> & {}

export const ContentStoryIntro = ({
  title,
  body,
  CTAButton: cta,
  leftImage,
  rightImage,
}: ContentStoryIntroProps) => {
  return (
    <StoryIntro>
      <StoryImages>
        <StoryLeftImage>
          {leftImage && leftImage.length > 0 && (
            <Media
              {...leftImage[0]}
              sizes={['100vw', '100vw', '100vw', '100vw']}
            />
          )}
        </StoryLeftImage>
        <StoryRightImage>
          {rightImage && rightImage.length > 0 && (
            <Media
              {...rightImage[0]}
              sizes={['100vw', '100vw', '100vw', '100vw']}
            />
          )}
        </StoryRightImage>
      </StoryImages>
      <StoryContent>
        <StoryHeading tag="h2" fontStyle={FONT_STYLE_SOFIA_28_500}>
          {title}
        </StoryHeading>
        <BodyText>{body}</BodyText>
        {cta && cta.label && cta.url && (
          <StoryCta
            href={cta.url}
            tag="a"
            variant={ButtonTypes.SECONDARY}
            isExternal={Boolean(cta.isExternal)}
          >
            {cta.label}
          </StoryCta>
        )}
      </StoryContent>
    </StoryIntro>
  )
}

const StoryIntro = styled.section`
  padding: 2rem;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 6rem 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const StoryImages = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    justify-content: space-between;
  }
`

const StoryLeftImage = styled.div`
  ${aspectRatio(335, 250)}

  ${MEDIA_QUERIES.desktopUp} {
    width: 55%;
    position: relative;
    left: 18%;
  }
`

const StoryRightImage = styled.div`
  margin-top: 2rem;
  ${aspectRatio(335, 430)}

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
    top: 8rem;
    position: relative;
    width: calc((100% / 3) + 4rem);
    right: -4rem;
    z-index: 1;
  }
`

const StoryContent = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    width: ${(5 / 12) * 100}%;
    margin: 0 0 6rem;
  }
`

const StoryHeading = styled(Heading)`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 4rem;
  }
`
const BodyText = styled.p`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)};
  margin-top: 2rem;
`

const StoryCta = styled(Button)`
  margin-top: 3rem;
`
