import { useMemo } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import { Sanity } from '@cellulargoods/types'
import { useWindowResize } from '@cellulargoods/hooks'

import {
  WIDTHS,
  MEDIA_QUERIES,
  aspectRatio,
  FONT_STYLE_SOFIA_28_500,
  getFontStyles,
  FONT_STYLE_SOFIA_40_500,
  FONT_STYLE_SOFIA_22_400,
} from '@cellulargoods/styles'

import IconInstagram from '../../assets/instagram.svg'
import IconFacebook from '../../assets/facebook.svg'
import IconLinkedin from '../../assets/linkedin.svg'
import IconTwitter from '../../assets/twitter.svg'

import { generateSrcSetSizes } from '../Image'
import { Button, ButtonTypes } from '../Button'
import { Heading } from '../Text'

export type CarouselInstagramProps =
  | Sanity.Keyed<Sanity.InstagramCarouselWithYotpo>
  | Sanity.Keyed<Sanity.SocialChannelsPromotionWithYotpo>

const SOCIAL_ICONS: Record<string, any> = {
  instagram: IconInstagram,
  facebook: IconFacebook,
  linkedin: IconLinkedin,
  twitter: IconTwitter,
}

export const CarouselInstagram = (props: CarouselInstagramProps) => {
  const { _type, images, title } = props

  const CTAButton = _type === 'InstagramCarousel' && props.CTAButton

  const socialLinks = _type === 'SocialChannelsPromotion' && props.socialLinks

  const { width } = useWindowResize()

  const displayedImages = useMemo(() => {
    if (!images) {
      return []
    }

    const isLengthEven = images.length % 2 === 0

    if (width >= WIDTHS.desktop || isLengthEven) {
      return images
    }

    return images.slice(0, images.length - 1)
  }, [images, width])

  return (
    <InstagramSection>
      <IntroWrap $isChannels={Boolean(socialLinks)}>
        {title ? (
          <StoryHeading
            tag="h2"
            fontStyle={
              Boolean(socialLinks)
                ? FONT_STYLE_SOFIA_22_400
                : FONT_STYLE_SOFIA_28_500
            }
          >
            {title}
          </StoryHeading>
        ) : null}
        {CTAButton && CTAButton?.label && CTAButton?.url ? (
          <StoryCta
            href={CTAButton.url}
            tag="a"
            variant={ButtonTypes.SECONDARY}
            isExternal={Boolean(CTAButton.isExternal)}
          >
            {CTAButton.label}
          </StoryCta>
        ) : null}
        {socialLinks ? (
          <SocialLinksList>
            {socialLinks.map((link) => {
              const Icon = SOCIAL_ICONS[link.label?.toLowerCase() ?? '']

              return (
                <SocialLinksListItem key={link.label}>
                  <SocialLinksAnchor
                    href={link.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span>{link.label}</span>
                    {Icon ? (
                      <SocialIconContainer>
                        <Icon width={35} height={35} />
                      </SocialIconContainer>
                    ) : null}
                  </SocialLinksAnchor>
                </SocialLinksListItem>
              )
            })}
          </SocialLinksList>
        ) : null}
      </IntroWrap>
      <TileWrap>
        {displayedImages.map((img) => (
          <InstagramTile key={img.imageId}>
            <InstagramIcon width={24} height={24} />
            <Image
              blurDataURL={img.lowResImage}
              placeholder="blur"
              src={img.imageUrl}
              objectFit="cover"
              layout="fill"
              sizes={generateSrcSetSizes(['65vw', null, null, '30vw'])}
            />
          </InstagramTile>
        ))}
      </TileWrap>
    </InstagramSection>
  )
}

const InstagramSection = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${MEDIA_QUERIES.desktopUp} {
    flex-wrap: nowrap;
  }
`

const StoryHeading = styled(Heading)`
  margin-top: 0rem;
  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0rem;
  }
`
const StoryCta = styled(Button)`
  margin-top: 2rem;
`

const IntroWrap = styled.div<{ $isChannels: boolean }>`
  background: var(--lightGrey2);
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.$isChannels ? '2rem' : '4rem 2rem')};
  ${MEDIA_QUERIES.desktopUp} {
    padding: ${(props) => (props.$isChannels ? '2rem' : '4rem')};
  }
`

const InstagramTile = styled.div`
  ${aspectRatio(1, 1, false)};
  z-index: 0;

  flex: 1 0 50%;
  color: var(--white);

  ${MEDIA_QUERIES.desktopUp} {
    flex: 0 1 100%;
  }
`

const InstagramIcon = styled(IconInstagram)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
`

const TileWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${MEDIA_QUERIES.desktopUp} {
    flex-wrap: nowrap;
  }
`

const SocialLinksList = styled.ul`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const SocialLinksListItem = styled.li`
  & + & {
    margin-top: 0.5rem;
  }
`

const SocialLinksAnchor = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-decoration: none;
  ${getFontStyles(FONT_STYLE_SOFIA_40_500)};
  position: relative;
  z-index: 0;
  transition: color 200ms;

  @media (hover: hover) {
    &:hover {
      color: var(--accessibleGrey);
    }
  }
`

const SocialIconContainer = styled.span`
  display: block;
  margin-left: 1.5rem;
  color: inherit;

  & > svg {
    position: relative;
    top: 0.5rem;
  }
`
