import { useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_18_400,
  FONT_STYLE_SOFIA_18_500,
  getFontStyles,
  MEDIA_QUERIES,
  WIDTHS,
} from '@cellulargoods/styles'
import {
  useWindowResize,
  useNewsletterSignup,
  useIntersection,
} from '@cellulargoods/hooks'

import {
  KLAVIYO_LIST_IDS,
  NEWSLETTER_SIGNUP_TEXT,
  NEWSLETTER_SUCCESS_TEXT,
} from '../../references/constants'

import { CheckboxInput, Input } from '../Input'
import { Accordion } from '../Accordion'
import { Heading, Text } from '../Text'

import { getFooterListItemLink } from '../../helpers/links'
import { omit } from '../../helpers/objects'

import MonogramIcon from '../../assets/monogram.svg'
import useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import mergeRefs from 'react-merge-refs'

export type FooterProps = Sanity.Footer & {
  appendWithEcommerceDomain?: boolean
  buyWidgetSticky?: (stuck: boolean) => void
}

export const Footer = ({
  sections,
  appendWithEcommerceDomain,
  buyWidgetSticky,
}: FooterProps) => {
  const [footerColumns, footerNewsletter] = useMemo(() => {
    const footerCols = (sections?.filter(
      (section) => section?._type === 'FooterList' && section.title
    ) ?? []) as Sanity.Keyed<Sanity.FooterList>[]

    const [footerNewsletter] = (sections?.filter(
      (section) => section?._type === 'FooterNewsletter'
    ) ?? []) as Sanity.Keyed<Sanity.FooterNewsletter>[]

    return [footerCols, footerNewsletter]
  }, [sections])

  const { width } = useWindowResize()

  const { state: inputState, events: inputEvents } = useNewsletterSignup(
    'footer',
    omit(
      KLAVIYO_LIST_IDS,
      'interestedInUpcomingProduct',
      'soldoutNotification'
    ) as unknown as KLAVIYO_LIST_IDS
  )

  const footerRef = useRef<HTMLDivElement>(null!)

  const intersection = useIntersection(footerRef)

  const [measureRef, { height }] = useMeasure({
    polyfill: ResizeObserver,
  })

  useEffect(() => {
    if (buyWidgetSticky && intersection) {
      if (intersection.isIntersecting) {
        buyWidgetSticky(true)
      } else if (!intersection.isIntersecting) {
        buyWidgetSticky(false)
      }
    }
  }, [buyWidgetSticky, height, intersection])

  return (
    <Foot ref={mergeRefs([footerRef, measureRef])}>
      <FooterContent
        columnCount={
          footerNewsletter ? footerColumns.length + 1 : footerColumns.length
        }
      >
        {footerColumns.map((col) => (
          <FooterNav key={col._key}>
            <FooterAccordion
              item={{
                label: col.title,
              }}
              forceOpen={width >= WIDTHS.desktop}
              labelFontStyle={FONT_STYLE_SOFIA_18_500}
            >
              <FooterList>
                {col.items?.map((item) =>
                  item
                    ? renderFooterListItem(item, appendWithEcommerceDomain)
                    : null
                )}
              </FooterList>
            </FooterAccordion>
          </FooterNav>
        ))}
        {footerNewsletter && (
          <FooterNewsletter>
            <NewsletterHeading tag="h2" fontStyle={FONT_STYLE_SOFIA_18_500}>
              {footerNewsletter.title}
            </NewsletterHeading>
            <NewsletterText fontStyle={FONT_STYLE_SOFIA_16_400}>
              {footerNewsletter.text}
            </NewsletterText>
            <NewsletterForm onSubmit={(e) => e.preventDefault()}>
              <Input
                label="Your email"
                type="email"
                withArrow
                value={inputState.inputValue}
                error={inputState.error}
                onChange={inputEvents.handleChange}
                onKeyDown={inputEvents.handleKeyDown}
                onInputEnterClick={inputEvents.handleClick}
                disabled={inputState.isSubmitting}
                hasBackground
              />
              <NewsletterCheckbox
                label={[NEWSLETTER_SIGNUP_TEXT]}
                error={inputState.checkError}
                onChange={inputEvents.handleCheckChange}
                checked={inputState.checkedValue}
              />
            </NewsletterForm>
            {inputState.success ? (
              <NewsletterSuccess fontStyle={FONT_STYLE_SOFIA_11_400}>
                {NEWSLETTER_SUCCESS_TEXT}
              </NewsletterSuccess>
            ) : null}
          </FooterNewsletter>
        )}
      </FooterContent>
      <FooterMonogram>
        <MonogramWrapper>
          <MonogramIcon />
        </MonogramWrapper>
      </FooterMonogram>
      <FooterTextWrapper>
        <Disclaimer fontStyle={FONT_STYLE_SOFIA_11_400}>
          These statements have not been evaluated by the MHRA. This product is
          not intended to diagnose, treat, cure or prevent any disease.
        </Disclaimer>
        <Copyright fontStyle={FONT_STYLE_SOFIA_11_400}>
          ©{new Date().getFullYear()} Cellular Goods PLC, All Rights Reserved.
        </Copyright>
      </FooterTextWrapper>
    </Foot>
  )
}

const renderFooterListItem = (
  { _key, label, ...restProps }: Sanity.Keyed<Sanity.FooterListItem>,
  appendWithEcommerceDomain?: boolean
) => {
  const linkProps = getFooterListItemLink(restProps, appendWithEcommerceDomain)

  return (
    <FooterListItem key={_key}>
      <FooterLink {...linkProps}>{label}</FooterLink>
    </FooterListItem>
  )
}

const Foot = styled.footer`
  width: 100%;
  background-color: var(--lightBrown);
  padding: 3rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 5.2rem 4rem 3rem 4rem;
  }
`

const FooterContent = styled.div<{
  columnCount: number
}>`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;

    & > * {
      flex-basis: ${(props) => 100 / props.columnCount}%;
    }
  }
`

const FooterNav = styled.nav``

const FooterAccordion = styled(Accordion)`
  border: none;

  &:last-child {
    border: none;
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
  }
`

const FooterList = styled.ul``

const FooterListItem = styled.li`
  & + & {
    margin-top: 2rem;
  }
`

const FooterLink = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_18_400)}
  color: var(--darkGrey2);
  text-decoration: none;
`

const FooterNewsletter = styled.div`
  padding: 0 2rem;
`

const NewsletterHeading = styled(Heading)`
  margin: 2.8rem 0;
  color: var(--black);

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 1rem;
  }
`

const NewsletterText = styled(Text)`
  color: var(--darkGrey2);
  margin-bottom: 2rem;
`

const NewsletterSuccess = styled(Text)`
  color: var(--darkGrey2);
  margin-bottom: 1rem;
  margin-top: 2rem;
`

const FooterMonogram = styled.div`
  width: 100%;
  padding: 2rem 2rem 4rem 2rem;
  display: flex;
  justify-content: center;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 0 8rem 0;
  }
`

const MonogramWrapper = styled.div`
  height: 4rem;
  width: 3.5rem;

  & > svg {
    fill: var(--black);
  }

  ${MEDIA_QUERIES.desktopUp} {
    height: 10rem;
    width: 8.75rem;
  }
`

const FooterTextWrapper = styled.div`
  padding: 0 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 3rem 0 0 0;
    display: flex;
    justify-content: space-between;
  }
`

const Disclaimer = styled(Text)`
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 0;
  }
`

const Copyright = styled(Text)``

const NewsletterForm = styled.form``

const NewsletterCheckbox = styled(CheckboxInput)`
  color: var(--accessibleGrey);
`
