import styled from 'styled-components'

import { FONT_STYLE_SOFIA_11_400, getFontStyles } from '@cellulargoods/styles'

type OnSaleIndicatorProps = {
  className?: string
  small?: boolean
  text?: string
}

export const OnSaleIndicator = ({
  className,
  small = false,
  text,
}: OnSaleIndicatorProps) =>
  text ? (
    <OnSale small={small} className={className}>
      {text}
    </OnSale>
  ) : null

const OnSale = styled.span<{
  small: boolean
}>`
  color: var(--white);
  background-color: #54635a;
  padding: 0.8rem 0.6rem;
  margin: 0;
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  text-align: center;
`
