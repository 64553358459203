import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NonNullSkipArray, Sanity } from '@cellulargoods/types'

import {
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_40_500,
  WIDTHS,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Heading, Text } from '../Text'
import { Bg, Button, ButtonTypes } from '../Button'
import { Media } from '../Media'

import { getSanityAsset } from '../../helpers/assets'

export type ContentHeroImageProps = Sanity.Keyed<Sanity.HeroImage> & {}

export const ContentHeroImage = ({
  title,
  title_width,
  subtitle,
  text,
  desktop_media: desktopMedia,
  mobile_media: mobileMedia,
  CTAButton,
}: ContentHeroImageProps) => {
  const [image, setImage] = useState<NonNullSkipArray<Sanity.Media> | null>(
    getSanityAsset(desktopMedia)
      ? desktopMedia![0]
      : getSanityAsset(mobileMedia)
      ? mobileMedia![0]
      : null
  )

  useEffect(() => {
    const handleResize = () => {
      if (image) {
        const currentAsset = getSanityAsset([image])
        const mobileAsset = getSanityAsset(mobileMedia)
        const desktopAsset = getSanityAsset(desktopMedia)

        if (
          window.innerWidth < WIDTHS.desktop &&
          mobileAsset &&
          mobileAsset !== currentAsset
        ) {
          setImage(mobileMedia![0])
        } else if (
          window.innerWidth >= WIDTHS.desktop &&
          desktopAsset &&
          desktopAsset !== currentAsset
        ) {
          setImage(desktopMedia![0])
        }
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [image, mobileMedia, desktopMedia])

  const hasCTA = CTAButton && CTAButton.label && CTAButton.url

  const hasContent = title || subtitle || text || hasCTA

  return (
    <Hero>
      {image && (
        <HeroImg>
          <Media {...image} sizes={['100vw']} />
        </HeroImg>
      )}
      {hasContent && (
        <HeroContent>
          {title && (
            <HeroTitle
              titleWidth={title_width ? title_width : 650}
              fontStyle={FONT_STYLE_SOFIA_40_500}
            >
              {title}
            </HeroTitle>
          )}
          {subtitle && (
            <HeroSubtitle fontStyle={FONT_STYLE_SOFIA_22_500}>
              {subtitle}
            </HeroSubtitle>
          )}
          {text && <HeroCopy>{text}</HeroCopy>}
          {hasCTA && (
            <HeroAction
              variant={ButtonTypes.PRIMARY}
              tag="a"
              isExternal={Boolean(CTAButton.isExternal)}
              href={CTAButton.url ?? ''}
              bg={Bg.white}
            >
              {CTAButton.label}
            </HeroAction>
          )}
        </HeroContent>
      )}
    </Hero>
  )
}

const Hero = styled.section`
  position: relative;
  min-height: 83vh;
  z-index: 0;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    align-items: center;
  }
`

const HeroImg = styled.div`
  width: 100%;
  height: 100%;
  min-height: 80vh;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
`

const HeroContent = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 120rem;
    padding: 12rem 4rem;

    p {
      max-width: 440px;
    }
  }
`

const HeroTitle = styled(Heading)<{ titleWidth: number }>`
  margin-bottom: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    font-size: 4.2rem;
    line-height: 5.2rem;
    max-width: ${(props) => props.titleWidth}px;
  }
`

const HeroSubtitle = styled(Heading)`
  margin-bottom: 2rem;
`

const HeroCopy = styled(Text)`
  margin-bottom: 2rem;
`

const HeroAction = styled(Button)`
  min-width: 21rem;
  margin-bottom: 3rem;
  display: inline-block;

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 0;
  }
`
