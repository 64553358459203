import { forwardRef } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import {
  FONT_STYLE_SOFIA_22_500,
  MEDIA_QUERIES,
  aspectRatio,
} from '@cellulargoods/styles'

import { Sanity, PickType } from '@cellulargoods/types'

import { Tag } from '../Ornaments/Tag'
import { Media } from '../Media'
import { Heading, Text } from '../Text'

import { createArticleLink } from '../../helpers/links'

export type ArticleCardProps = Pick<Sanity.InternalArticle, 'title' | 'slug'> &
  Pick<Sanity.ExternalArticle, 'file' | 'link'> & {
    className?: string
    tags?: PickType<Sanity.InternalArticle, 'Tags'>
    info?: PickType<Sanity.InternalArticle, 'ArticleCardInfo'>
    isInGrid?: boolean
    inCarousel?: boolean
  }

export const ArticleCard = forwardRef<HTMLDivElement, ArticleCardProps>(
  (
    {
      title,
      slug,
      link,
      file,
      tags,
      info,
      className,
      isInGrid = false,
      inCarousel,
    },
    ref
  ) => {
    const [tag] = tags ?? []
    const { title: tagTitle, slug: tagSlug } = tag ?? {}

    const { hrefTo, hrefProps } = createArticleLink({
      slug,
      tagSlug,
      link,
      file,
    })

    return (
      <Card ref={ref} className={className} isInGrid={isInGrid}>
        <Link href={hrefTo} passHref>
          <CardMedia
            draggable="false"
            inCarousel={Boolean(inCarousel)}
            {...hrefProps}
          >
            {info?.media && info?.media?.length > 0 ? (
              <Media {...info.media[0]} sizes={['100vw', null, '33vw', null]} />
            ) : null}
          </CardMedia>
        </Link>
        <CardContent isInGrid={isInGrid}>
          {tags && tags.length > 0 && <CardTag title={tagTitle} />}
          <Link href={hrefTo}>
            <a {...hrefProps}>
              <CardTitle tag="h3" fontStyle={FONT_STYLE_SOFIA_22_500}>
                {title}
              </CardTitle>
            </a>
          </Link>
          <Link href={hrefTo}>
            <a {...hrefProps}>
              <CardBody>{info?.excerpt}</CardBody>
            </a>
          </Link>
        </CardContent>
      </Card>
    )
  }
)

const Card = styled.div<{
  isInGrid: boolean
}>`
  padding: ${(props) => (!props.isInGrid ? '2rem 2rem 4rem 2rem' : '')};

  & a {
    text-decoration: none;
  }
`

const CardMedia = styled.a<{
  inCarousel: boolean
}>`
  ${(props) => (props.inCarousel ? aspectRatio(1, 1) : aspectRatio(335, 408))};
  display: block;
  background-color: var(--softGrey);

  img {
    transition: transform 400ms ease-out;
  }

  @media (hover: hover) {
    &:hover img {
      transform: scale(1.04);
    }
  }
`

const CardContent = styled.div<{
  isInGrid: boolean
}>`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
    padding: ${(props) => (props.isInGrid ? '3rem 0 0 0' : '3rem 4rem')};
  }
`

const CardTag = styled(Tag)``

const CardTitle = styled(Heading)`
  margin-top: 2rem;
`

const CardBody = styled(Text)`
  margin-top: 2rem;
`
