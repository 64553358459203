import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Heading, Text } from '../Text'

import {
  FONT_STYLE_SOFIA_14_400,
  FONT_STYLE_SOFIA_32_500,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Table } from './Table'

export type ComparisonTableProps = Sanity.Keyed<Sanity.ComparisonTable>

export const ComparisonTable = ({
  title,
  text,
  comparisonTable,
  additionText,
}: ComparisonTableProps) => (
  <TableContainer>
    <TableCopy>
      <TableHeading tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
        {title}
      </TableHeading>
      <TableText>{text}</TableText>
    </TableCopy>
    <TableTable>
      {comparisonTable ? <Table rows={comparisonTable.rows ?? []} /> : null}
    </TableTable>
    <TableExtraText fontStyle={FONT_STYLE_SOFIA_14_400}>
      {additionText}
    </TableExtraText>
  </TableContainer>
)

const TableContainer = styled.section``

const TableCopy = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 67rem;
    padding: 8rem 4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const TableHeading = styled(Heading)`
  margin-bottom: 2rem;
  text-align: center;
`

const TableText = styled(Text)`
  ${MEDIA_QUERIES.desktopUp} {
    text-align: center;
  }
`

const TableExtraText = styled(Text)`
  margin: 2rem 0;
  padding: 0 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 67rem;
    padding: 0 4rem;
  }
`

const TableTable = styled.div`
  padding-left: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem;
  }
`
