import { Maybe, Sanity } from '@cellulargoods/types'

export const getSanityAsset = (
  mediaArray?: Maybe<Sanity.Media>
): string | null => {
  if (!mediaArray) {
    return null
  } else {
    const [item] = mediaArray

    if (!item) {
      return null
    } else {
      switch (item._type) {
        case 'MediaImage':
          return (item.asset as unknown as Sanity.SanityImageAsset).url ?? null
        case 'MediaMux':
          return item.muxVideoFile?.asset?.playbackId ?? null
        case 'MediaYoutube':
          return item.url ?? null
        default:
          console.warn('Passed a non-valid media asset to getSanityAsset')
          return null
      }
    }
  }
}

/**
 * Takes a `productId` (string) from a Line Item Variant and
 * matches it with `product.IdEncoded` (string) and then returns the Sanity.MediaImage
 * associated with that product.
 *
 * This is used when getting customer Order information to match up Shopify Products
 * with Sanity Products to allow us to display the Sanity image instead of the Shopify image.
 */
export const getSanityProductMediaImage = (
  lineItemVariantProductId: string | null | undefined,
  products: Sanity.Product[] | undefined
) => {
  const sanityProduct = products?.find(
    ({ product }) => product && product.idEncoded === lineItemVariantProductId
  )
  return sanityProduct && sanityProduct.image
}
