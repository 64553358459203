import { Sanity } from '@cellulargoods/types'
import styled from 'styled-components'

import { Button, ButtonTypes } from '../Button'
import { Image } from '../Image'
import { Text, Heading } from '../Text'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  aspectRatio,
} from '@cellulargoods/styles'

interface TeamMemberCardProps extends Sanity.TeamMember {
  onReadMoreClick: () => void
  showBio?: boolean
}

export const TeamMemberCard = ({
  name,
  jobTitle,
  image,
  onReadMoreClick,
  showBio,
}: TeamMemberCardProps) => {
  return (
    <Wrap>
      {image && (
        <ImageWrap>
          <Image
            image={image}
            layout="fill"
            objectFit="contain"
            sizes={['100vw', null, null, '33vw']}
          />
        </ImageWrap>
      )}
      <Heading fontStyle={FONT_STYLE_SOFIA_22_500}>{name}</Heading>
      <JobTitle fontStyle={FONT_STYLE_SOFIA_16_400}>{jobTitle}</JobTitle>
      {showBio && (
        <Button variant={ButtonTypes.TERTIARY} onClick={onReadMoreClick}>
          Read more
        </Button>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
`

const ImageWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  ${aspectRatio(440, 400, false)};
  background-color: var(--softGrey);

  & img {
    background-repeat: no-repeat;
    background-position-x: 50% !important;
  }
`

const JobTitle = styled(Text)`
  margin: 20px 0;
`
