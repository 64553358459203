import { ArrowElbowDownLeft, Eye, EyeClosed } from 'phosphor-react'
import React, {
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { animated, useSpring } from '@react-spring/web'

import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_18_400,
  getFontStyles,
} from '@cellulargoods/styles'

import { IconButton } from '../Button'
import { Text } from '../Text'

export interface InputProps
  extends Partial<Pick<HTMLInputElement, 'min' | 'max'>> {
  autoComplete?: string
  error?: string
  label: string
  name?: string
  onChange?: (text: string, name: string | undefined) => void
  required?: boolean
  type: string
  value?: string
  disabled?: boolean
  withArrow?: boolean
  hasBackground?: boolean
  onInputEnterClick?: (e: MouseEvent<HTMLButtonElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onBlur?: (value: string) => void
  onFocus?: (value: string) => void
  placeholder?: string
  testId?: string
  className?: string
}

export const Input = ({
  autoComplete,
  disabled,
  error,
  label,
  name,
  onChange,
  type,
  value,
  withArrow = false,
  onInputEnterClick,
  onKeyDown,
  hasBackground = false,
  onFocus,
  onBlur,
  placeholder,
  className,
  testId,
  ...restProps
}: InputProps) => {
  const valueRef = useRef<string | null>(null)
  const [showPassword, setShowPassword] = useState(false)
  const [internalValue, setInternalValue] = useState('')
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value, name)
    }
    setInternalValue(e.target.value)
  }

  const handleShowPasswordClick = () => {
    return setShowPassword(!showPassword)
  }

  const handleInputEnterClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onInputEnterClick) {
      onInputEnterClick(e)
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      onKeyDown(e)
    }
  }

  const actualValue = value || internalValue

  const [styles, api] = useSpring(
    () => ({
      from: {
        translateY: actualValue ? '-150%' : '-50%',
        translateX: actualValue ? '-20%' : '0%',
        scale: actualValue ? 0.6 : 1,
      },
    }),
    []
  )

  const handleFocus = () => {
    api.start({
      to: {
        translateY: '-150%',
        translateX: '-20%',
        scale: 0.6,
      },
    })
    if (onFocus) {
      onFocus(internalValue)
    }
  }

  const handleBlur = useCallback(() => {
    if (!Boolean(actualValue)) {
      if (onBlur) {
        onBlur(internalValue)
      }

      api.start({
        to: {
          translateY: '-50%',
          translateX: '0%',
          scale: 1,
        },
      })
    }
  }, [api, actualValue, internalValue])

  /**
   * Starts the animation if value exists but
   * the valueRef is null.
   *
   * This ensures the animation will fire if the user
   * uses an autocomplete feature for things such as address autofill.
   *
   * onChange doesn't fire when it comes to autofill.
   */
  useEffect(() => {
    if (actualValue && valueRef.current === null) {
      valueRef.current = actualValue
      api.start({
        to: {
          translateY: '-150%',
          translateX: '-20%',
          scale: 0.6,
        },
      })
    }

    if (
      valueRef.current !== null &&
      typeof actualValue === 'string' &&
      actualValue.length === 0
    ) {
      valueRef.current = null
      handleBlur()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualValue, handleBlur])

  return (
    <Wrap
      className={className}
      aria-disabled={disabled}
      disabled={Boolean(disabled)}
    >
      <InputLabel isInvalid={!!error}>
        {label && (
          <TopLabel $hasBackground={hasBackground} style={styles}>
            {label}
          </TopLabel>
        )}
        <StyledInput
          disabled={disabled}
          autoComplete={autoComplete}
          name={name}
          value={actualValue}
          type={showPassword && type === 'password' ? 'text' : type}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          data-testid={testId}
          {...restProps}
        />
        {type === 'password' && (
          <EyeButton
            onClick={handleShowPasswordClick}
            ariaLabel={showPassword ? 'Hide Password' : 'Show Password'}
            disabled={disabled}
            icon={
              showPassword ? (
                <Eye size={20} />
              ) : (
                <EyeClosed
                  size={20}
                  color={
                    Boolean(actualValue) ? 'var(--black)' : 'var(--midGrey)'
                  }
                />
              )
            }
          />
        )}
        {withArrow && (
          <ArrowButton
            disabled={disabled}
            ariaLabel="Submit"
            onClick={handleInputEnterClick}
          >
            <ArrowElbowDownLeft size={20} weight="thin" />
          </ArrowButton>
        )}
      </InputLabel>
      {error && error !== '' && (
        <ErrorText testId="input-error">{error}</ErrorText>
      )}
    </Wrap>
  )
}

const Wrap = styled.div<{
  disabled: boolean
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
`

const InputLabel = styled.label<{
  isInvalid: boolean
}>`
  position: relative;

  ${({ isInvalid }) =>
    isInvalid &&
    `
    input, span {
      color: var(--validationError);
    }
  
    input {
      border-bottom: 0.5px solid var(--validationError);
    }
  `}
`
const StyledInput = styled.input`
  ${getFontStyles(FONT_STYLE_SOFIA_18_400)};
  height: 50px;
  padding-left: 0;
  padding-right: 10px;
  margin-top: 10px;
  border: none;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
  outline: none;
  width: 100%;
  background-color: transparent;
  color: var(--darkGrey1);
  border-radius: 0;
`

const EyeButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 50%;
  height: 100%;
  transform: translateY(-50%);
`

const ArrowButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 60%;
  height: 100%;
  transform: translateY(-50%);
`

const ErrorText = styled(Text)`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  color: var(--validationError);
  margin-top: 10px;
`

const TopLabel = styled(animated.span)<{
  $hasBackground: boolean
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_18_400)}
  color:${(props) =>
    props.$hasBackground ? 'var(--darkGrey2)' : 'var(--darkGrey1)'};
  position: absolute;
  top: calc(50% + 15px);
  left: 0;
  margin-top: -10px;
`
