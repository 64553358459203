import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { ArticleCard } from '../Cards'
import { Heading } from '../Text'
import { Button, ButtonTypes } from '../Button'

import { MEDIA_QUERIES, FONT_STYLE_SOFIA_32_500 } from '@cellulargoods/styles'

import { createSlug } from '../../helpers/links'

import { SlugType } from '../../references/constants'

import { Carousel, CarouselFlex } from './Carousel'

export type CarouselArticlesProps = Sanity.Keyed<Sanity.ArticleCarousel>

export const CarouselArticles = (props: CarouselArticlesProps) => {
  const { articles, title, background } = props

  const forceSize = articles ? articles.length <= 3 : true

  if (!articles || articles.length === 0) {
    return null
  }

  return (
    <CarouselContainer background={Boolean(background)} forceSize={forceSize}>
      {!forceSize && title ? (
        <CarouselTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
          {title}
        </CarouselTitle>
      ) : null}
      {!forceSize && (
        <CarouselCta
          variant={ButtonTypes.SECONDARY}
          tag="a"
          label="Read all articles"
          href={createSlug(SlugType.LEARN_ARTICLE, ['all'])}
        />
      )}
      <ArticlesCarousel
        forceSize={forceSize}
        hideButtons={forceSize}
        disableDrag={forceSize}
      >
        {articles.map(
          (article, index) =>
            article &&
            article.reference && (
              <CarouselArticleCard
                key={article._key ?? index}
                title={article.reference.title}
                slug={(article.reference as Sanity.InternalArticle).slug}
                link={(article.reference as Sanity.ExternalArticle).link}
                file={(article.reference as Sanity.ExternalArticle).file}
                tags={article.reference.Tags}
                info={article.reference.ArticleCardInfo}
                forceSize={forceSize}
                inCarousel
                isInGrid={!forceSize}
              />
            )
        )}
      </ArticlesCarousel>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div<{
  background: boolean
  forceSize: boolean
}>`
  background-color: ${(props) =>
    props.background ? 'var(--softGrey)' : 'transparent'};
  padding: ${(props) => (!props.forceSize ? '4rem 0 4rem 2rem' : 0)};
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    width: 100%;
    padding: ${(props) => (!props.forceSize ? '8rem 0' : '0 0 5rem 0')};
  }
`

const CarouselTitle = styled(Heading)`
  margin-bottom: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0 15.5rem 6rem 15.5rem;
  }
`

const CarouselCta = styled(Button)`
  margin-bottom: 6rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const ArticlesCarousel = styled(Carousel)<{
  forceSize: boolean
}>`
  padding-right: ${(props) => (props.forceSize ? '' : '2rem')};

  ${CarouselFlex} {
    ${(props) =>
      props.forceSize
        ? /* css */ `
    flex-direction: column;
    align-items: initial;
  `
        : `
        `}
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding-right: ${(props) => (props.forceSize ? '0' : '4rem')};

    ${CarouselFlex} {
      padding-left: 0;

      ${(props) =>
        props.forceSize
          ? `
        justify-content: center;\
        flex-direction: row;

        & > * {
          max-width: ${100 / 3}%;
        }
        `
          : `
        padding-bottom: 4rem;
        `};
    }
  }
`

const CarouselArticleCard = styled(ArticleCard)<{
  forceSize: boolean
}>`
  ${(props) =>
    !props.forceSize
      ? `
    padding: 0;
    max-width: 27.5rem;
    flex: 1 0 44rem;

    & + & {
      margin-left: 2rem;
    }
  `
      : `
    flex-basis: 100%;

    & + & {
      margin-left: 0;
    }
  `}

  ${MEDIA_QUERIES.desktopUp} {
    max-width: unset;
    padding: 0;
    height: 100%;

    ${(props) =>
      !props.forceSize &&
      `
    &:first-child {
      margin-left: 15.5rem;
    }
    &:last-child {
      margin-right: 15.5rem;
    }
  `}
  }
`
