import { ReactNode } from 'react'
import styled from 'styled-components'

import { getFontStyles, FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'

type IsNewTagsProps = {
  className?: string
  children?: ReactNode
}

export const IsNewTag = ({ className, children }: IsNewTagsProps) => (
  <IsNew className={className}>{children}</IsNew>
)

const IsNew = styled.span`
  display: inline-block;
  background-color: var(--darkElectricBlue);
  color: var(--white);
  padding: 0.8rem 0.6rem;
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)};
  text-transform: uppercase;
  text-align: center;
`
