import { useEffect, useMemo, useState } from 'react'
import PageVisibility from 'react-page-visibility'
import ReactTicker from 'react-ticker'
import styled from 'styled-components'
import { Maybe } from '@cellulargoods/types'

import { Heading } from '../Text'

import { FONT_STYLE_SOFIA_48_500, MEDIA_QUERIES } from '@cellulargoods/styles'

export type TickerProps = {
  text?: Maybe<string> | undefined
  children?: string
}

export const ContentTicker = ({ text, children }: TickerProps) => {
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = (isVisible: boolean) => {
    setPageIsVisible(isVisible)
  }

  const elements = useMemo(() => {
    const items = text ?? children

    return items?.split('\n').map((item) => item.trim()) ?? []
  }, [text, children])

  /**
   * Lil' ugly hack to completely reset the ticker text when
   * the text changes
   */
  useEffect(() => {
    setPageIsVisible(false)
    window.setTimeout(() => setPageIsVisible(true), 1)
  }, [text, children])

  return (
    <SectionWrap>
      <PageVisibility onChange={handleVisibilityChange}>
        <div style={{ height: 80 }}>
          {pageIsVisible && (
            <ReactTicker mode="chain" speed={20} height={80}>
              {(index) => (
                <TickerText fontStyle={FONT_STYLE_SOFIA_48_500}>
                  {elements.map((copy, elIndex) => (
                    <span key={`${copy}_${elIndex}_${index}`}>{copy}</span>
                  ))}
                </TickerText>
              )}
            </ReactTicker>
          )}
        </div>
      </PageVisibility>
    </SectionWrap>
  )
}

const SectionWrap = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 4rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8.4rem 0;
  }
`

const TickerText = styled(Heading)`
  display: inline;
  white-space: nowrap;

  & > span {
    margin-right: 4rem;
  }
`
