import { forwardRef, useMemo, useState } from 'react'
import styled from 'styled-components'

import { NonNullSkipArray, Yotpo } from '@cellulargoods/types'
import {
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_22_500,
  aspectRatio,
  getFontStyles,
  MEDIA_QUERIES,
  FONT_STYLE_SOFIA_14_500,
} from '@cellulargoods/styles'

import { Text, Heading } from '../Text'
import { Media } from '../Media'
import { Button, ButtonTypes } from '../Button'
import { PortalUGCReadMore } from '../Portals'
import { ReviewStarRating } from '../Reviews'

export type ReviewCardProps = Yotpo.Review & {
  product: NonNullSkipArray<Yotpo.CategoryReviews['reviews']>['product']
  className?: string
}

export const ReviewCard = forwardRef<HTMLDivElement, ReviewCardProps>(
  ({ title, content, score, user, customFields, product, className }, ref) => {
    const [isActive, setIsActive] = useState(false)

    const stars = useMemo(() => <ReviewStarRating rating={score} />, [score])

    const cardDetails = useMemo(
      () => [
        user.name,
        ...Object.values(customFields ?? {})
          .filter((field) => field.title === 'Location')
          .map((field) => field.value),
      ],
      [user, customFields]
    )

    const handleCloseClick = () => {
      if (isActive) {
        setIsActive(false)
      }
    }

    const handleReadMoreClick = () => {
      setIsActive(true)
    }

    return (
      <Card ref={ref} className={className}>
        <CardHeader>
          {product?.image && (
            <CardMedia>
              <Media {...product.image} sizes={['25vw', null, '9vw', null]} />
            </CardMedia>
          )}
          <CardDetails>
            <CardDetailList>
              {cardDetails.map((detail, index) => (
                <CardDetailItem key={index}>{detail}</CardDetailItem>
              ))}
            </CardDetailList>
            <CardHeaderStars>{stars}</CardHeaderStars>
          </CardDetails>
        </CardHeader>
        <CardContent>
          <CardContentStars>{stars}</CardContentStars>
          <CardTitle fontStyle={FONT_STYLE_SOFIA_22_500}>{title}</CardTitle>
          <CardCopy>{content}</CardCopy>
          {content.length > 280 ? (
            <CardCta
              variant={ButtonTypes.TERTIARY}
              onClick={handleReadMoreClick}
            >
              Read More
            </CardCta>
          ) : null}
        </CardContent>
        <PortalUGCReadMore
          stars={score}
          username={user.name}
          content={content}
          sanityImage={product?.image}
          imageMode="sanity"
          source="review"
          id="portal-root"
          isActive={isActive}
          onCloseClick={handleCloseClick}
        />
      </Card>
    )
  }
)

const Card = styled.div`
  background-color: var(--white);
  padding: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 4rem;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2rem;
`

const CardMedia = styled.div`
  ${aspectRatio(95, 138, false)}
  margin-right: 2rem;
  flex-basis: 35%;
`

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0;
`

const CardDetailList = styled.ul``

const CardDetailItem = styled.li`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}

  &:not(:first-child) {
    color: var(--accessibleGrey);
  }
`

const CardHeaderStars = styled.div`
  display: block;

  ${MEDIA_QUERIES.desktopUp} {
    display: none;
  }
`

const CardContent = styled.div`
  margin-top: 2rem;
`

const CardContentStars = styled.div`
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: block;
  }
`

const CardTitle = styled(Heading)`
  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 2rem;
  }
`

const CardCopy = styled(Text)`
  margin-top: 2rem;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 7;

  ${MEDIA_QUERIES.desktopUp} {
    -webkit-line-clamp: 6;
  }
`

const CardCta = styled(Button)`
  margin-top: 1rem;

  & > span {
    ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  }
`
