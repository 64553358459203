import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import {
  FONT_STYLE_SOFIA_16_500,
  getFontStyles,
  MEDIA_QUERIES,
  hideScrollBar,
} from '@cellulargoods/styles'

import { capitalizeFirstLetter } from '../../helpers/strings'

export interface TabBarProps {
  className?: string
  tabs: string[]
  onTabClick: (val: string | null) => void
  activeTab: string | null
  masterTab: string
  fontStyle?: string
  scrollOnMobile?: boolean
  disablePadding?: boolean
  useMasterTab?: boolean
}

export const TabBar = ({
  className,
  tabs,
  activeTab,
  onTabClick,
  masterTab,
  fontStyle = FONT_STYLE_SOFIA_16_500,
  scrollOnMobile,
  useMasterTab = true,
  disablePadding = false,
}: TabBarProps) => {
  const allTabs = useMemo(
    () =>
      [masterTab, ...tabs.map(capitalizeFirstLetter)].filter((tab) =>
        Boolean(tab)
      ) as string[],
    [masterTab, tabs]
  )

  const handleTabButtonClick = (val: string) => () => {
    if (onTabClick) {
      if (val !== masterTab) {
        onTabClick(val.toLowerCase())
      } else {
        onTabClick(null)
      }
    }
  }

  return (
    <Wrap className={className} disablePadding={disablePadding}>
      <TabNav scrollOnMobile={scrollOnMobile}>
        {allTabs.map((tab) => (
          <TabItem
            key={tab}
            isActive={
              activeTab === tab.toLowerCase() ||
              (activeTab === null && tab === masterTab && useMasterTab)
            }
          >
            <TabButton
              type="button"
              aria-label={`view ${tab}`}
              onClick={handleTabButtonClick(tab)}
              fontStyle={fontStyle}
            >
              <span>{tab}</span>
              <div
                style={{
                  visibility:
                    activeTab === tab.toLowerCase() ||
                    (activeTab === null && tab === masterTab && useMasterTab)
                      ? 'visible'
                      : 'hidden',
                }}
              >
                •
              </div>
            </TabButton>
          </TabItem>
        ))}
      </TabNav>
    </Wrap>
  )
}

const Wrap = styled.div<{
  disablePadding: boolean
}>`
  height: 7rem;
  max-width: 100%;
  padding: ${({ disablePadding }) => (disablePadding ? 0 : '0 2rem')};

  ${MEDIA_QUERIES.desktopUp} {
    padding: ${({ disablePadding }) => (disablePadding ? 0 : '0 4rem')};
  }
`

const TabNav = styled.ul<{
  scrollOnMobile: boolean | undefined
}>`
  ${hideScrollBar};
  display: flex;
  height: 100%;
  width: 100%;

  ${({ scrollOnMobile }) =>
    scrollOnMobile &&
    css`
      overflow-x: scroll;
      white-space: nowrap;
    `}

  ${MEDIA_QUERIES.desktopUp} {
    overflow-x: scroll;
  }
`

const TabItem = styled.li<{
  isActive: boolean
}>`
  transition: 300ms opacity;
  opacity: ${(props) => (props.isActive ? 1 : 0.2)};
  height: 100%;

  & + & {
    margin-left: 3rem;
  }
`

const BaseButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TabButton = styled(BaseButton)<{
  fontStyle: string
}>`
  ${({ fontStyle }) => getFontStyles(fontStyle)}

  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  padding-bottom: 1.4rem;

  & > div {
    margin-top: -0.8rem;
  }
`
