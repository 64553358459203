import { Sanity } from '@cellulargoods/types'

import { VideoPlayer, VIDEO_MODES } from '../Video'

export type MediaMuxProps = Sanity.MediaMux & {
  floodParent?: boolean
  mode?: VIDEO_MODES
  showVolume?: boolean
}

export const MediaMux = ({
  muxVideoFile,
  showControls,
  loopEnabled,
  playsinline,
  autoplay,
  muted,
  floodParent,
  mode,
  showVolume,
}: MediaMuxProps) => {
  const videoState = {
    playing: Boolean(autoplay),
    autoPlay: Boolean(autoplay),
    playsInline: Boolean(playsinline),
    muted: Boolean(muted),
    loop: Boolean(loopEnabled),
    controls: Boolean(showControls),
  }

  if (!muxVideoFile?.asset?.playbackId) {
    console.warn('No playbackId passed to MediaMux')
    return null
  }

  const src = `https://stream.mux.com/${muxVideoFile.asset.playbackId}.m3u8`

  const poster = getPosterSrc(muxVideoFile.asset.playbackId, {
    time: muxVideoFile.asset.thumbTime || 1,
    fitMode: 'preserve',
  })

  return (
    <VideoPlayer
      src={src}
      poster={poster}
      floodParent={floodParent}
      isMux
      mode={mode}
      playbackId={muxVideoFile.asset.playbackId}
      showVolume={showVolume}
      {...videoState}
    />
  )
}

export function getPosterSrc(
  playbackId: string,
  options: {
    width?: number
    height?: number
    time?: number
    fitMode?: string
  } = {}
) {
  const width = options.width || 1920
  const height = options.height || ''
  const time = options.time || 1
  const fitMode =
    typeof options.fitMode === 'undefined' ? 'smartcrop' : options.fitMode
  let url = `https://image.mux.com/${playbackId}/thumbnail.png?width=${width}&fit_mode=${fitMode}&time=${time}`
  if (options.height) {
    url += `&height=${height}`
  }
  return url
}
