export enum HeaderTextSizes {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ReviewModuleModes {
  CATEGORY = 'category',
  COMPANY = 'company',
  PRODUCT = 'product',
}

export enum SlugType {
  LEARN_ARTICLE = 'learn article',
  LEARN_SINGLETON = 'learn singleton',
  FUNCTIONAL_PDP = 'functional pdp',
  FUNCTIONAL_PLP = 'functional plp',
  ENHANCED_PDP = 'enhanced pdp',
  ENHANCED_PLP = 'enhanced plp',
}

export enum Slugs {
  LEARN = 'learn',
  ARTICLES = `learn/articles`,
  PRODUCTS = 'products',
  LOGIN = 'login',
  LOGOUT = 'logout',
  SIGNUP = 'signup',
  ACCOUNT_ADDRESS = 'account/address-book',
  PRIVACY_COOKIES = 'privacy-and-cookies',
}

export enum KLAVIYO_LIST_IDS {
  newsletter = 'QTq4Km',
  soldoutNotification = 'Wgw3x9',
  interestedInUpcomingProduct = 'SuRfTH',
}

export const INVALID_EMAIL_ERROR = 'Invalid Email'
export const EMAIL_IS_NOT_UNIQUE = 'This email is already signed up'
export const SERVER_ERROR = 'There was an error submitting your email'

export const NEWSLETTER_SIGNUP_TEXT =
  'Yes, I would like to receive email updates from Cellular Goods on products, launches, surveys and events. Unsubscribe anytime. For more information, see our Privacy Policy.'
export const NEWSLETTER_SUCCESS_TEXT =
  'Thank you for subscribing. If you do not receive your welcome email, please check your spam/junk mail.'

export const ECOMMERCE_DOMAIN = 'https://www.cellular-goods.com/'

/* seconds */
export const REVALIDATE_TIME = 60

export const BANNER_DISMISSED_ID = 'banner_dismissed'
