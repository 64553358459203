/**
 * Takes CMS Components and spits them out.
 */
import { Sanity, PickType, NonNullSkipArray } from '@cellulargoods/types'

// Banners
import { BenefitsBanner, ShoppingDirectBanner } from '../Banners'

// Carousels
import {
  CarouselPortrait,
  CarouselArticles,
  CarouselReviews,
  CarouselInstagram,
  CarouselUGC,
} from '../Carousels'

// Content Modules
import {
  ContentCategoryAnnouncement,
  ContentFAQAccordion,
  ContentFullLeftRight,
  ContentFullBleed,
  ContentFunctionalImage,
  ContentHeaderText,
  ContentHeroImage,
  ContentLogos,
  ContentLogosWithQuote,
  ContentSpotlight,
  ContentStoryIntro,
  ContentRelatedLinks,
  ContentTicker,
  ContentTwoColumnGrid,
  ContentTwoImageAndText,
} from '../Content'

// Grids
import { TeamGrid, ArticlesGrid } from '../Grids'

// Highlighters
import { HighlighterWhitepaper, HighlighterArticles } from '../Highlighters'

import { ComparisonTable } from '../ComparisonTable'

import { ReviewsModule } from '../Reviews/Reviews'
import { ContentThreeLinkGrid } from '../Content/ContentThreeLinkGrid'

type CMSComponentTypes =
  | NonNullSkipArray<PickType<Sanity.Homepage, 'CMSComponents'>>
  | Sanity.OneTileProductPromo
  | null

/**
 * The Global Renderer for the main CMSComponents.
 *
 * Some CMSComponents will contain a MiniRenderer which will render the
 * specific components isolated to that module, e.g `TwoColumnGrid` has a
 * `MiniRenderer` to render each left and right column items.
 *
 * `product` is passed when using Functional PDP's. This is because the whole
 * page has a referenced product which is queried via GraphQL at the top level,
 * rather than referencing the product multiple times throughout each CMSComponent
 * and having duplicated data within the GraphQL query.
 *
 * So we have
 * ```
 * {
 *   product: obj // We pass down the product obj in the `Renderer`
 *   CMSComponents: ...[]
 * }
 * ```
 *
 * Instead of duplicated data:
 * ```
 * {
 *   CMSComponents: [
 *     {
 *       _type: 'Component_One'
 *       product: obj // Duplicated 🔴
 *     },
 *     {
 *       _type: 'Component_Two'
 *       product: obj // Duplicated 🔴
 *     },
 *   ]
 * }
 * ``
 */
export const SharedCMSRenderer = (
  CMSComponent: CMSComponentTypes,
  warn?: boolean
) => {
  if (!CMSComponent) return null

  // Note: All components explicitly need a `key` prop, spreading does not apply because
  // Sanity is using `_key`

  switch (CMSComponent._type) {
    case 'ArticleCarousel': {
      return <CarouselArticles key={CMSComponent._key} {...CMSComponent} />
    }
    case 'ArticlesGrid': {
      return (
        <ArticlesGrid
          key={CMSComponent._key}
          {...(CMSComponent as Sanity.MutatedSanityArticlesGrid)}
          isModule
        />
      )
    }
    case 'ArticleHighlighter': {
      return <HighlighterArticles key={CMSComponent._key} {...CMSComponent} />
    }
    case 'BenefitsBanner': {
      return <BenefitsBanner key={CMSComponent._key} {...CMSComponent} />
    }
    case 'CategoryAnnouncement': {
      return (
        <ContentCategoryAnnouncement
          key={CMSComponent._key}
          {...CMSComponent}
        />
      )
    }
    case 'ComparisonTable':
      return <ComparisonTable key={CMSComponent._key} {...CMSComponent} />
    case 'FaqAccordion': {
      return <ContentFAQAccordion key={CMSComponent._key} {...CMSComponent} />
    }
    case 'FullLeftRightComponent':
      return <ContentFullLeftRight key={CMSComponent._key} {...CMSComponent} />
    case 'FullBleedComponent':
      return <ContentFullBleed key={CMSComponent._key} {...CMSComponent} />
    case 'TeamGrid':
      return <TeamGrid key={CMSComponent._key} {...CMSComponent} />
    case 'FunctionalImage':
      return (
        <ContentFunctionalImage key={CMSComponent._key} {...CMSComponent} />
      )
    case 'HeaderText':
      return (
        <ContentHeaderText
          key={CMSComponent._key}
          {...CMSComponent}
          useSecondaryCtaStyle
        />
      )
    case 'HeroImage':
      return <ContentHeroImage key={CMSComponent._key} {...CMSComponent} />
    case 'InstagramCarousel':
    case 'SocialChannelsPromotion': {
      return (
        <CarouselInstagram
          key={CMSComponent._key}
          {...(CMSComponent as Sanity.Keyed<Sanity.InstagramCarouselWithYotpo>)}
        />
      )
    }
    case 'LogosBanner':
      return <ContentLogos key={CMSComponent._key} {...CMSComponent} />
    case 'LogosBannerLargeWithQuote': {
      return <ContentLogosWithQuote key={CMSComponent._key} {...CMSComponent} />
    }
    case 'PortraitCarousel': {
      return <CarouselPortrait key={CMSComponent._key} {...CMSComponent} />
    }
    case 'RelatedLinks': {
      return <ContentRelatedLinks key={CMSComponent._key} {...CMSComponent} />
    }
    case 'ReviewCarousel':
      return (
        <CarouselReviews
          key={CMSComponent._key}
          {...(CMSComponent as Sanity.Keyed<Sanity.ReviewCarouselWithYotpo>)}
        />
      )
    case 'ReviewsModule':
      return (
        <ReviewsModule
          key={CMSComponent._key}
          {...(CMSComponent as Sanity.Keyed<Sanity.ReviewsWithYotpo>)}
        />
      )
    case 'ShoppingDirectBanner': {
      return <ShoppingDirectBanner key={CMSComponent._rev} {...CMSComponent} />
    }
    case 'Spotlight': {
      return <ContentSpotlight key={CMSComponent._key} {...CMSComponent} />
    }
    case 'StoryIntro': {
      return <ContentStoryIntro key={CMSComponent._key} {...CMSComponent} />
    }
    case 'ThreeLinkGrid':
      return <ContentThreeLinkGrid key={CMSComponent._key} {...CMSComponent} />
    case 'Ticker': {
      return <ContentTicker key={CMSComponent._key} {...CMSComponent} />
    }
    case 'TwoColumnGrid': {
      return <ContentTwoColumnGrid key={CMSComponent._key} {...CMSComponent} />
    }
    case 'TwoImageAndText': {
      return (
        <ContentTwoImageAndText key={CMSComponent._key} {...CMSComponent} />
      )
    }
    case 'WhitepaperHighlighter':
      return <HighlighterWhitepaper key={CMSComponent._key} {...CMSComponent} />
    case 'UGCCarousel':
      return (
        <CarouselUGC
          key={CMSComponent._key}
          {...(CMSComponent as Sanity.Keyed<Sanity.UGCCarouselWithYotpo>)}
        />
      )
    default:
      if (warn)
        console.warn(
          `Missing Case for ${CMSComponent._type} in Renderer`,
          JSON.stringify(CMSComponent)
        )
      return null
  }
}
