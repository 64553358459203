import FocusLock from 'react-focus-lock'
import { X } from 'phosphor-react'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from '@react-spring/web'
import { useWindowResize } from '@cellulargoods/hooks'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { IconButton } from '../Button'

const SIDE_SHEET_WIDTH = 500

interface SideSheetProps {
  isActive: boolean
  children: ReactNode
  onCloseClick: () => void
  onOpenChange?: (isOpen: boolean) => void
}

export const SideSheet = ({
  isActive,
  children,
  onCloseClick,
  onOpenChange,
}: SideSheetProps) => {
  const { right, opacity } = useSpring({
    right: isActive ? '0%' : '-100%',
    opacity: isActive ? 1 : 0,
    onRest: () => {
      if (onOpenChange) {
        onOpenChange(isActive)
      }
    },
  })

  const { width, height } = useWindowResize()

  return (
    <Aside
      style={{
        width: `${width}px`,
        height: `${height}px`,
        pointerEvents: isActive ? 'all' : 'none',
      }}
      aria-hidden={!isActive}
    >
      <Scrim
        style={{
          opacity,
        }}
        onClick={onCloseClick}
      />
      <FocusLock disabled={!isActive}>
        <Wrap
          style={{
            right,
          }}
        >
          <CloseBtn ariaLabel="Close panel" onClick={onCloseClick}>
            <X size={32} weight="thin" />
          </CloseBtn>
          {children}
        </Wrap>
      </FocusLock>
    </Aside>
  )
}

const Aside = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
`

const Scrim = styled(animated.div)`
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
`

const Wrap = styled(animated.div)`
  position: absolute;
  top: 0;
  z-index: 1;
  padding: 0 2rem 2rem;
  height: 100%;
  overflow-y: auto;
  background: white;
  width: 100%;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem 3rem;
    width: ${SIDE_SHEET_WIDTH}px;
  }
`

const CloseBtn = styled(IconButton)`
  z-index: 1;
  position: absolute;
  right: 1.6rem;
  top: 2rem;
  padding: 0.4rem;

  ${MEDIA_QUERIES.desktopUp} {
    top: 2.8rem;
    right: 3.6rem;
  }
`
