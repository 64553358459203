import { useState } from 'react'
import { useCurrency } from '@cellulargoods/hooks'
import { CaretDown } from 'phosphor-react'
import styled from 'styled-components'
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler'

import {
  COLORS,
  FONT_STYLE_SOFIA_14_500,
  getFontStyles,
} from '@cellulargoods/styles'
export const DropdownCurrencySwitcher = ({ theme }: { theme: string }) => {
  const [currency, setCurrency, currencies] = useCurrency()
  const [showOptions, setShowOptions] = useState(false)
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowOptions(false)
      }}
    >
      <Wrapper>
        <Button
          isDark={theme === 'white'}
          show={showOptions}
          onClick={() => setShowOptions(!showOptions)}
        >
          <span>{currency}</span> <CaretDown height={13} width={13} />
        </Button>
        <Options show={showOptions}>
          <ul>
            {currencies
              .filter((i) => i !== currency)
              .map((c: string) => (
                <Option
                  key={c}
                  onClick={() => {
                    setCurrency(c as 'GBP' | 'USD' | 'EUR')
                    setShowOptions(false)
                  }}
                >
                  {c}
                </Option>
              ))}
          </ul>
        </Options>
      </Wrapper>
    </OutsideClickHandler>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:hover > button {
    border: solid 1px black;
  }
`

const Button = styled.button<{ show?: boolean; isDark: boolean }>`
  appearance: none;
  background: transparent;
  border: none;
  padding: 4px 6px;
  display: flex;
  gap: 6px;
  ${(props) => (props.isDark ? 'color: var(--white);' : 'color: var(--black);')}
  align-items: center;
  border: solid 1px transparent;
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
  ${({ show }) => (show ? 'border: solid 1px black;' : null)}
`

const Options = styled.div<{ show?: boolean }>`
  position: absolute;
  width: 100%;
  top: calc(100% - 2px);
  margin-top: 1px;
  background-color: ${COLORS.white};
  border: solid 1px black;
  ${({ show }) => (show ? 'display: block;' : 'display: none;')}
`

const Option = styled.li`
  padding: 4px 6px;
  color: #757575;
  cursor: pointer;
  &:hover {
    color: black;
  }
  ${getFontStyles(FONT_STYLE_SOFIA_14_500)}
`
