import { ReviewModuleModes } from '@cellulargoods/core'

import { PickType, NonNullSkipArray, Maybe } from './utils'

import {
  CategoryReviews,
  PhotoAlbum,
  ProdBottomLine,
  ProductReviews,
  SiteReviews,
} from './yotpo'

export interface SanityDocument {
  _id?: string
  _createdAt?: string
  _rev?: string
  _updatedAt?: string
  _type?: string
}

export type Keyed<T> = T extends object
  ? T & {
      _key: string
    }
  : T

export interface Block {
  _type: 'block'
  [key: string]: any
}

export interface SanityImageAsset {
  _type?: string
  _key?: string | number
  url?: string
  altText?: string
  metadata?: {
    dimensions?: {
      width?: number
      height?: number
    }
  }
}

export interface BaseAccountPageSettings extends SanityDocument {
  _type: 'baseAccountPageSettings'

  loginBackground?: Media

  resetBackground?: Media

  signupBackground?: Media

  activateBackground?: Media
}

export interface Cart extends SanityDocument {
  _type: 'cart'

  copy?: string
  gwpMessage?: { title?: string; description?: string }
  subtext?: string

  RelatedProducts?: RelatedProducts
}

/**
 * Shopify Product
 *
 *
 */
export interface ShopifyProduct extends SanityDocument {
  _type: 'shopifyProduct'

  /**
   * Status — `string`
   *
   * Product status in shopify
   */
  status?: 'active' | 'archived' | 'draft'

  /**
   * Title — `string`
   *
   * Title displayed in both cart and checkout
   */
  title?: string

  /**
   * id — `number`
   *
   *
   */
  id?: number

  /**
   * ID (Base64 encoded) — `string`
   *
   *
   */
  idEncoded?: string

  /**
   * Price — `number`
   *
   *
   */
  price?: number

  /**
   * Compare price — `number`
   *
   *
   */
  compareAtPrice?: number

  /**
   * Product type — `string`
   *
   *
   */
  productType?: string

  /**
   * Preview Image URL — `string`
   *
   * Image displayed in both cart and checkout
   */
  previewImageUrl?: string

  /**
   * Variants — `array`
   *
   *
   */
  variants?: Array<Keyed<ShopifyProductVariant>>

  /**
   * Options — `array`
   *
   *
   */
  options?: Array<Keyed<ShopifyProductOption>>
}

/**
 * Enhanced Product Display Page
 *
 *
 */
export interface EnhancedProductDisplayPage extends SanityDocument {
  _type: 'enhancedProductDisplayPage'

  /**
   * Product — `reference`
   *
   *
   */
  product?: Partial<Product>
  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Faq
 *
 *
 */
export interface Faq extends SanityDocument {
  _type: 'faq'

  /**
   * Question — `string`
   *
   *
   */
  question?: string

  /**
   * Answer — `array`
   *
   *
   */
  answer?: Array<Keyed<Block>>

  /**
   * Category — `reference`
   *
   *
   */
  category?: FaqCategory
}

/**
 * Faq Category
 *
 *
 */
export interface FaqCategory extends SanityDocument {
  _type: 'faqCategory'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * slug — `slug`
   *
   *
   */
  slug?: Slug

  questions?: Keyed<Faq>[]
}

/**
 * Functional Product Display Page
 *
 *
 */
export interface FunctionalProductDisplayPage extends SanityDocument {
  _type: 'functionalProductDisplayPage'

  /**
   * Product — `reference`
   *
   *
   */
  product?: ProductWithYotpo

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * One Tile Product Promo — `OneTileProductPromo`
   *
   *
   */
  OneTileProductPromo?: OneTileProductPromo

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Internal Articles
 *
 *
 */
export interface InternalArticle extends SanityDocument {
  _type: 'internalArticle'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Date — `date`
   *
   *
   */
  date?: string

  /**
   * Enable Sharing — `boolean`
   *
   *
   */
  enableSharing?: boolean

  /**
   * Hide Components — `boolean`
   *
   *
   */
  hideComponents?: boolean

  /**
   * Article Card Info — `ArticleCardInfo`
   *
   *
   */
  ArticleCardInfo?: ArticleCardInfo

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Tags — `Tags`
   *
   *
   */
  Tags?: Tags

  /**
   * Author - `TeamMember`
   *
   *
   */
  author?: TeamMember

  /**
   * Standfirst — `text`
   *
   *
   */
  standfirst?: string

  foreword?: Array<Keyed<Block> | Keyed<CTAButton>>

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<InternalArticleImage>
    | Keyed<InternalArticleQuote>
    | Keyed<InternalArticleRichText>
    | Keyed<InternalArticleContents>
  >

  /**
   * Show Article Feedback Form — `boolean`
   *
   *
   */
  showArticleFeedback?: boolean

  /**
   * Related Links - `RelatedLinks`
   *
   *
   */
  RelatedLinks?: RelatedLinks
}

/**
 * External Articles
 *
 *
 */
export interface ExternalArticle extends SanityDocument {
  _type: 'externalArticle'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Date — `date`
   *
   *
   */
  date?: string

  /**
   * Article Card Info — `ArticleCardInfo`
   *
   *
   */
  ArticleCardInfo?: ArticleCardInfo

  /**
   * Link to external article — `string`
   *
   *
   */
  link?: string

  /**
   * Upload an external file — `file`
   *
   *
   */
  file?: File

  /**
   * Tags — `Tags`
   *
   *
   */
  Tags?: Tags
}

/**
 * Product
 *
 *
 */
export interface Product extends SanityDocument {
  _type: 'product'
  ukOnly?: boolean
  productType?: string[]

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Status — `string`
   *
   *
   */
  status?: 'interest' | 'preorder' | 'active'

  /**
   * Hide price — `boolean`
   *
   *
   */
  hidePrice?: boolean

  /**
   * Is New - `boolean`
   *
   *
   */
  isNew?: boolean

  /**
   * customTag - `string`
   *
   *
   */
  customTag?: string
  customTagUs?: string
  customTagEu?: string

  /**
   * Has compare price - `object`
   *
   *
   */
  hasComparePrice?: {
    showComparePrice?: boolean
    message?: string
    messageUs?: string
    messageEu?: string
  }

  /**
   * Preorder Text — `text`
   *
   * Only visible when the status of this product is "Preorder"
   */
  preorderText?: string

  /**
   * Description — `text`
   *
   *
   */
  description?: string

  /**
   * Image — `MediaImage`
   *
   *
   */
  image?: MediaImage

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Category — `reference`
   *
   * E.g Skincare
   */
  category?: ProductCategory

  /**
   * Product — `reference`
   *
   *
   */
  product?: ShopifyProduct

  /**
   * Soldout Text — `string`
   *
   * Used in the notification modal when the product is sold out
   */
  soldoutText?: string

  /**
   * Interest Text — `string`
   *
   * Used in the notification modal when the product is either in interest or preorder mode
   */
  interestText?: string

  /**
   * Show Reviews - `boolean`
   *
   */
  showReviews?: boolean

  /**
   * Strength of Product – `ProductStrength`
   */
  strength?: ProductStrength
}

export interface Filter {
  /**
   * filterName - `string`
   *
   *
   */
  filterName: string
  filterValue: string

  /**
   * isFilterHidden - `boolean`
   *
   *
   */
  isFilterHidden?: boolean
}

export interface SelectionFilter {
  /**
   * categoryName - `string`
   *
   *
   */
  categoryName?: string

  /**
   * multiSelect - `boolean`
   *
   *
   */
  multiSelect?: boolean

  /**
   * filters - `array`
   *
   *
   */
  filters?: Array<Keyed<Filter>>
}
export interface SelectionFiltersArray extends SanityDocument {
  _type: `selectionFiltersArray`

  /**
   * isActive - `boolean`
   *
   *
   */
  isActive?: boolean

  /**
   * filterCategories - `array`
   *
   *
   */
  filterCategories?: Array<Keyed<SelectionFilter>>
}
export interface ProductStrength {
  show: boolean
  value: 'Low' | 'Medium' | 'High'
}

/**
 * Product Collection
 *
 *
 */
export interface ProductCollection extends SanityDocument {
  _type: 'productCollection'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Selected Products — `array`
   *
   *
   */
  selected_products?: Array<Keyed<Product>>
}

/**
 * Product Categories
 *
 *
 */
export interface ProductCategory extends SanityDocument {
  _type: 'productCategory'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * QR Page
 *
 *
 */
export interface QrPage extends SanityDocument {
  _type: 'qrPage'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Tag
 *
 *
 */
export interface Tag extends SanityDocument {
  _type: 'tag'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug
}

/**
 * Utility Page
 *
 *
 */
export interface UtilityPage extends SanityDocument {
  _type: 'utilityPage'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Content — `UtilityContent`
   *
   *
   */
  UtilityContent?: UtilityContent
}

/**
 * About
 *
 *
 */
export interface About extends SanityDocument {
  _type: 'about'

  /**
   * Title — `string`
   *
   * Used when referencing the About page
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Footer
 *
 *
 */
export interface Footer extends SanityDocument {
  _type: 'footer'

  /**
   * Sections — `array`
   *
   *
   */
  sections?: Array<Keyed<FooterList> | Keyed<FooterNewsletter>>
}

/**
 * Help
 *
 *
 */
export interface Help extends SanityDocument {
  _type: 'help'

  /**
   * Title — `string`
   *
   * for referencing only
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Tab Bar Items — `array`
   *
   * FAQ Categories listed in the Tab Bar for help navigation.
   */
  tabBarItems?: Array<Keyed<FaqCategory>>

  /**
   * Default FAQ Category — `reference`
   *
   * The default FAQ category that /help will redirect to.
   */
  defaultFaqCategory?: FaqCategory

  /**
   * Enable chat — `boolean`
   *
   *
   */
  chat?: boolean

  /**
   * Enable email — `boolean`
   *
   *
   */
  email?: boolean

  /**
   * Enable returns — `boolean`
   *
   *
   */
  returns?: boolean

  /**
   * Top Questions — `array`
   *
   *
   */
  top_questions?: Array<Keyed<Faq>>

  categoryMeta?: PageMeta
}

/**
 * Homepage
 *
 *
 */
export interface Homepage extends SanityDocument {
  _type: 'homepage'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Investors
 *
 *
 */
export interface Investors extends SanityDocument {
  _type: 'investors'

  /**
   * Title — `string`
   *
   * for referencing only
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Overview — `array`
   *
   *
   */
  overview?: Array<Keyed<Block>>

  /**
   * Company Advisors — `array`
   *
   *
   */
  advisors?: Array<Keyed<Block>>

  /**
   * Significant Shareholders — `array`
   *
   *
   */
  shareholders?: Array<Keyed<SignificantShareholder>>

  /**
   * Significant Documents — `array`
   *
   *
   */
  shareholder_documents?: Array<Keyed<ShareholderDocument>>

  /**
   * Corporate Governance — `array`
   *
   *
   */
  governance?: Array<Keyed<Block>>

  /**
   * Media & Investor Contacts — `array`
   *
   *
   */
  contacts?: Array<Keyed<Block>>

  /**
   * AGM Q&A - `array`
   *
   *
   */
  agm_qa?: Array<Keyed<Faq>>

  availableSections?: Record<string, boolean | undefined>

  tabMeta?: PageMeta
}

/**
 * Interstitial
 *
 *
 */
export interface Interstitial extends SanityDocument {
  _type: 'interstitial'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Learn Cannabinoids
 *
 *
 */
export interface LearnPage extends SanityDocument {
  _type: 'learnPage'

  /**
   * Title — `string`
   *
   * Used when referencing the page
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >

  /**
   * Show Article Feedback Form — `boolean`
   *
   *
   */
  showArticleFeedback?: boolean
}

/**
 * All Articles
 *
 *
 */
export interface LearnAll extends SanityDocument {
  _type: 'learnAll'

  /**
   * Title — `string`
   *
   * Used when referencing the page
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Articles Grid — `ArticlesGrid`
   *
   *
   */
  ArticlesGrid?: ArticlesGrid
}

/**
 * Learn Homepage
 *
 *
 */
export interface LearnHome extends SanityDocument {
  _type: 'learnHome'

  /**
   * Title — `string`
   *
   * Used when referencing the Overview page
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Navigation
 *
 *
 */
export interface Navigation extends SanityDocument {
  _type: 'navigation'

  /**
   * Nav Bar Links — `array`
   *
   *
   */
  navBarLinks?: Array<Keyed<NavbarLinkItem>>

  /**
   * Mobile Sub Nav — `array`
   *
   *
   */
  mobileSubNav?: Array<Keyed<FooterListItem>>
}

/**
 * Newsletter
 *
 *
 */
export interface Newsletter extends SanityDocument {
  _type: 'newsletter'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Show as Popover — `boolean`
   *
   *
   */
  showPopover?: boolean

  /**
   * Heading — `string`
   *
   *
   */
  heading?: string

  /**
   * Copy — `text`
   *
   *
   */
  copy?: string

  /**
   * Media — `media`
   *
   *
   */
  media?: Media

  /**
   * Intersted In Products for Options - `string[]`
   */
  interestedIn?: string[]

  /**
   * Timing after the newsletter (pop-up) appears - number
   */
  modalShowingDuration?: number
}

export interface AskCel extends SanityDocument {
  _type: 'askCel'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Heading — `string`
   *
   *
   */
  heading?: string

  /**
   * Copy — `text`
   *
   *
   */
  copy?: string

  /**
   * Copy — `text`
   *
   *
   */
  cta?: string

  /**
   * Media — `media`
   *
   *
   */
  media?: Media

  /**
   * Timing after the newsletter (pop-up) appears - number
   */
  modalShowingDuration?: number
}

/**
 * Not Found (404)
 *
 *
 */
export interface NotFound extends SanityDocument {
  _type: 'notFound'

  /**
   * Related Products — `RelatedProducts`
   *
   *
   */
  RelatedProducts?: RelatedProducts
}

/**
 * Reviews
 *
 *
 */
export interface Reviews extends SanityDocument {
  _type: 'reviews'

  /**
   * Title — `string`
   *
   * Used when referencing the page
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >
}

/**
 * Search
 *
 *
 */
export interface SearchPage extends SanityDocument {
  _type: 'searchPage'

  /**
   * Related Products — `RelatedProducts`
   *
   *
   */
  RelatedProducts?: RelatedProducts
}

/**
 * Shopping Direct Banner
 *
 *
 */
export interface ShoppingDirectBanner extends SanityDocument {
  _type: 'ShoppingDirectBanner'

  /**
   * Items — `array`
   *
   *
   */
  shoppingDirectBannerItems?: Array<Keyed<string>>
}

export interface AdditionalLink {
  _type: Documents['_type']
  slug?: string
  title?: string
  tag?: string
  category?: string
}

export interface HelpButton {
  chatEnabled?: boolean
  emailEnabled?: boolean
  searchEnabled?: boolean
  additionalLinks?: Array<Keyed<AdditionalLink>>
}

/**
 * Site Settings
 *
 *
 */
export interface SiteSettings extends SanityDocument {
  _type: 'SiteSettings'

  /**
   * Site Banner — `SiteBanner`
   *
   *
   */
  fdaDisclaimer?: string
  usdExchangeRate?: number
  eurExchangeRate?: number
  ukOnlyMessage?: string
  SiteBanner?: SiteBanner

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  helpButton?: HelpButton
}

/**
 * socials
 *
 *
 */
export interface Socials extends SanityDocument {
  _type: 'socials'

  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Url — `url`
   *
   *
   */
  url?: string
}

/**
 * teamPage
 *
 *
 */
export interface TeamPage extends SanityDocument {
  _type: 'teamPage'

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Slug — `slug`
   *
   *
   */
  slug?: Slug

  /**
   * Components — `array`
   *
   *
   */
  CMSComponents?: Array<
    | Keyed<ArticleCarousel>
    | Keyed<ArticlesGrid>
    | Keyed<ArticleHighlighter>
    | Keyed<BenefitsBanner>
    | Keyed<CategoryAnnouncement>
    | Keyed<FaqAccordion>
    | Keyed<FullBleedComponent>
    | Keyed<FullLeftRightComponent>
    | Keyed<FunctionalImage>
    | Keyed<HeroImage>
    | Keyed<HeaderText>
    | Keyed<InstagramCarousel>
    | Keyed<LogosBanner>
    | Keyed<LogosBannerLargeWithQuote>
    | Keyed<ProductCarousel>
    | Keyed<QuoteCarousel>
    | Keyed<RelatedLinks>
    | Keyed<RelatedProducts>
    | Keyed<ShoppingDirectBanner>
    | Keyed<SocialChannelsPromotion>
    | Keyed<Spotlight>
    | Keyed<StoryIntro>
    | Keyed<PortraitCarousel>
    | Keyed<ReviewCarousel>
    | Keyed<ReviewsModule>
    | Keyed<ComparisonTable>
    | Keyed<TeamGrid>
    | Keyed<ThreeLinkGrid>
    | Keyed<Ticker>
    | Keyed<TwoColumnGrid>
    | Keyed<TwoImageAndText>
    | Keyed<UGCCarousel>
    | Keyed<WhitepaperHighlighter>
  >

  /**
   * Meta — `PageMeta`
   *
   *
   */
  PageMeta?: PageMeta

  /**
   * Settings — `PageSettings`
   *
   *
   */
  PageSettings?: PageSettings
}

/**
 * teamMember
 *
 *
 */
export interface TeamMember extends SanityDocument {
  _type: 'teamMember'

  /**
   * Name — `string`
   *
   *
   */
  name?: string

  /**
   * Job Title — `string`
   *
   *
   */
  jobTitle?: string

  /**
   * Image — `MediaImage`
   *
   *
   */
  image?: MediaImage

  /**
   * Anchor ID — `string`
   *
   * Anchor ID used to scroll to the team member on a different page. e.g. `#anchor`
   */
  anchor?: string
  /**
   * text — `text`
   *
   * Bio text used for the sidesheet bio.
   */
  text?: Array<Keyed<Block>>

  /**
   * Related Link Href — `url`
   *
   *
   */
  relatedLinkHref?: string
}

/**
 * Utility Navigation
 *
 *
 */
export interface UtilityNavigation extends SanityDocument {
  _type: 'utilityNavigation'

  /**
   * Utility Navigation Link — `array`
   *
   *
   */
  UtilityNavigationLink?: Array<Keyed<UtilityNavigationItem>>
}

export type Media = Array<
  Keyed<MediaImage> | Keyed<MediaYoutube> | Keyed<MediaMux>
>

export type ShopifyProductVariant = {
  _type: 'shopifyProductVariant'
  /**
   * In stock — `boolean`
   *
   *
   */
  inStock?: boolean

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Price — `number`
   *
   *
   */
  price?: number

  /**
   * Compare price — `number`
   *
   *
   */
  compareAtPrice?: number

  /**
   * Quantity Available — `number`
   *
   *
   */
  quantityAvailable?: number

  /**
   * ID — `number`
   *
   *
   */
  id?: number

  /**
   * SKU — `string`
   *
   *
   */
  sku?: string

  /**
   * Option 1 — `string`
   *
   *
   */
  option1?: string

  /**
   * Option 2 — `string`
   *
   *
   */
  option2?: string

  /**
   * Option 3 — `string`
   *
   *
   */
  option3?: string
}

export type ShopifyProductOption = {
  _type: 'shopifyProductOption'
  /**
   * Name — `string`
   *
   *
   */
  name?: string

  /**
   * Values — `array`
   *
   *
   */
  values?: Array<Keyed<string>>

  /**
   * Position — `number`
   *
   *
   */
  position?: number
}

export type ArticleCarousel = {
  _type: 'ArticleCarousel'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Background — `boolean`
   *
   * Giving the article carousel a background will also add internal padding
   */
  background?: boolean

  /**
   * Articles — `array`
   *
   *
   */
  articles?: Array<Keyed<ArticleTile>>
}

export type ArticleCardInfo = {
  _type: 'ArticleCardInfo'
  /**
   * Excerpt — `text`
   *
   *
   */
  excerpt?: string

  /**
   * Media — `media`
   *
   *
   */
  media?: Media
}

export type ArticleTile = {
  _type: 'ArticleTile'
  /**
   * Article Reference Link — `reference`
   *
   *
   */
  reference?: InternalArticle | ExternalArticle
}

export type ArticleHighlighter = {
  _type: 'ArticleHighlighter'
  /**
   * Articles — `array`
   *
   *
   */
  articles?: Array<Keyed<ArticleTile>>
}

export type BenefitsBanner = {
  _type: 'BenefitsBanner'
  /**
   * Has Background — `boolean`
   *
   *
   */
  hasBackground?: boolean

  /**
   * Items — `array`
   *
   *
   */
  benefitsBannerItems?: Array<Keyed<BenefitsBannerItem>>
}

export type BenefitsBannerItem = {
  _type: 'BenefitsBannerItem'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * Icon — `MediaSvg`
   *
   *
   */
  icon?: MediaSvg
}

export type FaqAccordion = {
  _type: 'FaqAccordion'
  /**
   * Header Text — `HeaderText`
   *
   *
   */
  HeaderText?: HeaderText

  /**
   * items — `array`
   *
   *
   */
  items?: Array<Keyed<FaqAccordionItem>>
}

export type FaqAccordionItem = {
  _type?: 'FaqAccordionItem'
  /**
   * Reference an FAQ — `reference`
   *
   * Refrence an FAQ document and the site will use that instead of the fields below
   */
  faq_reference?: Faq

  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string
}

export type NavbarLinkItem = {
  _type: 'NavbarLinkItem'
  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Menu Items — `array`
   *
   *
   */
  menuItems?: Array<Keyed<NavigationSectionMenuList>>

  /**
   * Page Reference — `reference`
   *
   * Link to a specific page instead of opening the side menu.
   */
  reference?: About
}

export type NavigationSectionMenuList = {
  _type: 'NavigationSectionMenuList'
  /**
   * Title Link — `MenuListTitleLink`
   *
   *
   */
  titleLink?: MenuListTitleLink

  /**
   * Coming Soon — `boolean`
   *
   * If the product is coming soon, hide the items and show a Coming Soon label.
   */
  isComingSoon?: boolean

  /**
   * Product List — `array`
   *
   * Show a list of products
   */
  productList?: Array<Keyed<Product>>

  /**
   * Learn Pages — `array`
   *
   * Show a list of Learn pages
   */
  learnPageList?: Array<Keyed<LearnPage | LearnHome>>
}

export type MenuListTitleLink = {
  _type: 'MenuListTitleLink'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Link — `reference`
   *
   *
   */
  link?: LearnAll | ProductCategory
}

export type OneTileProductPromo = {
  _id: 'string'
  _type: 'OneTileProductPromo'
  /**
   * Slider Images — `array`
   *
   *
   */
  sliderImages?: Array<Keyed<MediaImage> | Keyed<MediaMux>>

  /**
   * tabs — `array`
   *
   *
   */
  tabs?: Array<Keyed<OneTileProductPromoTabItem>>

  hasReviewsModule?: boolean
}

export type OneTileProductPromoTabItem = {
  _type: 'OneTileProductPromoTabItem'
  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Text — `array`
   *
   *
   */
  text?: Array<Keyed<Block>>
}

export type PortraitCarousel = {
  _type: 'PortraitCarousel'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<PortraitCarouselItem>>
}

export type PortraitCarouselItem = {
  _type: 'PortraitCarouselItem'
  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Image — `MediaImage`
   *
   *
   */
  image?: MediaImage
}

export type ArticlesGrid = {
  _type: 'ArticlesGrid'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string
}

export type ArticleIntro = {
  _type: 'ArticleIntro'
  /**
   * Subtitle — `string`
   *
   *
   */
  subtitle?: string

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Intro — `text`
   *
   *
   */
  intro?: string

  /**
   * Share is Active — `boolean`
   *
   * Show buttons to share to twitter, facebook and to copy the posts link.
   */
  shareIsActive?: boolean
}

export type BulletList = {
  _type: 'BulletList'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * List — `array`
   *
   *
   */
  items?: Array<Keyed<BenefitsBannerItem>>
}

export type CategoryAnnouncement = {
  _type: 'CategoryAnnouncement'
  /**
   * Ticker text — `string`
   *
   *
   */
  pillText?: string

  /**
   * Media — `media`
   *
   *
   */
  media?: Media

  /**
   * Announcement Text — `string`
   *
   *
   */
  announcement?: string

  /**
   * Product Category — `reference`
   *
   *
   */
  category?: ProductCategory

  /**
   * CTA label — `string`
   *
   *
   */
  ctaLabel?: string
}

export type ComparisonTable = {
  _type: 'ComparisonTable'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * Comparison Table — `table`
   *
   * If you want to use ticks and crosses, use true and false respectively
   */
  comparisonTable?: Table

  /**
   * Additional Text — `text`
   *
   * This will appear at the bottom of the screen
   */
  additionText?: string
}

export type CTAButton = {
  _type: 'CTAButton'
  /**
   * Link is external — `boolean`
   *
   *
   */
  isExternal?: boolean

  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Url — `string`
   *
   *
   */
  url?: string
}

export type FeatureCard = {
  _type: 'FeatureCard'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Image — `media`
   *
   *
   */
  image?: Media

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton

  /**
   * Text — `text`
   *
   *
   */
  text?: string
}

export type FullLeftRightComponent = {
  _type: 'FullLeftRightComponent'
  /**
   * Module has background — `boolean`
   *
   *
   */
  hasBackground?: boolean

  /**
   * Module has a margin around the image (if using an image) — `boolean`
   *
   *
   */
  hasMargins?: boolean

  /**
   * Left Column — `array`
   *
   *
   */
  leftCol?: Array<
    | Keyed<BulletList>
    | Keyed<MediaImage>
    | Keyed<HeaderText>
    | Keyed<FeatureCard>
    | Keyed<MediaMux>
  >

  /**
   * Right Column — `array`
   *
   *
   */
  rightCol?: Array<
    | Keyed<BulletList>
    | Keyed<MediaImage>
    | Keyed<HeaderText>
    | Keyed<FeatureCard>
    | Keyed<MediaMux>
  >
}

export type FunctionalImage = {
  _type: 'FunctionalImage'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton

  /**
   * Media Asset — `media`
   *
   *
   */
  media?: Media
}

export type FooterList = {
  _type: 'FooterList'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<FooterListItem>>
}

export type FooterListItem = {
  _type: 'FooterListItem'
  /**
   * Label — `string`
   *
   * Label for the list item. Note: If Social Link is provided, this is not required.
   */
  label?: string

  /**
   * Reference — `reference`
   *
   *
   */
  reference?:
    | InternalArticle
    | ExternalArticle
    | UtilityPage
    | About
    | Investors
    | Help
    | TeamPage
    | Reviews

  /**
   * Social Link — `reference`
   *
   *
   */
  socialLink?: Socials

  /**
   * Custom Url — `string`
   *
   * Can't find the page you're looking for? Use a custom URL instead.
   */
  link?: string
}

export type FooterNewsletter = {
  _type: 'FooterNewsletter'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string
}

export type FullBleedComponent = {
  _type: 'FullBleedComponent'
  /**
   * Position Overlay — `string`
   *
   *
   */
  position?: 'bottom' | 'center' | 'top'

  /**
   * Is Full Bleed — `boolean`
   *
   *
   */
  isFullBleed?: boolean

  /**
   * Heading Overlay — `HeaderText`
   *
   *
   */
  headingText?: HeaderText

  /**
   * Media Asset — `media`
   *
   *
   */
  media?: Media
}

export type GenericCard = {
  _type: 'GenericCard'
  /**
   * Product Or Post Reference — `reference`
   *
   * Associate a Product or an Article and use it's title and image
   */
  reference?:
    | InternalArticle
    | ExternalArticle
    | FunctionalProductDisplayPage
    | EnhancedProductDisplayPage
    | ProductCategory
    | LearnHome
    | LearnAll
    | LearnPage
    | UtilityPage
    | About
    | Help
    | Homepage
    | Investors
    | TeamPage
    | Reviews

  /**
   * Image — `media`
   *
   * Choose a custom image for this card.
   */
  image?: Media

  /**
   * Title — `string`
   *
   * The title used if no reference is selected.
   */
  title?: string

  /**
   * Text — `array`
   *
   *
   */
  text?: Array<Keyed<Block>>

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton
}

export type HeaderText = {
  _type: 'HeaderText'
  /**
   * Text size — `string`
   *
   *
   */
  size?: 'small' | 'medium' | 'large'

  /**
   * Center text on mobile — `boolean`
   *
   *
   */
  centerOnMobile?: boolean

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Subtitle — `text`
   *
   *
   */
  subtitle?: string

  /**
   * Body — `text`
   *
   *
   */
  body?: string

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton
}

export type ImageSlider = {
  _type: 'ImageSlider'
  /**
   * Left Image — `MediaImage`
   *
   *
   */
  left_image?: MediaImage

  /**
   * Right Image — `MediaImage`
   *
   *
   */
  right_image?: MediaImage

  /**
   * slider_start_point — `number`
   *
   * Set where you want the slider bar to start from 0-100
   */
  slider_start_point?: number
}

export type SocialChannelsPromotion = {
  _type: 'SocialChannelsPromotion'
  /**
   * Heading – string
   */
  title?: string
  /**
   * Socials – `array`
   */
  socialLinks?: Array<Keyed<Socials>>
  /**
   *  Gallery Name — `string`
   *
   * Enter a  gallery name that you want to display, this is case sensitive!
   */
  albumName?: string
}

export type InstagramCarousel = {
  _type: 'InstagramCarousel'
  /**
   *  Gallery Name — `string`
   *
   * Enter a  gallery name that you want to display, this is case sensitive!
   */
  albumName?: string
  /**
   * Heading – string
   */
  title?: string
  /**
   * CTAButton – `CTAButton`
   */
  CTAButton?: CTAButton
}

export type InternalArticleImage = {
  _type: 'InternalArticleImage'
  /**
   * Media — `array`
   *
   *
   */
  articleMedias?: Array<Keyed<ArticleMedia>>
}

export type ArticleMedia = {
  _type: 'articleMedia'
  /**
   * Media Asset — `media`
   *
   *
   */
  articleMediaAsset?: Media

  /**
   * Asset should be full width — `boolean`
   *
   *
   */
  fullWidth?: boolean

  /**
   * Media Figure — `string`
   *
   * This will only be used if the asset is an image
   */
  mediaFigure?: string
}

export type InternalArticleQuote = {
  _type: 'InternalArticleQuote'
  /**
   * Quote — `text`
   *
   *
   */
  quote?: string

  /**
   * Author — `string`
   *
   *
   */
  author?: string
}

export type InternalArticleRichText = {
  _type: 'InternalArticleRichText'
  /**
   * Richtext — `array`
   *
   *
   */
  richtext?: Array<Keyed<Block> | Keyed<CTAButton>>
}

export type InternalArticleContents = {
  _type: 'InternalArticleContents'
  contentsList: string[]
}

export type MediaGridItem = {
  _type: 'MediaGridItem'
  /**
   * Asset — `media`
   *
   *
   */
  asset?: Media
}

export type LogoItem = {
  _type: 'LogoItem'
  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Image — `MediaImage`
   *
   *
   */
  image?: MediaImage

  type?: 'url' | 'asset'

  /**
   * Url — `string`
   *
   *
   */
  url?: string

  asset?: string
}

export type LogoItemWithQuote = {
  _type: 'LogoItemWithQuote'
  /**
   * Asset — `MediaImage`
   *
   *
   */
  asset?: MediaImage

  /**
   * Quote — `Quote`
   *
   *
   */
  quote?: Quote
}

export type LogosBanner = {
  _type: 'LogosBanner'
  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<LogoItem>>

  title?: string

  hasBackground?: boolean
}

export type LogosBannerLargeWithQuote = {
  _type: 'LogosBannerLargeWithQuote'
  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<LogoItemWithQuote>>
}

export type MediaImage = {
  _type: 'MediaImage'
  asset: SanityImageAsset
}

export type MediaMux = {
  _type: 'MediaMux'
  /**
   * Mux Video File — `mux.video`
   *
   *
   */
  muxVideoFile?: MuxVideo

  /**
   * Show Video Controls — `boolean`
   *
   *
   */
  showControls?: boolean

  /**
   * Loop Video — `boolean`
   *
   *
   */
  loopEnabled?: boolean

  /**
   * Play Inline — `boolean`
   *
   *
   */
  playsinline?: boolean

  /**
   * Autoplay — `boolean`
   *
   *
   */
  autoplay?: boolean

  /**
   * Muted — `boolean`
   *
   *
   */
  muted?: boolean
}

export type MediaSvg = {
  _type: 'MediaSvg'
  asset: SanityImageAsset
}

export type MediaYoutube = {
  _type: 'MediaYoutube'
  /**
   * Embed Url — `string`
   *
   *
   */
  url?: string

  /**
   * Show Video Controls — `boolean`
   *
   *
   */
  showControls?: boolean

  /**
   * Loop Video — `boolean`
   *
   *
   */
  loopEnabled?: boolean

  /**
   * Play Inline — `boolean`
   *
   *
   */
  playsinline?: boolean

  /**
   * Autoplay — `boolean`
   *
   *
   */
  autoplay?: boolean
}

export type PageMeta = {
  _type: 'PageMeta'
  /**
   * Title — `string`
   *
   * Title displayed in browsers and search engines
   */
  title?: string

  /**
   * Description — `text`
   *
   * Displayed in search engine results and social cards
   */
  description?: string

  /**
   * Image — `image`
   *
   * Displayed in search engine results and social cards
   */
  image?: {
    _type: 'image'
    asset: SanityImageAsset
  }
}

export type PageSettings = {
  _type: 'PageSettings'
  /**
   * Transparent Header? — `boolean`
   *
   *
   */
  transparentHeader?: boolean

  /**
   * Header text color — `string`
   *
   *
   */
  theme?: 'black' | 'white'
}

export type ProductCarousel = {
  _type: 'ProductCarousel'
  /**
   * Products — `array`
   *
   *
   */
  products?: Array<Keyed<Product>>
}

export type HeroImage = {
  _type: 'HeroImage'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Title width - `number`
   *
   *
   */
  title_width?: number

  /**
   * Subtitle — `string`
   *
   *
   */
  subtitle?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton

  /**
   * Desktop Media — `media`
   *
   *
   */
  desktop_media?: Media

  /**
   * Mobile Media — `media`
   *
   *
   */
  mobile_media?: Media
}

export type Quote = {
  _type: 'Quote'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Quote — `text`
   *
   *
   */
  quote?: string

  /**
   * Author — `string`
   *
   *
   */
  author?: string
}

export type QuoteCarousel = {
  _type: 'QuoteCarousel'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<Quote>>
}

export type RelatedLinkCard = {
  _type: 'RelatedLinkCard'
  /**
   * Reference — `reference`
   *
   *
   */
  reference?:
    | InternalArticle
    | ExternalArticle
    | FunctionalProductDisplayPage
    | EnhancedProductDisplayPage
    | ProductCategory
    | LearnHome
    | LearnAll
    | LearnPage
    | UtilityPage
    | About
    | Help
    | Homepage
    | Investors
    | TeamPage
    | Reviews

  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Link — `string`
   *
   *
   */
  link?: Slug

  /**
   * Media — `media`
   *
   *
   */
  media?: Media
}

export type RelatedLinks = {
  _type: 'RelatedLinks'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<RelatedLinkCard>>
}

export type RelatedProducts = {
  _type: 'RelatedProducts'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Product Collection — `reference`
   *
   *
   */
  relatedProducts?: ProductCollection
}

export type Body = Array<Keyed<Block>>

export type ReviewCarousel = {
  _type: 'ReviewCarousel'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Copy — `text`
   *
   *
   */
  copy?: string

  /**
   * Type — `string`
   *
   *
   */
  mode?: 'category' | 'company' | 'product'

  /**
   * Product — `reference`
   *
   * The product you want to show reviews for
   */
  product?: Product

  /**
   * Category — `reference`
   *
   * The category you want to show reviews for
   */
  categories?: ProductCategory[]

  /**
   * Stars — `number`
   *
   * The minimum star rating for reviews that the module will show. This has no effect when showing company reviews.
   */
  stars?: number

  /**
   * Review amount — `number`
   *
   * How many reviews should be shown
   */
  count?: number
}

export type ReviewsModule = {
  _type: 'ReviewsModule'
  /**
   * Type — `string`
   *
   *
   */
  mode?: 'category' | 'company' | 'product'

  /**
   * Product — `reference`
   *
   * The product you want to show reviews for
   */
  product?: Product

  /**
   * Category — `reference`
   *
   * The category you want to show reviews for
   */
  categories?: ProductCategory[]

  /**
   * Stars — `number`
   *
   * The minimum star rating for reviews that the module will show. This has no effect when showing company reviews.
   */
  stars?: number

  /**
   * Review amount — `number`
   *
   *
   */
  count?: number
}

export type ShareholderDocument = {
  _type: 'ShareholderDocument'
  /**
   * Date — `string`
   *
   *
   */
  document_date?: string

  /**
   * Document name — `string`
   *
   *
   */
  document_name?: string

  /**
   * PDF — `file`
   *
   *
   */
  document?: File
}

export type SignificantShareholder = {
  _type: 'SignificantShareholder'
  /**
   * Shareholder name — `string`
   *
   *
   */
  shareholder_name?: string

  /**
   * Number of shares — `string`
   *
   *
   */
  number_shares?: string

  /**
   * % of issued capital — `string`
   *
   *
   */
  share_percentage?: string
}

export type SiteBanner = {
  _type: 'SiteBanner'
  /**
   * Dismissable — `boolean`
   *
   *
   */
  dismissable?: boolean

  /**
   * Banner mode — `string`
   *
   *
   */
  type?: 'static' | 'dynamic'

  /**
   * Banner color — `string`
   *
   *
   */
  color?: 'green' | 'blue'

  /**
   * Messages — `array`
   *
   *
   */
  messages?: Array<Keyed<SiteBannerMessage>>
  usMessages?: Array<Keyed<SiteBannerMessage>>
  euMessages?: Array<Keyed<SiteBannerMessage>>
}

export type SiteBannerMessage = {
  _type: 'siteBannerMessage'
  /**
   * Message — `array`
   *
   *
   */
  message?: Array<Keyed<Block>>
}

export type Spotlight = {
  _type: 'Spotlight'
  /**
   * Title — `string`
   *
   *
   */
  subtitle?: string

  /**
   * Image — `MediaImage`
   *
   *
   */
  image?: MediaImage

  /**
   * Text — `array`
   *
   *
   */
  text?: Array<Keyed<Block>>

  /**
   * CTAButton - `CTAButton`
   */
  CTAButton?: CTAButton
}

export type StoryIntro = {
  _type: 'StoryIntro'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Body text - `text`
   *
   *
   */
  body?: string

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton

  /**
   * Left Image — `media`
   *
   *
   */
  leftImage?: Media

  /**
   * Right Image — `media`
   *
   *
   */
  rightImage?: Media
}

export type Tags = Array<Keyed<Tag>>

export type TeamGrid = {
  _type: 'TeamGrid'
  /**
   * 'Show Team Members Bio Text' - 'boolean'
   */
  showBio?: boolean
  /**
   * Items — `array`
   *
   *
   */
  items?: Array<Keyed<TeamMember>>
}

export type TeamGridItem = {
  _type: 'TeamGridItem'
  /**
   * Media: Image — `MediaImage`
   *
   *
   */
  MediaImage?: MediaImage

  /**
   * Text — `string`
   *
   *
   */
  text?: string
}

export type TextGridItem = {
  _type: 'TextGridItem'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * CTA Button — `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton
}

export type Ticker = {
  _type: 'Ticker'
  /**
   * Text — `text`
   *
   * Use line breaks in the text to show lists on the site
   */
  text?: string
}

export type TwoColumnGrid = {
  _type: 'TwoColumnGrid'
  /**
   * Left Column — `array`
   *
   *
   */
  leftColumn?: Array<
    | Keyed<TextGridItem>
    | Keyed<MediaGridItem>
    | Keyed<GenericCard>
    | Keyed<ImageSlider>
  >

  /**
   * Right Column — `array`
   *
   *
   */
  rightColumn?: Array<
    | Keyed<TextGridItem>
    | Keyed<MediaGridItem>
    | Keyed<GenericCard>
    | Keyed<ImageSlider>
  >

  /**
   * Reverse columns on mobile devices — `boolean`
   *
   *
   */
  columnReverseOnMobile?: boolean

  /**
   * Module should have background — `boolean`
   *
   *
   */
  shouldHaveBackground?: boolean
}

export type TwoImageAndText = {
  _type: 'TwoImageAndText'
  /**
   * Left Column — `array`
   *
   *
   */
  leftCol_twoImageAndText?: Array<
    Keyed<MediaGridItem> | Keyed<TwoImageAndTextVerticalColumn>
  >

  /**
   * Right Column — `array`
   *
   *
   */
  rightCol_twoImageAndText?: Array<
    Keyed<MediaGridItem> | Keyed<TwoImageAndTextVerticalColumn>
  >
}

export type TwoImageAndTextVerticalColumn = {
  _type: 'TwoImageAndTextVerticalColumn'
  /**
   * Media: Image — `MediaImage`
   *
   *
   */
  media?: Media

  /**
   * Heading — `string`
   *
   *
   */
  heading?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * CTAButton - `CTAButton`
   *
   *
   */
  CTAButton?: CTAButton
}

export type UGCCarousel = {
  _type: 'UGCCarousel'
  /**
   *  Gallery Name — `string`
   *
   * Enter a  gallery name that you want to display, this is case sensitive!
   */
  albumName?: string

  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Copy — `text`
   *
   *
   */
  copy?: string

  /**
   * Label — `string`
   *
   *
   */
  label?: string

  /**
   * Link — `url`
   *
   *
   */
  link?: string
}

export type UtilityNavigationItem = {
  _type: 'UtilityNavigationItem'
  /**
   * Label — `string`
   *
   * Label for the nav item
   */
  label?: string

  /**
   * Reference — `reference`
   *
   *
   */
  reference?: UtilityPage

  /**
   * Custom Url — `string`
   *
   * Can't find the page you're looking for? Use a custom URL instead.
   */
  link?: string
}

export type UtilityContent = Array<Keyed<Block> | Keyed<MediaImage>>

export type WhitepaperHighlighter = {
  _type: 'WhitepaperHighlighter'
  /**
   * Title — `string`
   *
   *
   */
  title?: string

  /**
   * Text — `text`
   *
   *
   */
  text?: string

  /**
   * Whitepapers — `array`
   *
   *
   */
  whitepapers?: Array<Keyed<ArticleTile>>
}

export interface Slug {
  _type: 'slug'
  current: string
}

export interface File {
  _type: 'file'
  asset: any
}

export type Documents =
  | ShopifyProduct
  | EnhancedProductDisplayPage
  | Faq
  | FaqCategory
  | FunctionalProductDisplayPage
  | InternalArticle
  | ExternalArticle
  | Product
  | ProductCollection
  | ProductCategory
  | QrPage
  | Tag
  | UtilityPage
  | About
  | Footer
  | Help
  | Homepage
  | Investors
  | Interstitial
  | LearnPage
  | LearnAll
  | LearnHome
  | Navigation
  | Newsletter
  | NotFound
  | Reviews
  | SearchPage
  | ShoppingDirectBanner
  | SiteSettings
  | Socials
  | TeamPage
  | TeamMember
  | UtilityNavigation
  | BaseAccountPageSettings

/**
 * This interface is a stub. It was referenced in your sanity schema but
 * the definition was not actually found. Future versions of
 * sanity-codegen will let you type this explicity.
 */
export type Table = {
  _key: string
  _type: 'Table'
  rows?: Array<{
    _type: 'TableRow'
    _key: string
    cells?: string[]
  }>
}

/**
 * This interface is a stub. It was referenced in your sanity schema but
 * the definition was not actually found. Future versions of
 * sanity-codegen will let you type this explicity.
 */
export type MuxVideo = any

export type UGCCarouselWithYotpo = UGCCarousel & {
  images: PhotoAlbum
}

export type InstagramCarouselWithYotpo = InstagramCarousel & {
  images: PhotoAlbum
}

export type SocialChannelsPromotionWithYotpo = SocialChannelsPromotion & {
  images: PhotoAlbum
}

export type ProductWithYotpo = Product & {
  reviews: ProdBottomLine
}

type ReviewCarouselCompany = Omit<ReviewCarousel, 'mode' | 'product'> &
  SiteReviews & {
    mode: ReviewModuleModes
    cmsProduct: PickType<ReviewCarousel, 'product'>
  }

export type ReviewCarouselCategory = Omit<ReviewCarousel, 'mode' | 'product'> &
  CategoryReviews & {
    mode: ReviewModuleModes
    cmsProduct: PickType<ReviewCarousel, 'product'>
  }

export type ReviewCarouselProduct = Omit<ReviewCarousel, 'mode' | 'product'> &
  ProductReviews & {
    mode: ReviewModuleModes
    cmsProduct: PickType<ReviewCarousel, 'product'>
  }

export type ReviewCarouselWithYotpo =
  | ReviewCarouselCompany
  | ReviewCarouselCategory
  | ReviewCarouselProduct

export type ReviewsCompany = Omit<ReviewsModule, 'mode'> &
  SiteReviews & {
    mode: ReviewModuleModes.COMPANY
    cmsProduct: PickType<ReviewsModule, 'product'>
    allProducts: {
      id: string
      title: string
    }[]
  }

export type ReviewsCategory = Omit<ReviewsModule, 'mode'> &
  CategoryReviews & {
    mode: ReviewModuleModes.CATEGORY
    cmsProduct: PickType<ReviewsModule, 'product'>
  }

export type ReviewsProduct = Omit<ReviewsModule, 'mode'> &
  ProductReviews & {
    mode: ReviewModuleModes.PRODUCT
    cmsProduct: PickType<ReviewsModule, 'product'>
  }

export type ReviewsWithYotpo = ReviewsCompany | ReviewsCategory | ReviewsProduct

export type MutatedSanityArticlesGrid = Keyed<ArticlesGrid> & {
  articles: Array<Keyed<InternalArticle> | Keyed<ExternalArticle>>
  categories: string[]
}

export type GetLayoutDataPayload = {
  navbar: NavigationQueryResponse
  footer: Footer
  siteSettings: SiteSettings
}

export type CMSComponentTypes = NonNullSkipArray<
  PickType<Homepage, 'CMSComponents'>
> | null

export type Document = SanityDocument & {
  PageMeta?: PageMeta
  CMSComponents?: CMSComponentTypes[]
  ArticlesGrid?: Maybe<ArticlesGrid> | undefined
  product?: Product
}

export type GetLayoutDataVariables = {
  navbarId: 'navigation'
  footerId: 'footer'
  settingsId: 'SiteSettings'
}

export enum ProductStatus {
  ACTIVE = 'active',
  INTEREST = 'interest',
  PREORDER = 'preorder',
}

type MenuItemsChildren = {
  _key: string
  _type: string
  title: string
  slug: string
  category?: string
}
export interface NavBarChild extends Link {
  _key: string
  _type?: string
  label: string | null
  comingSoon: boolean | null
  media: MediaImage | MediaMux | null
}

interface NavBar extends Link {
  _key: string
  _type?: string
  label: string | null
  children: Array<NavBarChild> | null
}

export type NavigationQueryResponse = {
  _id?: string
  navbar: NavBar[]
  mobileSubNav: MenuItemsChildren[]
}

export interface Link {
  linkType: 'internal' | 'external'
  label?: string | null
  link?: string | null
  referenceType?: string | null
  category?: string | null
}

export interface ThreeLinkGridItem {
  title?: string | null
  copy?: string | null
  media?: Media | null
  link?: Link | null
}

export interface ThreeLinkGrid {
  _type: 'ThreeLinkGrid'
  header?: HeaderText | null
  items?: Array<Keyed<ThreeLinkGridItem>> | null
}
