import { atom, useAtom } from 'jotai'
import cookies from 'js-cookie'

import { SetStateAction, useEffect } from 'react'

import { BANNER_DISMISSED_ID } from '../../../references/constants'

const HEADER_BASE_STATE = {
  bannerDismissed: false,
  headerHeight: 0,
  bannerHeight: 0,
  desktopOpen: false,
  mobileOpen: false,
  transparent: false,
  desktopMenuOpen: false,
  theme: 'black',
}

type HeaderContext = {
  bannerDismissed: boolean
  headerHeight: number
  bannerHeight: number
  desktopOpen: boolean
  mobileOpen: boolean
  transparent: boolean
  desktopMenuOpen: boolean
  theme: string
}

const headerAtom = atom<HeaderContext>(HEADER_BASE_STATE)

export const useHeaderState = (
  options?: Partial<{
    theme: string
    transparent: boolean
    canBannerBeDismissed?: boolean
  }>
) => {
  const [headerState, setHeaderState] = useAtom(headerAtom)

  useEffect(() => {
    setHeaderState({
      ...headerState,
      bannerDismissed:
        options?.canBannerBeDismissed !== undefined
          ? Boolean(cookies.get(BANNER_DISMISSED_ID))
          : false,
      theme: options?.theme !== undefined ? options.theme : headerState.theme,
      transparent:
        options?.transparent !== undefined
          ? options.transparent
          : headerState.transparent,
    })
  }, [JSON.stringify(options ?? {})])

  return [headerState, setHeaderState] as [
    HeaderContext,
    (update: SetStateAction<HeaderContext>) => void
  ]
}
