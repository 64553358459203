import styled from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { Sanity } from '@cellulargoods/types'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_32_500,
  aspectRatio,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Heading, Text, TextProps } from '../Text'
import { Media } from '../Media'
import { ButtonTypes, Button } from '../Button'

export type ContentSpotlightProps = Sanity.Keyed<Sanity.Spotlight>

const serializers = {
  marks: {
    underline: ({ children }: { children: string }) => {
      return <mark>{children}</mark>
    },
  },
  types: {
    block: (props: TextProps) => {
      return <SpotlightCopy fontStyle={FONT_STYLE_SOFIA_32_500} {...props} />
    },
  },
}

export const ContentSpotlight = ({
  subtitle,
  image,
  text,
  CTAButton,
}: ContentSpotlightProps) => {
  const renderCta = CTAButton && CTAButton.label && CTAButton.url

  return (
    <Spotlight>
      {subtitle ? (
        <SpotlightTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_16_400}>
          {subtitle}
        </SpotlightTitle>
      ) : null}
      {image && (
        <SpotlightImage>
          <Media {...image} sizes={['100vw', null, null, '33vw']} />
        </SpotlightImage>
      )}
      {text ? (
        <SpotlightBlock>
          <BlockContent
            serializers={serializers}
            renderContainerOnSingleChild
            blocks={text}
          />
        </SpotlightBlock>
      ) : null}
      {renderCta && (
        <SpotlightCtaContainer>
          <SpotlightCTAButton
            variant={ButtonTypes.SECONDARY}
            tag="a"
            href={CTAButton.url}
            isExternal={Boolean(CTAButton.isExternal)}
          >
            {CTAButton.label}
          </SpotlightCTAButton>
        </SpotlightCtaContainer>
      )}
    </Spotlight>
  )
}

const Spotlight = styled.section`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 6rem 15.5rem;
  }
`

const SpotlightTitle = styled(Heading)`
  text-align: center;
`

const SpotlightImage = styled.div`
  margin-top: 3rem;
  ${aspectRatio(335, 192)};

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 44rem;
    margin: 3rem auto 0 auto;
  }
`

const SpotlightCopy = styled(Text)`
  & > mark {
    display: inline-block;
    height: 1.05em;
    line-height: 1;
    background-color: var(--lightBrown);
  }

  & + & {
    margin-top: 4rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    & + & {
      margin-top: 6rem;
    }
  }
`

const SpotlightBlock = styled.div`
  margin-top: 6rem;
  text-align: center;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 120rem;
    margin: 4rem auto 0 auto;
  }
`

const SpotlightCtaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SpotlightCTAButton = styled(Button)`
  margin-top: 3rem;
`
