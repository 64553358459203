import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'
import { Media } from '../Media'

import {
  aspectRatio,
  FONT_STYLE_SOFIA_32_500,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export type FeatureCardProps = Sanity.FeatureCard & {
  className?: string
  isSubmodule?: boolean
  hasAnchor?: boolean
}

export const FeatureCard = ({
  title,
  text,
  CTAButton: cta,
  image,
  className,
  isSubmodule,
  hasAnchor,
}: FeatureCardProps) => {
  return (
    <Feature as={isSubmodule ? 'div' : 'section'} className={className}>
      {title ? (
        <FeatureTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
          {title}
        </FeatureTitle>
      ) : null}
      <div>
        {image && image.length > 0 ? (
          <FeatureMediaWrap>
            <Media {...image[0]} sizes={['100vw', null, null, '25vw']} />
          </FeatureMediaWrap>
        ) : null}
        {text ? <FeatureText>{text}</FeatureText> : null}
      </div>
      {cta && cta.label && cta.url ? (
        <FeatureCtaWrapper>
          <FeatureCta
            variant={ButtonTypes.SECONDARY}
            tag={hasAnchor ? 'span' : 'a'}
            href={cta.url}
          >
            {cta.label}
          </FeatureCta>
        </FeatureCtaWrapper>
      ) : null}
    </Feature>
  )
}

const Feature = styled.section``

const FeatureTitle = styled(Heading)`
  text-align: center;
`

const FeatureMediaWrap = styled.div`
  margin-top: 3rem;

  ${aspectRatio(35, 43)}

  ${MEDIA_QUERIES.desktopUp} {
    margin: 6rem 10rem 0 10rem;
  }
`

const FeatureCtaWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const FeatureCta = styled(Button)`
  margin-top: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 10rem;
  }
`

const FeatureText = styled(Text)`
  text-align: center;
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 89rem;
    padding: 0 12rem;
    margin: 3rem auto 0 auto;
  }
`
