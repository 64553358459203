import styled, { css } from 'styled-components'
import { Star, StarHalf } from 'phosphor-react'

import { Text } from '../Text'

import { FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'

type StarsProps = {
  rating: number
  className?: string
  reviewCount?: number
  label?: string
  size?: number
}

export const ReviewStarRating = ({
  rating = 0,
  className,
  reviewCount,
  label,
  size = 24,
}: StarsProps) => {
  if (reviewCount === 0) {
    return null
  }

  return (
    <StarRating className={className}>
      {label ? (
        <ReviewTitle tag="legend" fontStyle={FONT_STYLE_SOFIA_11_400}>
          {label}
        </ReviewTitle>
      ) : null}
      <ReviewCountContainer>
        <StarsContainer $halfStars>
          {Array(5)
            .fill(null)
            .map((_, i) =>
              i + 0.5 <= rating ? (
                <StarHalf key={i} color="black" weight="fill" size={size} />
              ) : null
            )}
        </StarsContainer>
        <StarsContainer>
          {Array(5)
            .fill(null)
            .map((_, i) => (
              <Star
                key={i}
                color="black"
                weight={i <= rating - 1 ? 'fill' : 'regular'}
                size={size}
              />
            ))}
        </StarsContainer>
      </ReviewCountContainer>
    </StarRating>
  )
}

const StarRating = styled.div``

const ReviewCountContainer = styled.div`
  position: relative;
`

const StarsContainer = styled.div<{ $halfStars?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.1rem 0;

  ${(props) =>
    props.$halfStars
      ? css`
          position: absolute;
          top: 0;
          left: 0;
        `
      : ''}
`

const ReviewTitle = styled(Text)`
  margin-bottom: 1rem;
`
