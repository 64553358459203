import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { Image } from '../Image'

import {
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_32_500,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Heading } from './Heading'
import { Text } from './Text'

type TextBulletBannerListProps = Sanity.Keyed<Sanity.BulletList> & {
  className?: string
}

export const TextBulletBannerList = ({
  className,
  title,
  items,
}: TextBulletBannerListProps) => {
  return (
    <BulletList className={className}>
      <Heading tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
        {title}
      </Heading>
      {items && (
        <List>
          {items.map(
            (item) =>
              item && (
                <ListItem key={item._key}>
                  {item.icon && (
                    <ContentIcon>
                      <Image
                        image={item.icon}
                        layout="fill"
                        objectFit="cover"
                        sizes={['16vw', null, null, '4vw']}
                      />
                    </ContentIcon>
                  )}
                  <div>
                    {item.title ? (
                      <ContentHeading
                        fontStyle={FONT_STYLE_SOFIA_22_500}
                        tag="h3"
                      >
                        {item.title}
                      </ContentHeading>
                    ) : null}
                    {item.text ? <ContentText>{item.text}</ContentText> : null}
                  </div>
                </ListItem>
              )
          )}
        </List>
      )}
    </BulletList>
  )
}

const BulletList = styled.div`
  padding: 4rem 2rem;
`

const List = styled.ul`
  margin-top: 3rem;
`

const ListItem = styled.li`
  & + & {
    margin-top: 3rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
  }
`

const ContentIcon = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--softGrey);
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    flex: 1 0 6rem;
    margin-right: 4.5rem;
  }
`

const ContentHeading = styled(Heading)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0;
  }
`

const ContentText = styled(Text)`
  margin-top: 2rem;
`
