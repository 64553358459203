import styled from 'styled-components'
import Link from 'next/link'

import { Sanity, NonNullSkipArray, PickType } from '@cellulargoods/types'

import { mergeColumns } from '../../helpers/mergeColumns'

import { Image } from '../Image'
import { FeatureCard } from '../Cards/FeatureCard'
import { Media } from '../Media/Media'
import { TextBulletBannerList } from '../Text/TextBulletBannerList'

import { aspectRatio, MEDIA_QUERIES } from '@cellulargoods/styles'

import { ContentHeaderText } from './ContentHeaderText'
import { makeStringRelative } from '../../helpers/strings'

export type ContentFullLeftRightProps =
  Sanity.Keyed<Sanity.FullLeftRightComponent>

export const ContentFullLeftRight = ({
  leftCol,
  rightCol,
  hasBackground,
  hasMargins,
}: ContentFullLeftRightProps) => {
  if (!leftCol || !rightCol) {
    return null
  }

  const CMSComponents = mergeColumns(leftCol, rightCol)

  const columnsHasCta = CMSComponents.some(
    (comp) =>
      (comp._type === 'HeaderText' || comp._type === 'FeatureCard') &&
      comp.CTAButton &&
      comp.CTAButton.url &&
      comp.CTAButton.label
  )

  if (columnsHasCta) {
    const ctaUrl = CMSComponents.find(
      (comp) =>
        (comp._type === 'HeaderText' || comp._type === 'FeatureCard') &&
        comp.CTAButton &&
        comp.CTAButton.url
    ) as Sanity.FeatureCard | Sanity.HeaderText

    return (
      <Link href={`${makeStringRelative(ctaUrl.CTAButton?.url)}`} passHref>
        <SectionWrap as="a" $isAnchor hasBackground={hasBackground}>
          {CMSComponents.map(
            (item, index) =>
              item &&
              renderSubmodule(item, index, {
                hasMargins,
                hasAnchor: true,
              })
          )}
        </SectionWrap>
      </Link>
    )
  }

  return (
    <SectionWrap hasBackground={hasBackground}>
      {CMSComponents.map(
        (item, index) =>
          item &&
          renderSubmodule(item, index, {
            hasMargins,
          })
      )}
    </SectionWrap>
  )
}

const renderSubmodule = (
  CMSComponent: NonNullSkipArray<
    PickType<Sanity.FullLeftRightComponent, 'leftCol'>
  >,
  index: number,
  options: Pick<ContentFullLeftRightProps, 'hasMargins'> & {
    hasAnchor?: boolean
  }
) => {
  switch (CMSComponent._type) {
    case 'MediaImage':
      return (
        <ImageModule key={CMSComponent._key} hasMargins={options.hasMargins}>
          <AspectWrapper video={false}>
            <ImageMedia
              image={CMSComponent}
              layout="fill"
              objectFit="cover"
              sizes={['100vw', null, null, '50vw']}
            />
          </AspectWrapper>
        </ImageModule>
      )
    case 'MediaMux':
      return (
        <ImageModule key={CMSComponent._key} hasMargins={options.hasMargins}>
          <AspectWrapper video>
            <Media {...CMSComponent} sizes={['100vw', null, null, '50vw']} />
          </AspectWrapper>
        </ImageModule>
      )
    case 'FeatureCard':
      return (
        <FeatureModule
          key={CMSComponent._key}
          {...CMSComponent}
          hasAnchor={options.hasAnchor}
        />
      )
    case 'HeaderText':
      return (
        <HeaderModule
          key={CMSComponent._key}
          {...CMSComponent}
          isSubmodule
          hasAnchor={options.hasAnchor}
        />
      )
    case 'BulletList':
      return (
        <BulletListModule
          isFirst={index === 0}
          key={CMSComponent._key}
          {...CMSComponent}
        />
      )
    default:
      console.warn(`Missing case for ${CMSComponent} in ContentFullLeftRight`)
      return null
  }
}

const ImageModule = styled.div<Pick<ContentFullLeftRightProps, 'hasMargins'>>`
  padding: ${(props) => (!props.hasMargins ? '0' : '4rem 2rem 0 2rem')};
  order: 1;
  overflow: hidden;

  ${MEDIA_QUERIES.desktopUp} {
    padding: ${(props) => (!props.hasMargins ? '0' : '10rem 8rem')};
    order: unset;
  }
`

const AspectWrapper = styled.div<{
  video: boolean
}>`
  ${(props) => aspectRatio(335, 410, props.video)}
  transition: transform 400ms ease-out;

  ${MEDIA_QUERIES.desktopUp} {
    min-height: 100%;
    min-width: 100%;
  }
`

const SectionWrap = styled.section<{
  $isAnchor?: boolean
  hasBackground: PickType<ContentFullLeftRightProps, 'hasBackground'>
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    !props.hasBackground ? 'transparent' : 'var(--softGrey)'};

  ${MEDIA_QUERIES.desktopUp} {
    flex-direction: row;

    & > * {
      flex: 1 0 50%;
    }
  }
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      ${AspectWrapper} {
        transform: ${(props) => (props.$isAnchor ? 'scale(1.1)' : 'scale(1)')};
      }

      h2 {
        text-decoration: ${(props) => (props.$isAnchor ? 'underline' : 'none')};
      }
    }
  }
`

const ImageMedia = styled(Image)``

const HeaderModule = styled(ContentHeaderText)`
  display: flex;
  align-items: center;
  order: 2;

  & > div {
    max-width: 53rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    order: unset;
  }
`

const FeatureModule = styled(FeatureCard)`
  padding: 4rem 2rem;
  order: 2;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 6rem 8.5rem 4rem 8.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const BulletListModule = styled(TextBulletBannerList)<{
  isFirst: boolean
}>`
  order: 2;

  ${MEDIA_QUERIES.desktopUp} {
    flex: 0 1 50%;
    margin: ${(props) =>
      props.isFirst
        ? '8.5rem 12.5rem 8.5rem 15.5rem'
        : '8.5rem 15.5rem 8.5rem 12.5rem'};
  }
`
