import { KeyboardEvent, useState } from 'react'
import isEmail from 'validator/lib/isEmail'

import { useKlaviyo } from './useKlaviyo'
import {
  INVALID_EMAIL_ERROR,
  EMAIL_IS_NOT_UNIQUE,
  SERVER_ERROR,
  KLAVIYO_LIST_IDS,
} from '@cellulargoods/core'

/**
 * TODO – this is only used in one place
 * so we could probably get rid of it
 * and move the functionality closer to
 * the footer where it's used
 */
export const useNewsletterSignup = (
  captureSource: string,
  lists: KLAVIYO_LIST_IDS
) => {
  const [newsletterInputState, setNewsletterInputState] = useState<{
    inputValue: string
    checkedValue: boolean
    checkError: boolean
    error?: string
    success: boolean
    isSubmitting: boolean
  }>({
    inputValue: '',
    checkedValue: false,
    checkError: false,
    error: undefined,
    success: false,
    isSubmitting: false,
  })

  const { subscribeProfileToLists, isEmailUnique } = useKlaviyo()

  const handleJoinMailingListSubmit = async (email: string) => {
    setNewsletterInputState((state) => ({
      ...state,
      isSubmitting: true,
      success: false,
    }))

    try {
      // validate its actually an email
      const valueIsOkay = isEmail(email)
      const hasPrivacyBeenChecked = newsletterInputState.checkedValue

      if (!valueIsOkay || !hasPrivacyBeenChecked) {
        setNewsletterInputState((state) => ({
          ...state,
          error: valueIsOkay ? undefined : INVALID_EMAIL_ERROR,
          checkError: !hasPrivacyBeenChecked,
          isSubmitting: false,
        }))
        return
      } else if (
        newsletterInputState.error === INVALID_EMAIL_ERROR ||
        newsletterInputState.checkError
      ) {
        setNewsletterInputState((state) => ({
          ...state,
          error: valueIsOkay ? undefined : INVALID_EMAIL_ERROR,
          checkError: !hasPrivacyBeenChecked,
        }))
      }

      const emailUnique = await Promise.all(
        Object.values(lists).map((id) => isEmailUnique({ email, list: id }))
      )

      if (emailUnique.some(({ success }) => !success)) {
        setNewsletterInputState((state) => ({
          ...state,
          error: EMAIL_IS_NOT_UNIQUE,
          isSubmitting: false,
        }))
        return
      } else if (
        emailUnique.every((check) => check) &&
        newsletterInputState.error === EMAIL_IS_NOT_UNIQUE
      ) {
        setNewsletterInputState((state) => ({
          ...state,
          error: undefined,
          isSubmitting: false,
        }))
      }

      const { success } = await subscribeProfileToLists({
        email,
        captureSource: captureSource,
        dateCreated: new Date().toISOString(),
        lists: Object.values(lists),
      })

      if (success) {
        setNewsletterInputState((state) => ({
          ...state,
          success,
          error: undefined,
          isSubmitting: false,
        }))

        // @ts-ignore
        pintrk('track', 'SignUp')
      } else if (!success) {
        setNewsletterInputState((state) => ({
          ...state,
          success: false,
          error: SERVER_ERROR,
          isSubmitting: false,
        }))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleNewsletterInputChange = (text: string) => {
    setNewsletterInputState((state) => ({
      ...state,
      inputValue: text,
    }))
  }

  const handleNewsletterKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const { inputValue } = newsletterInputState
    if (e.key === 'Enter' && inputValue !== '') {
      handleJoinMailingListSubmit(inputValue)
    }
  }

  const handleNewsletterEnterClick = () => {
    const { inputValue } = newsletterInputState
    if (inputValue !== '') {
      handleJoinMailingListSubmit(inputValue)
    }
  }

  const handleCheckChange = (checked: boolean) => {
    setNewsletterInputState((s) => ({
      ...s,
      checkedValue: checked,
    }))
  }

  return {
    events: {
      handleChange: handleNewsletterInputChange,
      handleKeyDown: handleNewsletterKeyDown,
      handleClick: handleNewsletterEnterClick,
      handleCheckChange,
    },
    state: newsletterInputState,
  }
}
