import { ButtonPrimary, ButtonPrimaryProps, Bg } from './ButtonPrimary'
import {
  ButtonSecondary,
  ButtonSecondaryProps,
  ButtonSecondaryTheme,
} from './ButtonSecondary'
import {
  ButtonTertiary,
  ButtonTertiaryProps,
  ButtonTertiaryTheme,
} from './ButtonTertiary'

export enum ButtonTypes {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export type ButtonProps = {
  variant: ButtonTypes
} & ButtonPrimaryProps &
  ButtonSecondaryProps &
  ButtonTertiaryProps

export const Button = ({ variant, ...restProps }: ButtonProps) => {
  switch (variant) {
    case ButtonTypes.PRIMARY:
      return <ButtonPrimary {...restProps} />
    case ButtonTypes.SECONDARY:
      return <ButtonSecondary {...restProps} />
    case ButtonTypes.TERTIARY:
      return <ButtonTertiary {...restProps} />
    default:
      return null
  }
}

export type { ButtonPrimaryProps, ButtonTertiaryProps, ButtonSecondaryProps }
export { Bg, ButtonTertiaryTheme, ButtonSecondaryTheme }
