import styled from 'styled-components'

import { Button, ButtonTypes } from '../Button'
import { Heading, Text } from '../Text'

import { Sanity } from '@cellulargoods/types'

import { FONT_STYLE_SOFIA_32_500 } from '@cellulargoods/styles'

const TextRenderer = ({ blocks }: { blocks: Sanity.Keyed<Sanity.Block>[] }) => {
  const items = blocks.map((block) => {
    return block.children.map(
      ({ text, _key }: { text: string; _key: string }) => (
        <Text key={_key}>{text}</Text>
      )
    )
  })

  return <>{items}</>
}

export type GenericCardProps = Sanity.GenericCard

export const GenericCard = ({ title, CTAButton, text }: GenericCardProps) => {
  return (
    <div>
      <CardHeading tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
        {title}
      </CardHeading>
      {text ? (
        <CardText>
          <TextRenderer blocks={text} />
        </CardText>
      ) : null}
      {CTAButton?.label && (
        <Button
          variant={ButtonTypes.SECONDARY}
          tag="a"
          href={CTAButton.url ?? ''}
          isExternal={Boolean(CTAButton.isExternal)}
        >
          {CTAButton.label}
        </Button>
      )}
    </div>
  )
}

export default { GenericCard }

const CardHeading = styled(Heading)`
  margin-bottom: 2rem;
`

const CardText = styled.div`
  margin-bottom: 3rem;
`
