import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { Image } from '../../Image'

import { TRANSITION } from './utils'

const Logos = ({
  activeQuoteIndex,
  items,
  onClick,
}: {
  activeQuoteIndex: number
  items: Sanity.Keyed<Sanity.LogoItemWithQuote>[]
  onClick: (index: number) => void
}) => {
  if (!items) return null

  return (
    <LogosWrap>
      {items.map((logo, index) => {
        return logo.asset ? (
          <LogoItem key={logo._key} logoCount={items.length}>
            <LogoBtn onClick={() => onClick(index)}>
              <ImageWrap isActive={activeQuoteIndex === index}>
                <Image
                  image={logo.asset}
                  layout="fill"
                  objectFit="contain"
                  sizes={['50vw', null, null, '14vw']}
                />
              </ImageWrap>
            </LogoBtn>
          </LogoItem>
        ) : null
      })}
    </LogosWrap>
  )
}

export default Logos

const LogosWrap = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0 4rem;
  }
`

const LogoItem = styled.li<{
  logoCount: number
}>`
  flex-basis: 50%;

  & + & {
    margin-top: 2rem;
  }

  &:nth-child(2) {
    margin-top: 0;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex-basis: ${(props) => `calc(100% / ${props.logoCount})`};

    & + & {
      margin-top: 0;
    }
  }
`

const LogoBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  width: 100%;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 21rem;
  margin: 0 auto;
`

const ImageWrap = styled.div<{
  isActive: boolean
}>`
  width: 100%;
  position: relative;
  padding: 15px;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0.25)};
  transition: ${TRANSITION};
`
