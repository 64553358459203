import styled from 'styled-components'

import { Text } from '../Text/Text'

import {
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_14_500,
} from '@cellulargoods/styles'

type SoldOutOrnamentProps = {
  className?: string
  small?: boolean
}

export const SoldOutOrnament = ({
  className,
  small = false,
}: SoldOutOrnamentProps) => (
  <SoldOut
    small={small}
    className={className}
    tag="span"
    fontStyle={small ? FONT_STYLE_SOFIA_11_500 : FONT_STYLE_SOFIA_14_500}
  >
    Sold out
  </SoldOut>
)

const SoldOut = styled(Text)<{
  small: boolean
}>`
  color: var(--black);
  background-color: var(--lightGrey2);
  padding: ${(props) => (props.small ? '0.8rem 1rem' : '0.3rem 1.8rem 0.7rem')};
`
