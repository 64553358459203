import { CaretLeft, CaretRight } from 'phosphor-react'
import { MouseEvent } from 'react'
import styled from 'styled-components'

import { IconButton } from '../Button'
import { Text } from '../Text'

import { MEDIA_QUERIES } from '@cellulargoods/styles'

export type PaginationTabBarProps = {
  currentPage?: number
  limit?: number
  totalPages?: number
  itemCount?: number
  onLeftClick?: (e: MouseEvent<HTMLButtonElement>) => void
  onRightClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

export const PaginationTabBar = ({
  currentPage = 0,
  limit = 0,
  totalPages = 0,
  onLeftClick,
  onRightClick,
  itemCount = 0,
}: PaginationTabBarProps) => {
  const handleLeftClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onLeftClick) {
      onLeftClick(e)
    }
  }

  const handleRightClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onRightClick) {
      onRightClick(e)
    }
  }

  const lowerCount = (currentPage - 1) * limit + 1

  return (
    <PaginationBar>
      <PaginationTotals>
        {`${lowerCount}-${lowerCount + itemCount - 1}`}
      </PaginationTotals>
      <PaginationControls>
        <PaginationLeftButton
          ariaLabel={`Show page ${currentPage - 1}`}
          disabled={currentPage === 1}
          onClick={handleLeftClick}
        >
          <CaretLeft size={20} weight="thin" color="var(--darkGrey1)" />
        </PaginationLeftButton>
        <PaginationControlText>{`${currentPage}/${totalPages}`}</PaginationControlText>
        <PaginationRightButton
          ariaLabel={`Show page ${currentPage + 1}`}
          disabled={currentPage === totalPages}
          onClick={handleRightClick}
        >
          <CaretRight size={20} weight="thin" color="var(--darkGrey1)" />
        </PaginationRightButton>
      </PaginationControls>
    </PaginationBar>
  )
}

const PaginationBar = styled.div`
  padding: 4rem 0 4rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 3rem 3rem 8rem 4rem;
  }
`

const PaginationTotals = styled(Text)`
  position: relative;
  bottom: 0.4em;
`

const PaginationControls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const PaginationButton = styled(IconButton)`
  min-width: 4rem;
  min-height: 4rem;
`

const PaginationLeftButton = styled(PaginationButton)``

const PaginationRightButton = styled(PaginationButton)``

const PaginationControlText = styled(Text)`
  position: relative;
  bottom: 0.4rem;
`
