import styled from 'styled-components'
import { FONT_STYLE_SOFIA_16_400, getFontStyles } from '@cellulargoods/styles'

export const NavLinkIconWrap = styled.div`
  display: inline-flex;
`

export const NavLink = styled.button<{ theme?: string }>`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)};
  position: relative;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  color: ${(props) =>
    props.theme === 'white' ? 'var(--white)' : 'var(--black)'};

  &:visited {
    color: ${(props) =>
      props.theme === 'white' ? 'var(--white)' : 'var(--black)'};
  }

  /* Do it this way so it remains hover styled when we're not hovering on it */
  &.hovered,
  &:hover {
    /* color: var(--electricBlue); */

    & > span:first-child {
      text-decoration: underline;
    }
  }
`
