import { forwardRef, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CaretLeft, CaretRight } from 'phosphor-react'
import cookies from 'js-cookie'

import { Sanity } from '@cellulargoods/types'
import {
  FONT_STYLE_SOFIA_14_400,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'

import { CloseBtn, IconButton } from '../Button'
import { TextRenderer } from '../Renderers'

import { useHeaderState } from '../Site/Header/HeaderContext'
import { BANNER_DISMISSED_ID } from '../../..'
import { useCurrency } from '@cellulargoods/hooks'

export enum SiteBannerTypes {
  STATIC = 'static',
  DYNAMIC = 'dynamic',
}

export enum SiteBannerColors {
  BLUE = 'blue',
  GREEN = 'green',
}

export type SiteBannerProps = Omit<
  Sanity.Keyed<Sanity.SiteBanner>,
  'type' | 'color'
> & {
  type: SiteBannerTypes
  color: SiteBannerColors
}

export const SiteBanner = forwardRef<HTMLDivElement, SiteBannerProps>(
  ({ dismissable, type, color, messages, usMessages, euMessages }, ref) => {
    const [currency] = useCurrency()

    const baseMessages =
      currency === 'USD'
        ? usMessages ?? []
        : currency === 'EUR'
        ? euMessages ?? []
        : messages

    const [{ bannerDismissed }, setHeaderState] = useHeaderState()

    if (
      !baseMessages ||
      (baseMessages && baseMessages.length === 0) ||
      bannerDismissed
    ) {
      return null
    }

    const cleanMessages = baseMessages.filter(
      (msg) => msg !== null || msg !== undefined
    ) as Sanity.Keyed<Sanity.SiteBannerMessage>[]

    const handleDismissClick = () => {
      setHeaderState((s) => ({
        ...s,
        bannerDismissed: true,
      }))

      cookies.set(BANNER_DISMISSED_ID, 'true', {
        expires: 1000 * 60 * 60 * 24 * 14, // 14 days
      })
    }

    return (
      <Banner ref={ref} color={color}>
        {type === SiteBannerTypes.STATIC ? (
          <StaticMessages messages={cleanMessages} />
        ) : (
          <DynamicMessages messages={cleanMessages} />
        )}
        {dismissable && (
          <BannerCloseButton
            color={
              color === SiteBannerColors.BLUE ? 'var(--white)' : 'var(--black)'
            }
            size={16}
            onClick={handleDismissClick}
          />
        )}
      </Banner>
    )
  }
)

const DynamicMessages = ({
  messages,
}: {
  messages: Sanity.Keyed<Sanity.SiteBannerMessage>[]
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const messagesRef = useRef<HTMLDivElement>(null)
  const [wrapHeight, setWrapHeight] = useState<number | null>(null)

  useEffect(() => {
    const handleResize = () => {
      const children =
        messagesRef.current && Array.from(messagesRef.current!.children)

      if (children) {
        const childrenHeights = children!.map((child) => {
          return child.scrollHeight
        })
        setWrapHeight(Math.max(...childrenHeights))
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleArrowClick = (direction: 'left' | 'right') => () => {
    const ind = getNextIndex(direction, activeIndex, messages.length)
    setActiveIndex(ind)
  }

  return (
    <DynamicMesssageContainer
      style={{
        height: `${wrapHeight}px`,
      }}
      ref={messagesRef}
    >
      <DynamicButton
        ariaLabel={`See message ${getNextIndex(
          'left',
          activeIndex,
          messages.length
        )}`}
        onClick={handleArrowClick('left')}
      >
        <CaretLeft size={20} weight="thin" color="var(--white)" />
      </DynamicButton>
      <DynamicMessagesWrap>
        {messages.map((msg, index) =>
          msg.message ? (
            <DynamicMessage key={msg._key} isActive={activeIndex === index}>
              <TextRenderer blocks={msg.message} />
            </DynamicMessage>
          ) : null
        )}
      </DynamicMessagesWrap>
      <DynamicButton
        ariaLabel={`See message ${getNextIndex(
          'right',
          activeIndex,
          messages.length
        )}`}
        onClick={handleArrowClick('right')}
      >
        <CaretRight size={20} weight="thin" color="var(--white)" />
      </DynamicButton>
    </DynamicMesssageContainer>
  )
}

const StaticMessages = ({
  messages,
}: {
  messages: Sanity.Keyed<Sanity.SiteBannerMessage>[]
}) => {
  const messageBlocks = messages.flatMap((msg) => msg.message) as Sanity.Block[]

  return (
    <StaticMessageContainer>
      <TextRenderer blocks={messageBlocks} />
    </StaticMessageContainer>
  )
}

const Banner = styled.div<Pick<SiteBannerProps, 'color'>>`
  width: 100%;
  background-color: ${(props) =>
    props.color === SiteBannerColors.BLUE
      ? 'var(--darkElectricBlue)'
      : 'var(--lightBrown)'};
  padding: 2rem 2rem;
  position: relative;
  z-index: 10;

  & p,
  & a {
    color: ${(props) =>
      props.color === SiteBannerColors.BLUE ? 'var(--white)' : 'var(--black)'};
  }

  ${MEDIA_QUERIES.desktopUp} {
    height: 4.6rem;
  }
`

const getNextIndex = (
  direction: 'left' | 'right',
  prevIndex: number,
  arrayLength: number
) => {
  const newIndex = direction === 'left' ? prevIndex - 1 : prevIndex + 1
  return arrayLength - 1 === prevIndex
    ? 0
    : newIndex < 0
    ? arrayLength - 1
    : newIndex
}

const BannerCloseButton = styled(CloseBtn)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;

  ${MEDIA_QUERIES.desktopUp} {
    top: 0.7rem;
    right: 2rem;
  }
`

const DynamicMesssageContainer = styled.div`
  display: flex;
  margin: 0.6rem 4rem;
  align-items: center;
  justify-content: space-between;

  & p {
    ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
    margin: 0;
    text-align: center;
  }

  & a {
    ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
  }

  ${MEDIA_QUERIES.desktopUp} {
    height: 100% !important;
    margin: 0 2rem;
  }
`

const DynamicButton = styled(IconButton)``

const DynamicMessagesWrap = styled.div`
  flex: 1 0 auto;
  position: relative;
  margin: 0 2rem;
  bottom: 0.2rem;
  height: 100%;
  display: flex;
  justify-content: center;

  ${MEDIA_QUERIES.desktopUp} {
    bottom: 0rem;
    top: 0.1rem;
  }
`

const DynamicMessage = styled.div<{
  isActive: boolean
}>`
  position: absolute;
  transition: 1000ms opacity;
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
`

const StaticMessageContainer = styled.div`
  margin: 0.3rem 0;

  & p {
    ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
    margin: 0;
    text-align: center;
  }

  & a {
    ${getFontStyles(FONT_STYLE_SOFIA_14_400)}
  }

  ${MEDIA_QUERIES.desktopUp} {
    position: relative;
    bottom: 1rem;

    & > div {
      display: flex;
      justify-content: center;
    }

    & p + p {
      margin-left: 2.2rem;
      position: relative;

      &:before {
        content: '•';
        width: 2.2rem;
        height: 2.2rem;

        position: absolute;
        left: -2.2rem;
        top: -0.1rem;
      }
    }
  }
`
