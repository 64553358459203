import styled from 'styled-components'
import Link from 'next/link'

import { Sanity } from '@cellulargoods/types'

import { Heading } from '../Text/Heading'

import { FONT_STYLE_SOFIA_14_500 } from '@cellulargoods/styles'

export type TagProps = Pick<Sanity.Tag, 'slug' | 'title'> & {
  className?: string
  inSnippet?: boolean
}

export const Tag = ({
  title,
  slug,
  className,
  inSnippet = false,
}: TagProps) => {
  const { current } = slug ?? {}

  const shouldBeAnchor = Boolean(current)

  const inner = (
    <TagTitle
      fontStyle={FONT_STYLE_SOFIA_14_500}
      tag={inSnippet ? 'span' : 'h4'}
    >
      {title}
    </TagTitle>
  )

  if (shouldBeAnchor) {
    return (
      <Link href={`learn/${current}`} passHref>
        <TagContainer className={className} as="a">
          {inner}
        </TagContainer>
      </Link>
    )
  }

  return (
    <TagContainer as={inSnippet ? 'span' : 'div'} className={className}>
      {inner}
    </TagContainer>
  )
}

const TagContainer = styled.div`
  padding: 0.5rem 1.8rem;
  display: inline-block;
  background-color: var(--darkElectricBlue);
  border-radius: 0.1rem;

  & > * {
    color: var(--white);
  }
`

const TagTitle = styled(Heading)`
  position: relative;
  top: -2px;
`
