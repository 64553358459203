import { Maybe, Sanity } from '@cellulargoods/types'

export const DEFAULT_SEO = {
  titleTemplate: '%s | Cellular Goods',
  defaultTitle: 'Cellular Goods',
  description: '',
  openGraph: {
    url: 'https://www.cellular-goods.com',
    title: 'Cellular Goods',
    description: '',
    images: [
      {
        url: 'https://www.cellular-goods.com/site/share_image.jpg',
        width: 800,
        height: 600,
      },
    ],
    site_name: 'Cellular Goods',
  },
  twitter: {
    site: '@CellularGoods',
    handle: '@CellularGoods',
    cardType: 'summary_large_image',
  },
}

export const createSeoFromPageMeta = (
  obj?: Maybe<Omit<Sanity.PageMeta, '_type'>> & {
    slug?: string
  }
) => {
  if (!obj) {
    return DEFAULT_SEO
  } else {
    const { title, description, image, slug } = obj

    return {
      ...DEFAULT_SEO,
      title: title ?? undefined,
      description: description ?? DEFAULT_SEO.defaultTitle,
      openGraph: {
        ...DEFAULT_SEO.openGraph,
        title: title
          ? `${DEFAULT_SEO.openGraph.title} | ${title}`
          : DEFAULT_SEO.openGraph.title,
        description: description ?? DEFAULT_SEO.openGraph.description,
        url: slug
          ? `https://www.cellular-goods.com${slug}`
          : 'https://www.cellular-goods.com',
        images:
          image && image.asset && image.asset.url
            ? [
                {
                  url: image.asset.url,
                  width: 800,
                  height: 600,
                },
              ]
            : DEFAULT_SEO.openGraph.images,
      },
    }
  }
}
