import Script from 'next/script'

export const TrackingCriteo = () => {
  if (!process.env.NEXT_PUBLIC_CRITEO_APP_ID) {
    return null
  }

  return (
    <Script
      type="text/javascript"
      src={`//dynamic.criteo.com/js/ld/ld.js?a=${process.env.NEXT_PUBLIC_CRITEO_APP_ID}`}
      async
    />
  )
}
