import { useState, FC, useEffect } from 'react'
import styled from 'styled-components'
import { Plus, Minus } from 'phosphor-react'
import { animated, useSpring } from '@react-spring/web'
import useMeasure from 'react-use-measure'
import clsx from 'clsx'
import { ResizeObserver } from '@juggle/resize-observer'

import { usePrevious } from '@cellulargoods/hooks'
import { Sanity } from '@cellulargoods/types'

import { RichText } from '../Text/RichText'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_18_400,
  getFontStyles,
} from '@cellulargoods/styles'

export type AccordionProps = {
  item: Sanity.FaqAccordionItem
  className?: string
  forceOpen?: boolean
  labelFontStyle?: string
  isWithProduct?: boolean
  initiallyOpen?: boolean
}

/**
 * Originally built for the FAQItem, if an item prop
 * does not have a question or text it will fallback
 * to showing the children element inside the drawer
 */
export const Accordion: FC<AccordionProps> = ({
  item,
  className,
  children,
  forceOpen,
  labelFontStyle = FONT_STYLE_SOFIA_18_400,
  isWithProduct = false,
  initiallyOpen,
}) => {
  const prevForceOpen = usePrevious(forceOpen)
  const { faq_reference: ref, label, text } = item
  const { answer, question } = ref ?? {}

  const [isOpen, setIsOpen] = useState(false)

  const [textRef, { height: textHeight }] = useMeasure({
    polyfill: ResizeObserver,
  })

  const handleClick = () => {
    if (!forceOpen) {
      setIsOpen((state) => !state)
    }
  }

  const [{ height }, api] = useSpring(
    () => ({
      from: {
        height: 0,
      },
    }),
    []
  )

  useEffect(() => {
    /**
     * this will run when forceOpen changes,
     * the use case for implementing this is the footer
     * where they're accordions on mobile but on desktop
     * they're lists, but instead of renderering two lists,
     * its easier to expand when desktop.
     */
    api.start({
      to: {
        height: forceOpen ? textHeight : 0,
      },
      immediate:
        (forceOpen && forceOpen === prevForceOpen) ||
        (!forceOpen && forceOpen === prevForceOpen),
      onRest: () => {
        if (!forceOpen && prevForceOpen) {
          setIsOpen(false)
        }
      },
    })
  }, [forceOpen, prevForceOpen, api, textHeight])

  useEffect(() => {
    /**
     * this is our regular handler for onClick stuff.
     */
    if (!forceOpen) {
      api.start({
        to: {
          height: isOpen ? textHeight : 0,
        },
      })
    }
  }, [api, isOpen, textHeight, forceOpen])

  useEffect(() => {
    if (initiallyOpen) {
      api.start({
        to: {
          height: textHeight,
        },
        immediate: true,
      })
      setIsOpen(true)
    }
  }, [initiallyOpen, api, textHeight])

  return (
    <AccordionContainer
      className={clsx(className, isWithProduct && 'accordion--with-product')}
    >
      <AccordionButton
        aria-label={`${isOpen ? 'Open' : 'Close'} accordion`}
        type="button"
        onClick={handleClick}
        tabIndex={forceOpen ? -1 : 0}
        fontStyle={labelFontStyle}
      >
        <span>{question ?? label}</span>
        {!isOpen && !forceOpen ? (
          <Plus size={isWithProduct ? 20 : 32} color="black" weight="thin" />
        ) : !forceOpen ? (
          <Minus size={isWithProduct ? 20 : 32} color="black" weight="thin" />
        ) : null}
      </AccordionButton>
      <AccordionText
        style={{
          height,
        }}
      >
        <div ref={textRef}>
          {question || text ? (
            <AccordionTextInner>
              {question ? <RichText blocks={answer} /> : text}
            </AccordionTextInner>
          ) : (
            children
          )}
        </div>
      </AccordionText>
    </AccordionContainer>
  )
}

const AccordionButton = styled.button<{
  fontStyle: string
}>`
  ${(props) => getFontStyles(props.fontStyle)}
  min-height: 8rem;
  border: none;
  background: none;
  text-align: left;
  padding: 2.8rem 0 2.9rem 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;

  & > span {
    margin-right: 2rem;
  }

  & > svg {
    flex: 0 0 32px;
  }
`

const AccordionText = styled(animated.div)`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  overflow: hidden;
  max-width: 55rem;
`

const AccordionTextInner = styled.div`
  padding-bottom: 2rem;
`

const AccordionContainer = styled.div`
  padding: 0 2rem;
  border-top: solid 1px rgba(0, 0, 0, 0.2);

  &:last-child {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  }

  &.accordion--with-product {
    padding: 0;
    border-top: solid 1px var(--black);

    &:last-child {
      border-bottom: solid 1px var(--black);
    }

    ${AccordionButton} {
      padding: 1rem 0;
      min-height: 4rem;
    }
  }
`
