import { NextPage } from 'next'
import { ReactElement, ReactNode } from 'react'
import { ApolloProvider } from '@apollo/client'
import { DefaultSeo } from 'next-seo'
import { createGlobalStyle } from 'styled-components'
import { AppProps } from 'next/app'
import { CSS_GLOBAL } from '@cellulargoods/styles'

import { DEFAULT_SEO } from '@cellulargoods/core'
import { useApollo } from 'apollo/client'

import { TrackingOptimize } from 'components/Tracking/TrackingOptimize'
import { TrackingHead } from 'components/Tracking/TrackingHead'
import { WidgetIubenda } from 'components/Widgets/WidgetIubenda'
import { WidgetMarker } from 'components/Widgets/WidgetMarker'
import { TrackingCriteo } from 'components/Tracking/TrackingCriteo'
import { TrackingHotjar } from 'components/Tracking/TrackingHotjar'
import { TrackingPinterest } from 'components/Tracking/TrackingPinterest'
import { TrackingRakuten } from 'components/Tracking/TrackingRakuten'
import { WidgetGorgias } from 'components/Widgets/WidgetGorgias'
import { TrackingTwitter } from 'components/Tracking/TrackingTwitter'
import { TrackingKlarna } from 'components/Tracking/TrackingKlarna'

export const GlobalStyle = createGlobalStyle`
  ${CSS_GLOBAL}
`

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <TrackingOptimize />
      <TrackingKlarna />
      <TrackingHead />
      <WidgetIubenda />
      <WidgetMarker />
      <TrackingCriteo />
      <TrackingHotjar />
      <TrackingPinterest />
      <TrackingRakuten />
      <DefaultSeo {...DEFAULT_SEO} />
      <GlobalStyle />

      <ApolloProvider client={apolloClient}>
        {getLayout(<Component {...pageProps} />)}
      </ApolloProvider>
      <WidgetGorgias />
      <TrackingTwitter />
    </>
  )
}

export default App
