import styled from 'styled-components'

import { FONT_STYLE_SOFIA_16_400, getFontStyles } from '@cellulargoods/styles'

import logError from './logError'

export interface TextProps {
  children?: React.ReactNode
  fontStyle?: string
  tag?: string | React.ComponentType<any>
  href?: string
  className?: string
  injectHtml?: string
  testId?: string
}

export const Text = ({
  children,
  className,
  tag = 'p',
  href,
  fontStyle = FONT_STYLE_SOFIA_16_400,
  injectHtml,
  testId,
}: TextProps) => {
  const extraProps: {
    href?: string
  } = {}
  if (tag === 'a') {
    if (!href) logError(children)
    extraProps.href = href
  }

  return (
    <Element
      className={className}
      as={tag}
      fontStyle={fontStyle}
      data-testid={testId}
      {...extraProps}
      {...(injectHtml
        ? { dangerouslySetInnerHTML: { __html: injectHtml } }
        : {})}
    >
      {children}
    </Element>
  )
}

const Element = styled.p<{
  as?: any
  fontStyle: string
}>`
  ${(props) => getFontStyles(props.fontStyle)}
  color: var(--darkGrey1);
  white-space: pre-line;

  ${({ as }) =>
    as === 'a' &&
    `
    text-decoration: underline;
    display: block;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
  `}
`
