import Link from 'next/link'
import { ArrowElbowDownRight } from 'phosphor-react'
import styled from 'styled-components'

import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export interface SectionListTitleProps {
  href?: string
  title: string
  comingSoon?: boolean
  onClick?: () => void
  tabIndex?: number
}

const SectionListTitle = ({
  href,
  title,
  comingSoon,
  onClick,
  tabIndex = 0,
}: SectionListTitleProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <StyledList>
      <ArrowElbowDownRight size={20} />
      {href && !comingSoon ? (
        <Link href={href} passHref>
          <StyledLink tabIndex={tabIndex} onClick={handleClick}>
            {title}
          </StyledLink>
        </Link>
      ) : comingSoon ? (
        <TitleContainer>
          <StyledLink>{title}</StyledLink>
          <ComingSoon>{`Coming Soon`}</ComingSoon>
        </TitleContainer>
      ) : null}
    </StyledList>
  )
}

export default SectionListTitle

const StyledList = styled.div`
  display: flex;
  align-items: center;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
    margin-bottom: 2rem;
  }

  & > svg {
    position: relative;
    top: -2px;
  }
`

const StyledLink = styled.a`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  color: var(--darkGrey1);
  text-decoration: none;
  margin-left: 1rem;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const ComingSoon = styled.span`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)};
  color: var(--electricBlue);
  margin-left: 1rem;
`
