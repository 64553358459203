import { useState, useRef } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'
import { X } from 'phosphor-react'
import { animated, useSpring } from '@react-spring/web'
import FocusLock from 'react-focus-lock'
import { Sanity, NonNullSkipArray, PickType } from '@cellulargoods/types'
import {
  useWindowResize,
  useClickOutside,
  useKeyPress,
  useDisableScroll,
} from '@cellulargoods/hooks'
import {
  FONT_STYLE_SOFIA_11_500,
  FONT_STYLE_SOFIA_16_500,
  WIDTHS,
  aspectRatio,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Text, Heading } from '../Text'
import { Button, ButtonTypes, IconButton } from '../Button'

import { createSlug } from '../../helpers/links'
import { slugify } from '../../helpers/strings'

import { SlugType } from '../../references/constants'
import { generateSrcSetSizes } from '../Image'
import { Media } from '../Media'
import useMeasure from 'react-use-measure'
import mergeRefs from 'react-merge-refs'
import { ReviewStarRating } from '../Reviews'

export type ModalUGCReadMoreProps = Partial<
  NonNullSkipArray<PickType<Sanity.UGCCarouselWithYotpo, 'images'>>
> & {
  isActive?: boolean
  onCloseClick?: () => void
  imageMode?: 'yotpo' | 'sanity'
  sanityImage?: Sanity.MediaImage
}

const HEIGHT_PERCENTAGE = 0.64

export const ModalUGCReadMore = ({
  lowResImage,
  imageUrl,
  source,
  username,
  product,
  profilePhoto,
  stars,
  content,
  isActive,
  onCloseClick,
  imageMode = 'yotpo',
  sanityImage,
}: ModalUGCReadMoreProps) => {
  const modalRef = useRef<HTMLDivElement>(null!)
  const [isDisplaying, setIsDisplaying] = useState(Boolean(isActive))
  const [measureRef, { height: elementHeight }] = useMeasure()

  const styles = useSpring({
    opacity: isActive ? 1 : 0,
    onStart: () => {
      if (isActive) {
        setIsDisplaying(true)
      }
    },
    onRest: () => {
      if (!isActive) {
        setIsDisplaying(false)
      }
    },
  })

  const { width, height } = useWindowResize()

  const handleCloseClick = () => {
    if (onCloseClick) {
      onCloseClick()
    }
  }

  useClickOutside(
    {
      current: [modalRef.current],
    },
    handleCloseClick
  )

  useKeyPress('Escape', handleCloseClick)

  useDisableScroll(isDisplaying)

  return (
    <ModalUGCDialog
      open={isDisplaying}
      style={{
        ...styles,
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <FocusLock disabled={!isDisplaying}>
        <AspectContainer>
          <ModalUGCContainer ref={mergeRefs([modalRef, measureRef])}>
            <ModalUGCCloseButton
              ariaLabel="Close dialog"
              onClick={handleCloseClick}
            >
              <X size={24} color="var(--black)" weight="thin" />
            </ModalUGCCloseButton>
            <ModalUGCImage
              style={{
                flexBasis: width >= WIDTHS.desktop ? elementHeight : '',
              }}
            >
              <ModalUGCMedia>
                {imageMode === 'yotpo' ? (
                  <Image
                    blurDataURL={lowResImage}
                    placeholder="blur"
                    src={imageUrl!}
                    objectFit="cover"
                    layout="fill"
                    draggable={false}
                    sizes={generateSrcSetSizes(['100vw', null, '50vw', null])}
                  />
                ) : sanityImage ? (
                  <Media
                    {...sanityImage}
                    objectFit="contain"
                    sizes={['100vw', null, '50vw', null]}
                  />
                ) : null}
              </ModalUGCMedia>
              {product && (
                <Link
                  passHref
                  href={createSlug(SlugType.FUNCTIONAL_PDP, [
                    product.category,
                    slugify(product.name),
                  ])}
                >
                  <ModalUGCLabel>
                    <ModalUGCText
                      tag="span"
                      fontStyle={FONT_STYLE_SOFIA_11_500}
                    >
                      {product.name}
                    </ModalUGCText>
                  </ModalUGCLabel>
                </Link>
              )}
            </ModalUGCImage>
            <ModalUGCContent>
              <ModalUGCHead>
                <div>
                  {profilePhoto ? (
                    <ModalUGCPhoto>
                      <img
                        src={profilePhoto}
                        alt={`${username} profile photo`}
                      />
                    </ModalUGCPhoto>
                  ) : null}
                  {source === 'instagram' ? (
                    <Button
                      tag="a"
                      href={`https://www.instagram.com/${username}`}
                      isExternal
                      variant={ButtonTypes.TERTIARY}
                    >
                      <Heading tag="h3" fontStyle={FONT_STYLE_SOFIA_16_500}>
                        {`@${username}`}
                      </Heading>
                    </Button>
                  ) : (
                    <Heading tag="h3" fontStyle={FONT_STYLE_SOFIA_16_500}>
                      {username}
                    </Heading>
                  )}
                </div>
                {stars ? <UGCStars rating={stars} /> : null}
              </ModalUGCHead>
              <ModalUGCCopy>{content}</ModalUGCCopy>
            </ModalUGCContent>
          </ModalUGCContainer>
        </AspectContainer>
      </FocusLock>
    </ModalUGCDialog>
  )
}

const ModalUGCDialog = styled(animated.dialog)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border: none;
  padding: 0;
  margin: 0;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);

  &:not([open]) {
    display: none;
  }

  & > div {
    height: inherit;
    display: flex;
    align-items: center;
  }

  ${MEDIA_QUERIES.desktopUp} {
    & > div {
      height: ${HEIGHT_PERCENTAGE * 100}%;
      width: 74%;
      max-width: 170rem;
    }
  }
`

const AspectContainer = styled.div`
  height: 100%;
  width: 100%;

  ${MEDIA_QUERIES.desktopUp} {
    width: unset;
    height: unset;
    flex: 1;
    ${aspectRatio(968, 526, true)};
  }
`

const ModalUGCContainer = styled.div`
  width: 100%;
  height: inherit;
  background-color: var(--softGrey);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  max-width: 47rem;

  @media (min-width: 470px) {
    max-height: calc(100vh - 6rem);
  }

  ${MEDIA_QUERIES.desktopUp} {
    position: absolute;
    flex-direction: row;
    max-width: unset;
    max-height: unset;
    height: 100%;
    overflow: hidden;
  }
`

const ModalUGCCloseButton = styled(IconButton)`
  position: absolute;
  top: 2.4rem;
  right: 2rem;
  z-index: 10;
  padding: 0.8rem;

  ${MEDIA_QUERIES.desktopUp} {
    top: 1rem;
    right: 1rem;
  }
`

const ModalUGCImage = styled.div`
  position: relative;
  width: 100%;

  ${MEDIA_QUERIES.desktopUp} {
    flex: 1 0;
  }
`

const ModalUGCMedia = styled.div`
  ${aspectRatio(1, 1, false)};
`

const ModalUGCLabel = styled.a`
  padding: 1rem;
  display: inline-block;
  background-color: var(--darkElectricBlue);
  border-radius: 0.1rem;
  text-decoration: none;
  position: absolute;
  bottom: 1rem;
  left: 1rem;

  & > * {
    color: var(--white);
  }
`

const ModalUGCText = styled(Text)`
  position: relative;
  bottom: 0.1rem;
`

const ModalUGCContent = styled.div`
  padding: 2rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.05);
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #1d1d1d;
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding: 5.4rem 2rem;
    height: 100%;
    max-height: 100%;
    width: 100%;
  }
`

const ModalUGCHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > h3 {
      position: relative;
      bottom: 0.2rem;
    }
  }
`

const ModalUGCPhoto = styled.div`
  ${aspectRatio(1, 1)};

  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  overflow: hidden;

  margin-right: 1rem;

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`

const UGCStars = styled(ReviewStarRating)`
  margin-top: 0.5rem;
`

const ModalUGCCopy = styled(Text)`
  margin-top: 2rem;
`
