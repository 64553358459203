import sanityClient from '@sanity/client'
import urlBuilder from '@sanity/image-url'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'

const PROJECT_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID
const DATASET_ID = process.env.NEXT_PUBLIC_SANITY_DATASET

export const urlFor = (source: SanityImageSource) => {
  if (!PROJECT_ID || !DATASET_ID) {
    console.error('no project id and/or dataset id set for Sanity')
    return ''
  } else {
    return urlBuilder({
      projectId: PROJECT_ID,
      dataset: DATASET_ID,
    })
      .image(source)
      .url()
  }
}

export const sanityClientRead = sanityClient({
  projectId: PROJECT_ID,
  dataset: DATASET_ID,
  apiVersion: 'v2021-06-07',
  useCdn: true, // `false` if you want to ensure fresh Data
})

export const createSanityClientRead = (preview?: boolean) =>
  sanityClient({
    projectId: PROJECT_ID,
    dataset: DATASET_ID,
    apiVersion: 'v2021-06-07',
    useCdn: true,
    token: preview ? process.env.SANITY_CMS_TOKEN : undefined,
  })

export const createClientWrite = (staging = false) => {
  return sanityClient({
    projectId: PROJECT_ID,
    dataset: staging ? 'staging' : DATASET_ID,
    apiVersion: 'v2021-06-07',
    token: process.env.SANITY_CMS_TOKEN, // or leave blank for unauthenticated usage
  })
}

export type { SanityDocumentStub } from '@sanity/client'
