import { HeaderTextSizes } from '../references/constants'

import {
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_28_500,
  FONT_STYLE_SOFIA_32_500,
  FONT_STYLE_SOFIA_40_500,
  FONT_STYLE_SOFIA_48_500,
} from '@cellulargoods/styles'

export const getHeaderFontStyles = (size: HeaderTextSizes) => {
  switch (size) {
    case HeaderTextSizes.LARGE:
      return {
        headerFontStyle: FONT_STYLE_SOFIA_48_500,
        subtitleFontStyle: FONT_STYLE_SOFIA_28_500,
      }
    case HeaderTextSizes.MEDIUM:
      return {
        headerFontStyle: FONT_STYLE_SOFIA_40_500,
        subtitleFontStyle: FONT_STYLE_SOFIA_22_500,
      }
    case HeaderTextSizes.SMALL:
    default:
      return {
        headerFontStyle: FONT_STYLE_SOFIA_32_500,
        subtitleFontStyle: FONT_STYLE_SOFIA_18_500,
      }
  }
}
