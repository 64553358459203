import { MutableRefObject, useEffect } from 'react'

export const useClickOutside = <TElement extends HTMLElement>(
  ref: MutableRefObject<Array<TElement | null>>,
  cb: () => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: PointerEvent) => {
      if (ref.current.length > 0 && cb) {
        const doesNotContainTarget = ref.current.map(
          (x) => x && !x.contains(event.target as Node)
        )

        if (!doesNotContainTarget.includes(false)) {
          cb()
        }
      }
    }
    // Bind the event listener
    document.addEventListener('pointerdown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('pointerdown', handleClickOutside)
    }
  }, [ref, cb])
}
