import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import { useWindowResize } from '@cellulargoods/hooks'

import { Media } from '../Media'
import { Heading, Text } from '../Text'
import { Button, ButtonSecondaryTheme, ButtonTypes } from '../Button'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_32_500,
  WIDTHS,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export type ContentFunctionalImageProps =
  Sanity.Keyed<Sanity.FunctionalImage> & {
    className?: string
  }

export const ContentFunctionalImage = ({
  media,
  CTAButton: cta,
  title,
  text,
}: ContentFunctionalImageProps) => {
  const { height, width } = useWindowResize()

  return (
    <FunctionalContainer
      style={{
        height: height >= WIDTHS.desktop ? `${height}px` : 'auto',
      }}
    >
      {media && media.length > 0 && (
        <FunctionalImage>
          <Media {...media[0]} sizes={['100vw']} />
        </FunctionalImage>
      )}
      <FunctionalContent>
        {title && (
          <FunctionalTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
            {title}
          </FunctionalTitle>
        )}
        <div>
          {text && (
            <FunctionalText fontStyle={FONT_STYLE_SOFIA_16_400}>
              {text}
            </FunctionalText>
          )}
          {cta && cta.url && cta.label && (
            <FunctionalCta
              variant={ButtonTypes.SECONDARY}
              tag="a"
              href={cta.url}
              isExternal={Boolean(cta.isExternal)}
              theme={
                width >= WIDTHS.desktop
                  ? ButtonSecondaryTheme.WHITE
                  : ButtonSecondaryTheme.BLACK
              }
            >
              {cta.label}
            </FunctionalCta>
          )}
        </div>
      </FunctionalContent>
    </FunctionalContainer>
  )
}

const FunctionalContainer = styled.section`
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    height: 100vh;
  }
`

const FunctionalImage = styled.div`
  width: 100%;
  min-height: 60rem;
  position: relative;

  ${MEDIA_QUERIES.desktopUp} {
    height: 100%;
  }
`

const FunctionalContent = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 6rem 4rem;
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const FunctionalTitle = styled(Heading)`
  ${MEDIA_QUERIES.desktopUp} {
    color: var(--white);
  }
`

const FunctionalText = styled(Text)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    color: var(--white);
  }
`

const FunctionalCta = styled(Button)`
  margin-top: 3rem;
`
