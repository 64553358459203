import { NonNullSkipArray, PickType, Sanity } from '@cellulargoods/types'

type Response<Tdata> = {
  status: {
    code: number
    message: string
  }
  response: Tdata
}

export type GetAlbumByNameResponse = Response<{
  images?: GetAlbumByNameImage[]
}>

export type GetProductBottomLineResponse = Response<{
  bottomline: {
    average_score: number
    total_reviews: number
  }
}>

export type ReviewCustomField = {
  title: string
  form_id: number
  field_type: CustomFieldTypes
  value: string | number
}

export type GetProductReviews = Response<{
  pagination: {
    page: number
    per_page: number
    total: number
  }
  bottomline: {
    total_review: number
    average_score: number
  }
  products: Array<ReviewProduct | null>
  reviews: {
    id: number
    score: number
    content: string
    title: string
    created_at: string
    custom_fields: Record<string, ReviewCustomField>
    user: {
      display_name: string
      social_image: string | null
    }
  }[]
}>

export type GetSiteReviews = Response<{
  pagination: {
    page: number
    per_page: number
    total: number
  }
  bottomline: {
    total_review: number
    average_score: number
  }
  reviews: {
    id: number
    score: number
    content: string
    title: string
    created_at: string
    custom_fields: Record<string, ReviewCustomField>
    product_id: number
    user: {
      display_name: string
      social_image: string | null
    }
  }[]
}>

export type Review = {
  id: number
  score: number
  content: string
  title: string
  createdAt: string
  customFields?: Record<string, ReviewCustomField>
  user: {
    name: string
    image: string | null
  }
}

export type ProductReviews = ProdBottomLine & {
  product: ReviewProduct | null
  reviews: Review[]
}

export type SiteReviews = Omit<ProductReviews, 'product'>

export type CategoryReviews = ProdBottomLine & {
  reviews: Array<
    Review & {
      product: ReviewProduct | null
    }
  >
}

export type ProdBottomLine = {
  score: number
  totalReviews: number
}

export type GetAlbumByNameImageInstagram = {
  source: 'instagram'
  image_id: string
  low_resolution_image_url: string
  original_image_url: string
  album_name: string
  tagged_products: {
    id: number
    name: string
    domain_key: string
    product_link: string
    product_url: string
    image_url: string
    score: number | null
    reviews_count: number | null
  }[]
  post: {
    profile_picture: string
    username: string
    location_name: string
    created_time: string
    hashtags: string[]
    content: string
    votes_up: number
    votes_down: number
  }
}

export type ReviewProduct = {
  domain_key: string
  name: string
  product_url?: string
  product_link: string
  image_url: string
  image?: Sanity.MediaImage
}

export type GetAlbumByNameImageReview = {
  source: 'review'
  image_id: string
  low_resolution_image_url: string
  original_image_url: string
  album_name: string
  review: {
    id: number
    review_type_id: number
    rejected: boolean
    title: string
    content: string
    score: number
    created_at: string
    verified_buyer: number
    archived: number
    deleted: number
    votes_up: number
    votes_down: number
    user: {
      display_name: string
      social_image: string | null
      email: string
    }
  }
  product: ReviewProduct
}

export type GetAlbumByNameImageManual = {
  source: 'manual_upload'
  image_id: string
  low_resolution_image_url: string
  original_image_url: string
  album_name: string
  tagged_products: {
    id: number
    name: string
    domain_key: string
    product_link: string
    product_url: string
    image_url: string
    score: number | null
    reviews_count: number | null
  }[]
  post: {
    username: string
    created_time: string
    content: string
    votes_up: number
    votes_down: number
  }
}

export type GetAlbumByNameImage =
  | GetAlbumByNameImageManual
  | GetAlbumByNameImageInstagram
  | GetAlbumByNameImageReview

export type PhotoBase = {
  source: 'manual_upload' | 'instagram' | 'review'
  imageId: string
  lowResImage: string
  imageUrl: string
  username: string | null
  profilePhoto: string | null
  content: string | null
  stars: number | null
}

export type PhotoAlbum = Array<
  PhotoBase & {
    product:
      | (NonNullSkipArray<
          PickType<GetAlbumByNameImageManual, 'tagged_products'>
        > & { category: string })
      | (PickType<GetAlbumByNameImageReview, 'product'> & {
          category: string
        })
      | null
  }
>

export type GetReviewOptions = {
  stars: number
  count: number
  pageNumber?: number
}

export enum CustomFieldTypes {
  CustomerFreeText = 'CustomerFreeText',
  ProductFreeText = 'ProductFreeText',
  MultipleChoice = 'MultipleChoice',
  Rating = 'Rating',
  SingleChoice = 'SingleChoice',
}

export type SearchReviewsResults = Review[]

export type GetSearchResultsResponse = {
  success: boolean
  results: Review[]
  error?: unknown
}
