import Script from 'next/script'

export const TrackingTwitter = () => (
  <>
    <Script
      strategy="afterInteractive"
      src="//static.ads-twitter.com/oct.js"
      type="text/javascript"
    />
    <Script
      type="text/javascript"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: /* js */ `
          if(window.twttr && window.twttr.conversion && window.twttr.conversion.trackPid){
            window.twttr.conversion.trackPid('o9hu0', { tw_sale_amount: 0, tw_order_quantity: 0 })
          }
        `,
      }}
    />
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src="https://analytics.twitter.com/i/adsct?txn_id=o9hu0&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0"
      />
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        alt=""
        src="//t.co/i/adsct?txn_id=o9hu0&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0"
      />
    </noscript>
  </>
)
