import { useMemo } from 'react'
import imageUrlBuilder from '@sanity/image-url'
import NextImage, { ImageLoaderProps } from 'next/image'
import { Sanity } from '@cellulargoods/types'
import { WIDTHS } from '@cellulargoods/styles'

import { sanityClientRead } from '../../lib/sanity'

import { findLastNonNullValue } from '../../helpers/arrays'

export type ImageLayout = 'fill' | 'fixed' | 'intrinsic' | 'responsive'

export type SizesArray = [
  largeMobile: string | null,
  tablet?: string | null,
  smallDesktop?: string | null,
  largeDesktop?: string | null
]

export type Props = {
  image: Sanity.MediaImage | Sanity.MediaSvg
  layout?: ImageLayout
  objectFit?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectFit']
  sizes?: string | SizesArray
}

export const Image = ({
  image,
  layout = 'intrinsic',
  objectFit = 'cover',
  sizes = '100vw',
}: Props) => {
  const imageProps = useSanityImage(image)

  if (!imageProps || imageProps.src === '') {
    console.warn('Missing source from image')
    return null
  }

  const filteredImageProps = {
    ...imageProps,
  }
  if (layout === 'fill') {
    delete filteredImageProps.width
    delete filteredImageProps.height
  }

  return (
    <NextImage
      {...filteredImageProps}
      layout={layout}
      objectFit={objectFit}
      sizes={generateSrcSetSizes(sizes)}
      draggable={false}
    />
  )
}

export const generateSrcSetSizes = (sizes?: string | SizesArray) => {
  if (!sizes) {
    return undefined
  }

  if (typeof sizes === 'string') {
    return sizes
  }

  if (sizes.length === 1 && sizes[0] !== null) {
    return sizes[0]
  }

  return sizes
    .map((val, i) => {
      if (i === sizes.length - 1) {
        return sizes[i]
      }

      let current = val
      if (val === null) {
        current = findLastNonNullValue(sizes, i)
      }

      return `(max-width: ${Object.values(WIDTHS).slice(4, 8)[i]}px) ${current}`
    })
    .join(', ')
}

export const useSanityImage = (image: Sanity.MediaImage | Sanity.MediaSvg) => {
  const imageProps = useMemo(() => {
    if (!image.asset) {
      return null
    }

    const builder = imageUrlBuilder(sanityClientRead)
    const urlFor = builder.image(image)

    const loader = ({ quality, width }: ImageLoaderProps) => {
      return (
        urlFor
          .width(width)
          .auto('format')
          .quality(quality || 75)
          .toString() || ''
      )
    }

    return {
      blurDataURL:
        urlFor //
          .auto('format')
          .blur(50)
          .quality(30)
          .width(64)
          .toString() || '',
      height: image.asset?.metadata?.dimensions?.height || undefined,
      alt: image.asset?.altText ?? '',
      loader,
      placeholder: 'blur' as const,
      src:
        urlFor //
          .auto('format')
          .quality(20)
          .width(800)
          .toString() || '',
      width: image.asset?.metadata?.dimensions?.width || undefined,
    }
  }, [image])

  return imageProps
}
