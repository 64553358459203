import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Text, Heading } from '../Text'
import { Image } from '../Image'

import {
  MEDIA_QUERIES,
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_14_400,
} from '@cellulargoods/styles'

export type BenefitsBannerProps = Sanity.Keyed<Sanity.BenefitsBanner>

export const BenefitsBanner = ({
  benefitsBannerItems: items,
  hasBackground = false,
}: BenefitsBannerProps) => {
  return (
    <Banner hasBackground={Boolean(hasBackground)}>
      <BannerList $columns={items?.length ?? 1}>
        {items &&
          items.map((_item) => {
            const item = _item
            return (
              <BannerItem key={item._key}>
                <BannerIcon>
                  {item.icon && (
                    <Image
                      image={item.icon}
                      layout="fill"
                      objectFit="contain"
                      sizes={['20vw', null, null, '25vw']}
                    />
                  )}
                </BannerIcon>
                <BannerTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_18_500}>
                  {item.title}
                </BannerTitle>
                <BannerCopy fontStyle={FONT_STYLE_SOFIA_14_400}>
                  {item.text}
                </BannerCopy>
              </BannerItem>
            )
          })}
      </BannerList>
    </Banner>
  )
}

const Banner = styled.section<{
  hasBackground: boolean
}>`
  background-color: ${(props) =>
    !props.hasBackground ? 'transparent' : 'var(--softGrey)'};

  ${MEDIA_QUERIES.desktopUp} {
    padding: 4rem 1rem;
  }
`

const BannerList = styled.ul<{ $columns: number }>`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;

    & > li {
      flex-basis: ${(props) => 100 / props.$columns}%;
    }
  }
`

const BannerItem = styled.li`
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 2.2rem;
    margin: 4rem 0;
  }
`

const BannerIcon = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--softGrey);
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  ${MEDIA_QUERIES.desktopUp} {
    width: 8rem;
    height: 8rem;
  }
`

const BannerTitle = styled(Heading)`
  margin-bottom: 1rem;
  text-align: center;
`

const BannerCopy = styled(Text)`
  text-align: center;
`
