import Script from 'next/script'

export const TrackingPinterest = () => {
  if (!process.env.NEXT_PUBLIC_PINTEREST_TAG_ID) {
    return null
  }

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: /* js */ `
              !function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
              Array.prototype.slice.call(arguments))};var
              n=window.pintrk;n.queue=[],n.version="3.0";var
              t=document.createElement("script");t.async=!0,t.src=e;var
              r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
              pintrk('load', '${process.env.NEXT_PUBLIC_PINTEREST_TAG_ID}');

          `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{
            display: 'none',
          }}
          alt=""
          src={`https://ct.pinterest.com/v3/?tid=${process.env.NEXT_PUBLIC_PINTEREST_TAG_ID}&event=init&noscript=1`}
        />
      </noscript>
    </>
  )
}
