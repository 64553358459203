import { MouseEvent } from 'react'
import styled from 'styled-components'
import { ArrowElbowDownRight } from 'phosphor-react'

import { Text } from '../Text/Text'

import { ButtonGenericProps } from './types'

import { FONT_STYLE_SOFIA_16_500, getFontStyles } from '@cellulargoods/styles'

export enum ButtonSecondaryTheme {
  BLACK = 'black',
  WHITE = 'white',
}

export type ButtonSecondaryProps = ButtonGenericProps & {
  theme?: ButtonSecondaryTheme
}

export const ButtonSecondary = ({
  theme = ButtonSecondaryTheme.BLACK,
  children,
  label,
  onClick,
  href,
  tag = 'button',
  className,
  isExternal,
  type = 'button',
  ...restProps
}: ButtonSecondaryProps) => {
  const anchorProps = {
    href,
    rel: isExternal ? 'noopener noreferrer' : '',
    target: isExternal ? '_blank' : '',
  }

  const handleOnClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <>
      {/* @ts-expect-error no idea, and got no time. – https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117 */}
      <Button
        type={type}
        as={tag}
        className={className}
        onClick={handleOnClick}
        {...(tag === 'a' ? anchorProps : {})}
        {...restProps}
      >
        <ArrowElbowDownRight size={20} color={theme} />
        <ButtonText tag="span" theme={theme}>
          {label ?? children}
        </ButtonText>
      </Button>
    </>
  )
}

const Button = styled.button<{
  as: string
}>`
  display: inline-flex;
  text-decoration: none;
  justify-content: flex-start;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  & > svg {
    margin-top: 0.2rem;
  }
`

const ButtonText = styled(Text)<{
  theme: ButtonSecondaryTheme
}>`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  margin-left: 1rem;
  color: ${(props) => `var(--${props.theme})`};
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0.6rem;
    z-index: -1;
    right: 0;
    background-color: var(--lightBrown);
    width: 0%;
    height: 1.2em;
    transition: width 200ms ease-out;
  }

  @media (hover: hover) {
    &:hover:before {
      right: unset;
      left: 0;
      width: 100%;
    }
  }
`
