import { atom, useAtom } from 'jotai'
import { SetStateAction } from 'react'

const ukOnlyDisclaimerAtom = atom<string | any>(
  'Please note that this product does not ship to the US, you can read more information here.'
)

export const useUKDisclaimer = () => {
  const [ukOnlyDisclaimer, setUkOnlyDisclaimer] = useAtom(ukOnlyDisclaimerAtom)
  return [ukOnlyDisclaimer, setUkOnlyDisclaimer] as [
    string | any,
    (update: SetStateAction<string | any>) => void
  ]
}
