import { useState } from 'react'
import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { TeamMemberCard } from '../Cards'
import { SideSheet } from '../SideSheets'
import { Image } from '../Image'
import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'

import { TextRenderer } from '@cellulargoods/core'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_22_500,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

type InitialStateType = {
  sideSheetActive: boolean
  activeTeamMember: Sanity.TeamMember | null
}
const initialState: InitialStateType = {
  sideSheetActive: false,
  activeTeamMember: null,
}

export type TeamGridProps = Sanity.Keyed<Sanity.TeamGrid>

export const TeamGrid = ({ showBio, items }: TeamGridProps) => {
  const [state, setState] = useState(initialState)

  const handleReadMoreClick = (item: Sanity.TeamMember) => {
    setState({
      sideSheetActive: true,
      activeTeamMember: item,
    })
  }

  const handleSideSheetClose = () => {
    setState((s) => ({
      ...s,
      sideSheetActive: false,
    }))
  }

  const { activeTeamMember } = state
  return (
    <>
      <SideSheet
        isActive={state.sideSheetActive}
        onCloseClick={handleSideSheetClose}
      >
        {activeTeamMember && (
          <>
            {activeTeamMember.image && (
              <ImageWrap>
                <Image
                  image={activeTeamMember.image}
                  layout="fill"
                  sizes={['100vw', null, null, '33vw']}
                />
              </ImageWrap>
            )}
            <Heading fontStyle={FONT_STYLE_SOFIA_22_500}>
              {activeTeamMember.name}
            </Heading>
            <JobTitle fontStyle={FONT_STYLE_SOFIA_16_400}>
              {activeTeamMember.jobTitle}
            </JobTitle>
            {activeTeamMember.text ? (
              <Bio>
                <TextRenderer
                  blocks={activeTeamMember.text}
                  bodyFontStyle={FONT_STYLE_SOFIA_16_400}
                />
              </Bio>
            ) : null}
            {activeTeamMember.relatedLinkHref && (
              <Button
                variant={ButtonTypes.TERTIARY}
                tag="a"
                href={activeTeamMember.relatedLinkHref}
              >
                Related Link
              </Button>
            )}
          </>
        )}
      </SideSheet>
      <Wrap>
        {items &&
          (items as Sanity.TeamMember[]).map((item) => (
            <TeamMemberCard
              key={item._id}
              showBio={showBio}
              {...item}
              onReadMoreClick={() => handleReadMoreClick(item)}
            />
          ))}
      </Wrap>
    </>
  )
}

const Wrap = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    flex-wrap: wrap;
    & > * {
      flex-basis: calc(100% / 3);
    }
  }
`

const ImageWrap = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 20px;

  ${MEDIA_QUERIES.desktopUp} {
    margin-bottom: 30px;
  }
`

const JobTitle = styled(Text)`
  margin: 10px 0px 20px;
`
const Bio = styled.div`
  margin-bottom: 30px;
`
