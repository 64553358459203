import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { Sanity } from '@cellulargoods/types'

import { Heading } from '../../Text/Heading'

import { TRANSITION } from './utils'

import {
  FONT_STYLE_SOFIA_18_400,
  FONT_STYLE_SOFIA_32_400,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

const Quotes = ({
  activeQuoteIndex,
  items,
}: {
  activeQuoteIndex: number
  items: Sanity.Keyed<Sanity.LogoItemWithQuote>[]
}) => {
  const quotesWrapRef = useRef<HTMLDivElement>(null)
  const [wrapHeight, setWrapHeight] = useState<number | null>(null)

  useEffect(() => {
    const handleResize = () => {
      const children =
        quotesWrapRef.current && Array.from(quotesWrapRef.current!.children)

      if (children) {
        const childrenHeights = children!.map(
          (child) => child.getBoundingClientRect().height
        )
        setWrapHeight(Math.max(...childrenHeights))
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <QuotesWrap height={wrapHeight} ref={quotesWrapRef}>
      {items.map(({ _key, quote }, index) => (
        <QuoteContainer isActive={activeQuoteIndex === index} key={_key}>
          <div>
            <Quote tag="h2" fontStyle={FONT_STYLE_SOFIA_32_400}>
              {quote && quote.quote}
            </Quote>
            <QuoteAuthor fontStyle={FONT_STYLE_SOFIA_18_400} tag="h3">
              {quote && quote.author}
            </QuoteAuthor>
          </div>
        </QuoteContainer>
      ))}
    </QuotesWrap>
  )
}

export default Quotes

const QuotesWrap = styled.div<{
  height: number | null
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 125px;
  text-align: center;
  margin-bottom: 4rem;

  ${(props) =>
    props.height &&
    `
    height: ${props.height}px;
  `}

  ${MEDIA_QUERIES.desktopUp} {
    max-width: 90rem;
    margin: 0 auto;
    margin-bottom: 6rem;
  }
`
const QuoteContainer = styled.div<{
  isActive: boolean
}>`
  position: absolute;
  transition: ${TRANSITION};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
`

const Quote = styled(Heading)`
  margin-bottom: 2rem;
`

const QuoteAuthor = styled(Heading)``
