import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'
import { ReviewCard } from '../Cards'

import { FONT_STYLE_SOFIA_40_500, MEDIA_QUERIES } from '@cellulargoods/styles'

import { Carousel } from './Carousel'
import { ReviewStarRating } from '../Reviews'

export type CarouselReviewsProps = Sanity.Keyed<Sanity.ReviewCarouselWithYotpo>

export const CarouselReviews = (props: CarouselReviewsProps) => {
  const { title, copy, reviews, score, totalReviews } = props
  if (!Array.isArray(reviews) || reviews.length === 0) {
    return null
  }

  return (
    <CarouselContainer>
      <CarouselHeader>
        <div>
          <CarouselStars>
            <ReviewStarRating rating={score} />
            <Text tag="span">{`${score.toFixed(
              2
            )} from ${totalReviews} reviews`}</Text>
          </CarouselStars>
          <CarouselTitle fontStyle={FONT_STYLE_SOFIA_40_500}>
            {title}
          </CarouselTitle>
          <CarouselCta
            variant={ButtonTypes.SECONDARY}
            tag="a"
            href={`/reviews`}
          >{`See all reviews`}</CarouselCta>
        </div>
        <div>
          <CarouselCopy>{copy}</CarouselCopy>
        </div>
      </CarouselHeader>
      <CarouselReview>
        {reviews.map((review, index) => (
          <CarouselReviewCard
            key={`${index}_${review.id}`}
            product={(props as Sanity.ReviewCarouselProduct).product}
            {...review}
          />
        ))}
      </CarouselReview>
    </CarouselContainer>
  )
}

const CarouselContainer = styled.section`
  background-color: var(--softGrey);
`

const CarouselHeader = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8rem 15.5rem;

    & > div {
      flex-basis: 50%;

      &:first-child {
        margin-right: 3rem;
      }

      &:last-child {
        margin-left: 3rem;
      }
    }
  }
`

const CarouselStars = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > span {
    margin-left: 1rem;
    position: relative;
    bottom: 0.2rem;
  }
`

const CarouselTitle = styled(Heading)`
  margin-top: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 3rem;
  }
`

const CarouselCopy = styled(Text)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
  }
`

const CarouselCta = styled(Button)`
  margin: 3rem 0 1rem;
`

const CarouselReview = styled(Carousel)`
  padding: 0 2rem 2rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem 8rem 4rem;
  }
`

const CarouselReviewCard = styled(ReviewCard)`
  flex: 0 0 31.5rem;

  & + & {
    margin-left: 2rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex: 0 0 40.5rem;
  }
`
