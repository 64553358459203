/* eslint-disable react/display-name */
import { ReactNode, MouseEvent, forwardRef } from 'react'
import styled from 'styled-components'
import { animated } from '@react-spring/web'
import { getFontStyles, FONT_STYLE_SOFIA_16_500 } from '@cellulargoods/styles'

export interface IconButtonProps {
  icon?: any
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  ariaLabel: string
  style?: any
  children?: ReactNode
  type?: 'button' | 'submit' | 'reset'
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      onClick,
      icon,
      children,
      className,
      disabled = false,
      ariaLabel,
      style,
      type = 'button',
    },
    ref
  ) => {
    return (
      <Button
        type={type}
        ref={ref}
        className={className}
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
        style={style}
      >
        {icon ?? children}
      </Button>
    )
  }
)

const Button = styled(animated.button)<Pick<IconButtonProps, 'disabled'>>`
  ${getFontStyles(FONT_STYLE_SOFIA_16_500)}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: var(--black);
`
