import { Maybe, Sanity } from '@cellulargoods/types'

import { Slugs, SlugType, ECOMMERCE_DOMAIN } from '../references/constants'

export const checkForHttps = (link: string) => /(http(s?)):\/\//i.test(link)

export const hasSubdomain = (url: string) => {
  const regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/)

  return !!url.match(regex) // make sure it returns boolean
}

export const getFooterListItemLink = (
  {
    link,
    socialLink,
    reference,
  }: Pick<Sanity.FooterListItem, 'link' | 'reference' | 'socialLink'>,
  appendWithEcomDomain = false
) => {
  if (
    (reference && reference._type === 'utilityPage') ||
    (reference && reference._type === 'teamPage')
  ) {
    return {
      href: `${reference?.slug?.current}`.replace(
        /^/,
        appendWithEcomDomain ? ECOMMERCE_DOMAIN : '/'
      ),
    }
  } else if (reference) {
    return {
      href: `${reference._id}`.replace(
        /^/,
        appendWithEcomDomain ? ECOMMERCE_DOMAIN : '/'
      ),
    }
  } else if (socialLink && socialLink.url) {
    return {
      href: socialLink.url,
      rel: 'noopener noreferrer',
      target: '_blank',
    }
  } else if (link) {
    const isExternal = checkForHttps(link)
    return {
      href: link.replace(
        /^\//,
        !isExternal && appendWithEcomDomain ? ECOMMERCE_DOMAIN : '/'
      ),
      rel: isExternal ? 'noopener noreferrer' : '',
      target: isExternal ? '_blank' : '',
    }
  } else {
    return {
      href: '',
    }
  }
}

export const createSlug = (
  type: SlugType,
  endpoints: Array<Maybe<string> | undefined>
) => {
  const endpoint = (endpoints.filter((end) => Boolean(end)) as string[])
    .map((end) => encodeURI(end))
    .join('/')

  switch (type) {
    case SlugType.LEARN_ARTICLE:
      return `/${Slugs.ARTICLES}/${endpoint}`
    case SlugType.LEARN_SINGLETON:
      return `/${Slugs.LEARN}/${endpoint}`
    case SlugType.FUNCTIONAL_PLP:
    case SlugType.FUNCTIONAL_PDP:
      return `/${Slugs.PRODUCTS}/${endpoint}`
    case SlugType.ENHANCED_PDP:
    case SlugType.ENHANCED_PLP:
      return `/${endpoint}`
    default:
      return `/${endpoint}`
  }
}

type CreateArticleLinkOptions = {
  slug?: Maybe<Sanity.Slug> | undefined
  tagSlug?: Maybe<Sanity.Slug> | undefined
  file?: Maybe<Sanity.File> | undefined
  link?: Maybe<string> | undefined
}

export const createArticleLink = ({
  slug,
  tagSlug,
  file,
  link,
}: CreateArticleLinkOptions) => {
  /**
   * check if there's a slug cause it's internal
   * check if there's a file cause it's external
   * check if there's a link cause it's external
   */
  const hrefTo = slug?.current
    ? createSlug(SlugType.LEARN_ARTICLE, [tagSlug?.current, slug?.current])
    : file
    ? file.asset?.url ?? ''
    : link
    ? link
    : ''

  const hrefProps =
    file?.asset?.url || link
      ? {
          rel: 'noopener noreferrer',
          target: '_blank',
        }
      : {}

  return {
    hrefTo,
    hrefProps,
  }
}

/**
 * @deprecated where applicable we should
 * be using the new link component in the
 * CMS. Make sure you know which link
 * component you're rendering from the CMS!
 */
export const getUrlFromReference = (reference?: Maybe<Sanity.Documents>) => {
  if (!reference) {
    return ''
  } else {
    switch (reference._type) {
      case 'homepage':
        return '/'
      case 'about':
      case 'help':
      case 'investors':
      case 'teamPage':
      case 'reviews':
      case 'utilityPage':
        return reference.slug?.current ?? ''
      case 'learnHome':
        return '/learn'
      case 'functionalProductDisplayPage':
        return createSlug(SlugType.FUNCTIONAL_PDP, [
          reference.product?.category?.slug?.current,
          reference.product?.slug?.current,
        ])
      case 'productCategory':
        return createSlug(SlugType.ENHANCED_PLP, [reference.slug?.current])
      case 'enhancedProductDisplayPage':
        return createSlug(SlugType.ENHANCED_PDP, [
          reference.product?.category?.slug?.current,
          reference.product?.slug?.current,
        ])
      case 'learnAll':
      case 'learnPage':
        return createSlug(SlugType.LEARN_SINGLETON, [reference.slug?.current])
      case 'internalArticle':
        return createSlug(SlugType.LEARN_ARTICLE, [
          reference.Tags && reference.Tags[0]?.slug?.current,
          reference.slug?.current,
        ])
      case 'externalArticle':
        return reference.file?.asset?.url ?? reference.link ?? ''
      default:
        return ''
    }
  }
}

export const makeUrlFromType = (
  type: Sanity.Documents['_type'],
  endpoints: Array<string | undefined>
) => {
  const endpoint = (endpoints.filter((end) => Boolean(end)) as string[])
    .map((end) => encodeURI(end))
    .join('/')
  switch (type) {
    case 'homepage':
      return '/'
    case 'about':
    case 'help':
    case 'investors':
    case 'teamPage':
    case 'reviews':
    case 'utilityPage':
    case 'externalArticle':
      return `/${endpoint}`
    case 'learnHome':
      return '/learn'
    case 'functionalProductDisplayPage':
      return createSlug(SlugType.FUNCTIONAL_PDP, endpoints)
    case 'productCategory':
      return createSlug(SlugType.ENHANCED_PLP, endpoints)
    case 'enhancedProductDisplayPage':
      return createSlug(SlugType.ENHANCED_PDP, endpoints)
    case 'learnAll':
    case 'learnPage':
      return createSlug(SlugType.LEARN_SINGLETON, endpoints)
    case 'internalArticle':
      return createSlug(SlugType.LEARN_ARTICLE, endpoints)
    default:
      return ''
  }
}

export const ensureLinkHasProtocol = (link?: string) => {
  if (!link) {
    return null
  }

  const regexp =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

  if (regexp.test(link)) {
    return link
  }

  return `https://${link.split('//').filter((x) => Boolean(x))[0]}`
}

export const createSlugFromQuery = (
  type: 'internal' | 'external',
  slug?: string | null,
  referenceType?: string | null,
  category?: string | null,
  showRightSide = false
) => {
  if (!slug) {
    return
  } else if (type === 'external') {
    return slug
  } else {
    let unAppendedSlug = slug
    if (referenceType) {
      switch (referenceType) {
        case 'learnHome':
          unAppendedSlug = `/${slug}`
          break
        case 'learnPage':
          unAppendedSlug = createSlug(SlugType.LEARN_SINGLETON, [slug])
          break
        case 'functionalProductDisplayPage':
          unAppendedSlug = createSlug(SlugType.FUNCTIONAL_PDP, [category, slug])
          break
        case 'enhancedProductDisplayPage':
          unAppendedSlug = createSlug(SlugType.ENHANCED_PDP, [category, slug])
          break
        case 'internalArticle':
        case 'externalArticle':
          unAppendedSlug = createSlug(SlugType.LEARN_ARTICLE, [category, slug])
          break
        default:
          unAppendedSlug = slug
      }
    }

    return unAppendedSlug.replace(
      /^/,
      !showRightSide
        ? ECOMMERCE_DOMAIN
        : !unAppendedSlug.startsWith('/')
        ? '/'
        : ''
    )
  }
}
