import { atom, useAtom } from 'jotai'
import { SetStateAction } from 'react'

const fdaAtom = atom(
  'Please note that these products have not been evaluated by the Food and Drug Administration for safety and efficacy and are not intended to diagnose, treat, cure, or prevent any disease.'
)

export const useFDAMessage = () => {
  const [fda, setFda] = useAtom(fdaAtom)
  return [fda, setFda] as [string, (update: SetStateAction<string>) => void]
}
