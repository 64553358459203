import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { SlugType } from '../../references/constants'

import { Heading, Text } from '../Text'
import { TabBar, PaginationTabBar } from '../TabBars'
import { Bg, Button, ButtonTypes } from '../Button'
import { ArticleCard } from '../Cards'

import { chunkArray } from '../../helpers/arrays'
import { createSlug } from '../../helpers/links'

import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_32_500,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export type ArticlesGridProps = Sanity.MutatedSanityArticlesGrid & {
  originalActiveTab?: string
  onTabClick?: (tab: string) => void
  isModule?: boolean
}

export const ALL_TAB = 'All'
const PAGE_LIMIT = 12
const MODULE_PAGE_LIMIT = 6

export const ArticlesGrid = ({
  title,
  text,
  categories,
  articles,
  originalActiveTab,
  onTabClick,
  isModule = false,
}: ArticlesGridProps) => {
  const [activeTab, setActiveTab] = useState<string | null>(() => {
    if (originalActiveTab && originalActiveTab !== ALL_TAB.toLowerCase()) {
      return originalActiveTab
    } else {
      return null
    }
  })

  const [currentPage, setCurrentPage] = useState(1)

  const handleTabClick = (tab: string | null) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setCurrentPage(1)

      if (onTabClick) {
        onTabClick(tab ?? ALL_TAB.toLowerCase())
      }
    }
  }

  const requiredArticles = useMemo(
    () =>
      articles.filter((article) => {
        if (activeTab === null) {
          return true
        }
        if (article && article.Tags![0]?.title?.toLowerCase() === activeTab) {
          return true
        } else {
          return false
        }
      }),
    [activeTab, articles]
  )

  const chunkedArticles = useMemo(
    () =>
      chunkArray(requiredArticles, !isModule ? PAGE_LIMIT : MODULE_PAGE_LIMIT),
    [requiredArticles, isModule]
  )

  const handleLeftClick = () => {
    if (currentPage - 1 < 1) {
      return
    } else {
      setCurrentPage((s) => s - 1)
    }
  }

  const handleRightClick = () => {
    if (currentPage + 1 > chunkedArticles.length) {
      return
    } else {
      setCurrentPage((s) => s + 1)
    }
  }

  return (
    <ArticlesGridContainer>
      <ArticlesGridBanner isModule={isModule}>
        <ArticlesGridContent>
          {title ? (
            <ArticlesGridTitle
              tag={!isModule ? 'h1' : 'h2'}
              fontStyle={FONT_STYLE_SOFIA_32_500}
            >
              {title}
            </ArticlesGridTitle>
          ) : null}
          {text ? (
            <ArticlesGridCopy fontStyle={FONT_STYLE_SOFIA_16_400}>
              {text}
            </ArticlesGridCopy>
          ) : null}
        </ArticlesGridContent>
      </ArticlesGridBanner>
      <ArticlesGridTabBanner
        isModule={isModule}
        tabs={categories}
        masterTab={ALL_TAB}
        activeTab={activeTab}
        onTabClick={handleTabClick}
        scrollOnMobile
      />
      <Grid isModule={isModule}>
        {chunkedArticles[currentPage - 1] &&
          chunkedArticles[currentPage - 1].map(
            (article, index) =>
              article && (
                <GridCard
                  key={article._key ?? `${article.title}_${index}`}
                  title={article.title}
                  slug={(article as Sanity.InternalArticle).slug}
                  link={(article as Sanity.ExternalArticle).link}
                  file={(article as Sanity.ExternalArticle).file}
                  tags={article.Tags}
                  info={article.ArticleCardInfo}
                  isInGrid
                />
              )
          )}
      </Grid>
      {!isModule && chunkedArticles.length > 1 ? (
        <PaginationTabBar
          currentPage={currentPage}
          limit={PAGE_LIMIT}
          itemCount={chunkedArticles[currentPage - 1]?.length ?? 0}
          totalPages={Math.ceil(requiredArticles.length / PAGE_LIMIT)}
          onLeftClick={handleLeftClick}
          onRightClick={handleRightClick}
        />
      ) : null}
      {!isModule ? null : (
        <ModuleButton
          variant={ButtonTypes.PRIMARY}
          tag="a"
          href={createSlug(SlugType.LEARN_ARTICLE, ['all'])}
          bg={Bg.white}
        >
          View all articles
        </ModuleButton>
      )}
    </ArticlesGridContainer>
  )
}

const ArticlesGridContainer = styled.section``

const ArticlesGridBanner = styled.section<Pick<ArticlesGridProps, 'isModule'>>`
  padding-top: ${(props) =>
    !props.isModule ? 'var(--header-height-mobile)' : '0'};
  background-color: ${(props) =>
    !props.isModule ? 'var(--softGrey)' : 'transparent'};

  ${MEDIA_QUERIES.desktopUp} {
    padding-top: ${(props) =>
      !props.isModule ? 'var(--header-height-tablet)' : '0'};
  }
`

const ArticlesGridContent = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 4rem;
    max-width: 50%;
  }
`

const ArticlesGridTitle = styled(Heading)``

const ArticlesGridCopy = styled(Text)`
  margin-top: 2rem;
`

const ArticlesGridTabBanner = styled(TabBar)<
  Pick<ArticlesGridProps, 'isModule'>
>`
  background-color: ${(props) =>
    !props.isModule ? 'var(--softGrey)' : 'transparent'};
  border-bottom: ${(props) =>
    !props.isModule ? 'solid 1px var(--darkGrey1)' : 'none'};
`

const Grid = styled.div<Pick<ArticlesGridProps, 'isModule'>>`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.tabletUp} {
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding: ${(props) =>
      !props.isModule ? '4rem 4rem 8rem 4rem' : '4rem 4rem 6rem 4rem'};
    grid-template-columns: repeat(3, 1fr);
  }
`

const GridCard = styled(ArticleCard)`
  & + & {
    margin-top: 3rem;
  }

  ${MEDIA_QUERIES.tabletUp} {
    & + & {
      margin: 0;
    }
  }
`

const ModuleButton = styled(Button)`
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: block;
    margin: 0 4rem 4rem 4rem;
  }
`
