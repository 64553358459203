import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'

import { seperateTextByLineBreaks } from '../../helpers/strings'
import { getHeaderFontStyles } from '../../helpers/text'

import { HeaderTextSizes } from '../../references/constants'

export type ContentHeaderTextProps = Sanity.HeaderText & {
  isSubmodule?: boolean
  useSecondaryCtaStyle?: boolean
  className?: string
  hasAnchor?: boolean
}

export const ContentHeaderText = ({
  title,
  subtitle,
  body,
  CTAButton,
  size = 'small',
  isSubmodule = false,
  useSecondaryCtaStyle = false,
  className,
  centerOnMobile,
  hasAnchor,
}: ContentHeaderTextProps) => {
  const { headerFontStyle, subtitleFontStyle } = getHeaderFontStyles(
    size as HeaderTextSizes
  )

  return (
    <Content
      as={isSubmodule ? 'div' : 'section'}
      className={className}
      centerOnMobile={centerOnMobile}
    >
      <ContentWidthWrap isSubmodule={isSubmodule}>
        {title ? (
          <ContentHeading tag="h2" fontStyle={headerFontStyle}>
            {title}
          </ContentHeading>
        ) : null}
        {subtitle ? (
          <ContentSubtitle tag="h3" fontStyle={subtitleFontStyle}>
            {seperateTextByLineBreaks(subtitle).map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </ContentSubtitle>
        ) : null}
        {body ? (
          <ContentText>
            {seperateTextByLineBreaks(body).map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </ContentText>
        ) : null}
        {CTAButton && CTAButton?.label && CTAButton?.url ? (
          <ContentCTA
            variant={
              !isSubmodule && !useSecondaryCtaStyle
                ? ButtonTypes.PRIMARY
                : ButtonTypes.SECONDARY
            }
            tag={hasAnchor ? 'span' : 'a'}
            href={CTAButton.url}
            isExternal={Boolean(CTAButton.isExternal)}
            isSubmodule={isSubmodule}
          >
            {CTAButton!.label}
          </ContentCTA>
        ) : null}
      </ContentWidthWrap>
    </Content>
  )
}

const Content = styled.section<Pick<ContentHeaderTextProps, 'centerOnMobile'>>`
  text-align: ${({ centerOnMobile }) => (centerOnMobile ? 'center' : 'unset')};
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 4rem;
  }
`

const ContentWidthWrap = styled.div<
  Pick<ContentHeaderTextProps, 'isSubmodule'>
>`
  ${MEDIA_QUERIES.desktopUp} {
    max-width: 80rem;
    margin: 0 auto;
    text-align: ${(props) => (!props.isSubmodule ? 'center' : 'left')};
  }
`

const ContentHeading = styled(Heading)``

const ContentSubtitle = styled(Heading)`
  margin-top: 2rem;

  & > span + span {
    display: block;
    margin-top: 1.37em;
  }
`

const ContentText = styled(Text)`
  margin-top: 2rem;

  & > span + span {
    display: block;
    margin-top: 1.37em;
  }
`

const ContentCTA = styled(Button)<Pick<ContentHeaderTextProps, 'isSubmodule'>>`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    max-width: ${(props) => (props.isSubmodule ? 'unset' : '21rem')};
    margin: ${(props) =>
      !props.isSubmodule ? '2rem auto 0 auto' : '2rem 0 0 0'};
  }
`
