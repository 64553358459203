import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { mergeColumns } from '../../helpers/mergeColumns'

import { GenericCard } from '../Cards'
import { TextGridItem } from '../Text'
import { ImageSlider } from '../Image'

import { MEDIA_QUERIES } from '@cellulargoods/styles'

export type ContentTwoColumnGridProps = Sanity.Keyed<Sanity.TwoColumnGrid>

export const ContentTwoColumnGrid = ({
  leftColumn,
  rightColumn,
  shouldHaveBackground,
  columnReverseOnMobile,
}: ContentTwoColumnGridProps) => {
  if (!leftColumn || !rightColumn) {
    return null
  }

  const components = mergeColumns(leftColumn, rightColumn)

  const isTextColumns = components.every(
    (item) => item?._type === 'TextGridItem'
  )

  return (
    <ColumnContainer
      reverseColumnsOnMobile={Boolean(columnReverseOnMobile)}
      hasBackground={Boolean(shouldHaveBackground)}
      isTextColumns={isTextColumns}
    >
      {components.map((item, index) => (
        <Column key={item?._key ?? index}>
          {item &&
            renderComponent(item, index, {
              isTextColumns,
            })}
        </Column>
      ))}
    </ColumnContainer>
  )
}

type CMSComponents =
  | Sanity.Keyed<Sanity.GenericCard>
  | Sanity.Keyed<Sanity.MediaGridItem>
  | Sanity.Keyed<Sanity.TextGridItem>
  | Sanity.Keyed<Sanity.ImageSlider>

const renderComponent = (
  CMSComponent: CMSComponents,
  index: number,
  options: {
    isTextColumns: boolean
  }
) => {
  switch (CMSComponent._type) {
    case 'GenericCard':
      return <GenericCard {...CMSComponent} />
    case 'TextGridItem':
      return (
        <ColumnTextContainer
          noMargin={options.isTextColumns}
          isFirst={index === 0}
        >
          <ColumnTextGridItem
            noMargin={options.isTextColumns}
            key={CMSComponent._key}
            {...CMSComponent}
          />
        </ColumnTextContainer>
      )
    case 'ImageSlider':
      return <ColumnImageSlider key={CMSComponent._key} {...CMSComponent} />
    default:
      return null
  }
}

const ColumnContainer = styled.section<{
  hasBackground: boolean
  reverseColumnsOnMobile: boolean
  isTextColumns: boolean
}>`
  padding: ${(props) =>
    !props.isTextColumns ? '4rem 2rem 5rem 2rem' : '4rem 2rem 4rem 2rem'};
  background-color: ${(props) =>
    props.hasBackground ? 'var(--softGrey)' : ''};
  display: flex;
  flex-direction: column;

  & > *:nth-child(1) {
    order: ${(props) => (props.reverseColumnsOnMobile ? 2 : 1)};
  }

  & > *:nth-child(2) {
    order: ${(props) => (props.reverseColumnsOnMobile ? 1 : 2)};
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
    flex-direction: row;

    & > *:nth-child(1) {
      order: 1;
    }

    & > *:nth-child(2) {
      order: 2;
    }
  }
`

const Column = styled.div`
  flex-basis: 50%;
`

const ColumnTextContainer = styled.div<{
  isFirst: boolean
  noMargin: boolean
}>`
  margin-top: ${(props) => (!props.noMargin ? '4rem' : '0')};

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0;
    padding: ${(props) => {
      if (props.isFirst && !props.noMargin) {
        return '8rem 12.5rem 8rem 15.5rem'
      } else if (!props.isFirst && !props.noMargin) {
        return '8rem 15.5rem 8rem 12.5rem'
      } else if (props.isFirst && props.noMargin) {
        return '8rem 2.7rem 8rem 15.6rem'
      } else {
        return '8rem 15.6rem 8rem 2.7rem'
      }
    }};

    display: flex;
    justify-content: ${(props) => (!props.noMargin ? 'center' : 'flex-start')};
    align-items: ${(props) => (!props.noMargin ? 'center' : 'flex-start')};
    height: 100%;
  }
`

const ColumnTextGridItem = styled(TextGridItem)<{
  noMargin: boolean
}>`
  ${(props) =>
    !props.noMargin
      ? ''
      : `
    ${MEDIA_QUERIES.desktopUp}{
      & p:first-child {
        margin: 0;
      }
    }
`}
`

const ColumnImageSlider = styled(ImageSlider)`
  ${MEDIA_QUERIES.desktopUp} {
    padding: 10rem 8rem 7rem 8rem;
  }
`
