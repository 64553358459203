import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Heading } from '../Text'
import { RelatedLinkCard } from '../Cards'

import { FONT_STYLE_SOFIA_32_500, MEDIA_QUERIES } from '@cellulargoods/styles'

export type ContentRelatedLinksProps = Sanity.RelatedLinks

export const ContentRelatedLinks = ({
  title,
  items,
}: ContentRelatedLinksProps) => {
  return (
    <RelatedLinks>
      <RelatedLinksTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
        {title}
      </RelatedLinksTitle>

      <RelatedLinksList>
        {items &&
          items.map(
            (item, index) =>
              item && (
                <RelatedLinksItem key={item._key ?? index}>
                  <RelatedLinkCard
                    label={item.label}
                    link={item.link}
                    reference={item.reference}
                    media={item.media ? item.media[0] : null}
                  />
                </RelatedLinksItem>
              )
          )}
      </RelatedLinksList>
    </RelatedLinks>
  )
}

const RelatedLinks = styled.section`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 4rem;
  }
`

const RelatedLinksTitle = styled(Heading)`
  ${MEDIA_QUERIES.desktopUp} {
    text-align: center;
  }
`

const RelatedLinksList = styled.ul`
  margin-top: 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0 -2rem 0 -2rem;
    margin-top: 8rem;
    display: flex;
    flex-wrap: wrap;
  }
`

const RelatedLinksItem = styled.li`
  & + & {
    margin-top: 5rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex: 1 0;
    margin: 0 2rem;

    & + & {
      margin-top: 0;
    }
  }
`
