/**
 * Useful function for when we needed to explicitly set
 * a left and right column on the CMS.
 *
 * We are forcing the return type because we know what types of columns will
 * go into a leftColumn or rightColumn component, but typescript wants us to
 * include them all.
 *
 * So instead we can simply call:
 *
 * ```
 * const components = mergeColumns<[Sanity_GenericCard | Sanity_MediaImage]>(leftCol, rightCol)
 * ```
 *
 * If we know the columns will only include a GenericCard or a MediaImage etc.
 */
export function mergeColumns<TLeft extends any[], TRight extends any[]>(
  leftCol: TLeft,
  rightCol: TRight
): [TLeft[0], TRight[0]] {
  const items = []
  if (leftCol && leftCol[0]) items.push(leftCol[0])
  if (rightCol && rightCol[0]) items.push(rightCol[0])
  return items as [TLeft[0], TRight[0]]
}
