import Script from 'next/script'

export const WidgetGorgias = () => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: /* css */ `
           iframe#chat-button {
               display: none;
               bottom: -1rem !important;
               right: 10rem !important;
           }
           iframe#chat-window {
               right: 9.2rem !important;
           }

           @media(min-width: 1024px){
            iframe#chat-button {
                bottom: 1rem !important;
                right: 12.6rem !important;
            }
            iframe#chat-window {
                right:11.2rem !important;
            }
           }
        `,
        }}
      ></style>
      <Script
        strategy="lazyOnload"
        src="https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=10795"
        onLoad={() => {
          if (window.GorgiasChat) {
            window.GorgiasChat.init().then((chat) => {
              const buttonEl = document.querySelector(
                'iframe#chat-button'
              ) as HTMLDivElement

              chat.on('widget:opened', () => {
                buttonEl.style.display = 'unset'
              })

              if (chat.isOpen()) {
                buttonEl.style.display = 'unset'
              }

              chat.hidePoweredBy(true)
            })
          }
        }}
      />
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: /*js */ `
                  var maxRepetitions = 60
                  var repetitionCount = 0
                  var isWindowStyleAppended = false
                  var gorgiasChatInterval = window.setInterval(function() {
                      var container = document.querySelector('#gorgias-chat-container');
                      var chatButtonHead = container?.querySelector('#chat-button')?.contentWindow?.document.querySelector('head');
      
                      if (chatButtonHead?.children.length && !isWindowStyleAppended) {
                          var buttonStyle = document.createElement('style');
                          buttonStyle.textContent = '#gorgias-chat-messenger-button {width: 48px; height: 48px;}'; // the custom CSS for the chat button
                          chatButtonHead.appendChild(buttonStyle);
                          isWindowStyleAppended = true;
                      }
      
                      if (++repetitionCount == maxRepetitions) {
                          window.clearInterval(gorgiasChatInterval);
                      }
                  }, 100);`,
        }}
      />
    </>
  )
}
