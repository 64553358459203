import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { incrementQuoteIndex } from './LogosWithQuote/utils'
import Quotes from './LogosWithQuote/Quotes'
import Logos from './LogosWithQuote/Logos'

import { MEDIA_QUERIES } from '@cellulargoods/styles'

const FADE_DURATION = 5000

export type ContentLogosWithQuoteProps =
  Sanity.Keyed<Sanity.LogosBannerLargeWithQuote>

export const ContentLogosWithQuote = ({
  items,
}: ContentLogosWithQuoteProps) => {
  const [activeQuoteIndex, setActiveQuoteIndex] = useState(0)
  const timer = useRef<number | null>(null)

  const initInterval = useCallback(() => {
    if (!items) return null
    setActiveQuoteIndex((prevIndex) =>
      incrementQuoteIndex(prevIndex, items.length)
    )
  }, [items])

  useEffect(() => {
    timer.current = window.setInterval(() => {
      initInterval()
    }, FADE_DURATION)

    return () => {
      timer.current && clearInterval(timer.current)
    }
  }, [initInterval])

  const handleLogoClick = (index: number) => {
    setActiveQuoteIndex(index)
    timer.current && clearInterval(timer.current)
    timer.current = window.setInterval(() => initInterval(), FADE_DURATION)
  }

  return (
    <SectionWrap>
      {items && <Quotes activeQuoteIndex={activeQuoteIndex} items={items} />}
      {items && (
        <Logos
          onClick={handleLogoClick}
          activeQuoteIndex={activeQuoteIndex}
          items={items}
        />
      )}
    </SectionWrap>
  )
}

const SectionWrap = styled.section`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 2.5rem;
  }
`
