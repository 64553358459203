import styled from 'styled-components'
import { CheckCircle, XCircle } from 'phosphor-react'

import { Sanity } from '@cellulargoods/types'
import { Text } from '../Text'
import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_18_400,
  MEDIA_QUERIES,
  getFontStyles,
} from '@cellulargoods/styles'

type TableProps = Pick<Sanity.Table, 'rows'>

const formatCell = (cell: string | null) => {
  if (cell === 'true') {
    return <CheckCircle color="black" size={32} weight="thin" />
  } else if (cell === 'false') {
    return <XCircle color="black" size={32} weight="thin" />
  } else {
    return cell
  }
}

export const Table = ({ rows = [] }: TableProps) => {
  if (!rows) {
    return null
  }
  const [topRow, ...restRows] = rows

  const columnCount = (topRow.cells ?? []).length

  return (
    <TableGrid>
      <TableTopRow columns={columnCount}>
        {topRow.cells?.map((cell, i) => (
          <TableTopCell key={`top_row_${i}`}>{cell}</TableTopCell>
        ))}
      </TableTopRow>
      {restRows.map((row, i) => (
        <TableRow columns={columnCount} key={`row_${i}`}>
          {row.cells?.map((cell, j) =>
            j === 0 ? (
              <TableLeftCell key={`row_${i}_cell_${j}`}>
                {formatCell(cell)}
              </TableLeftCell>
            ) : (
              <TableCell
                key={`row_${i}_cell_${j}`}
                fontStyle={FONT_STYLE_SOFIA_16_400}
              >
                {formatCell(cell)}
              </TableCell>
            )
          )}
        </TableRow>
      ))}
    </TableGrid>
  )
}

const TableGrid = styled.div`
  display: grid;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 100%;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--black);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    margin-right: 2rem;
  }
`

const TableGenericRow = styled.div<{
  columns: number
}>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  padding-right: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
  }
`

const TableTopRow = styled(TableGenericRow)``

const TableRow = styled(TableGenericRow)`
  &:last-child {
    margin-bottom: 4rem;

    ${MEDIA_QUERIES.desktopUp} {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
`

const TableGenericCell = styled(Text)`
  min-width: 11.1rem;
  width: 100%;
  padding: 3.1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TableTopCell = styled(TableGenericCell)`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  height: 9rem;
  text-align: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);

  ${MEDIA_QUERIES.tabletUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_18_400)}
  }
`

const TableLeftCell = styled(TableGenericCell)`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  justify-content: flex-start;

  ${MEDIA_QUERIES.tabletUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_16_400)}
  }

  ${MEDIA_QUERIES.desktopUp} {
    justify-content: center;
  }
`

const TableCell = styled(TableGenericCell)`
  padding: 2.3rem 0.9rem;
  text-align: center;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 5.2rem 2rem;
    max-height: 13rem;
  }
`
