export const validateValues = <TValues, TKey extends keyof TValues>(
  values: TValues,
  customValidator: (key: TKey, string: TValues[TKey]) => boolean = (
    _key,
    _value
  ) => true
) => {
  const errors: Record<TKey, boolean> = {} as Record<TKey, boolean>

  Object.entries(values).forEach(([key, value]) => {
    if (
      (typeof value === 'string' && value === '') ||
      !customValidator(key as TKey, value)
    ) {
      errors[key as TKey] = true
    } else {
      errors[key as TKey] = false
    }
  })

  const errorsExist = Object.values(errors).some((x) => Boolean(x))

  return {
    errors: errors,
    valid: !errorsExist,
  }
}
