import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import { aspectRatio } from '@cellulargoods/styles'

export const MediaYoutube = ({
  url,
  showControls,
  loopEnabled,
  playsinline,
  autoplay,
}: Sanity.MediaYoutube) => {
  return (
    <IframeContainer>
      <Iframe
        src={`${url}?loop=${Boolean(loopEnabled) ? 1 : 0}&controls=${
          Boolean(showControls) ? 1 : 0
        }&playsinline=${Boolean(playsinline) ? 1 : 0}&autoplay=${
          Boolean(autoplay) ? 1 : 0
        }`}
      />
    </IframeContainer>
  )
}

const IframeContainer = styled.div`
  ${aspectRatio(16, 9)};
`

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`
