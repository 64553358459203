import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { FONT_STYLE_SOFIA_32_500, MEDIA_QUERIES } from '@cellulargoods/styles'
import { Sanity, Yotpo, NonNullSkipArray, PickType } from '@cellulargoods/types'
import { Dropdown } from '../Input'
import { Button, ButtonTypes } from '../Button'
import { Heading } from '../Text'
import { PaginationTabBar } from '../TabBars'
import { uniqueOnly } from '../../helpers'
import { ReviewModuleModes } from '../../references/constants'
import { ReviewsSnippet } from './ReviewsSnippet'
import { ReviewStarRating } from './ReviewStarRating'

export type ReviewsModuleProps = Sanity.Keyed<Sanity.ReviewsWithYotpo>

const filterForFilterableQuestions = (value: {
  field_type: string
  title: string
}) =>
  (value.field_type === Yotpo.CustomFieldTypes.SingleChoice ||
    value.field_type === Yotpo.CustomFieldTypes.MultipleChoice ||
    value.field_type === Yotpo.CustomFieldTypes.Rating) &&
  value.title !== 'Would you recommended this product to a friend?'

/**
 * TO DO:
 *
 * if reviews are the company reviews
 * when you set the product we need to
 * fetch the reviews for said products
 * they are now the reviews visible
 * excluding the parameters of the
 * filters already set
 */
export const ReviewsModule = (props: ReviewsModuleProps) => {
  const { reviews, score, totalReviews, count, mode } = props
  const { allProducts } = props as Sanity.ReviewsCompany

  const isCategory = mode === ReviewModuleModes.CATEGORY

  const { asPath } = useRouter()

  const [currentPage, setCurrentPage] = useState(1)
  // const [searchResults, setSearchResults] = useState<Yotpo.Review[]>([])
  const [filters, setFilters] = useState<Record<string, string | null>>(() => {
    const state: Record<string, string | null> = {
      product: null,
    }

    void (reviews as Yotpo.Review[])
      .flatMap((rev) =>
        Object.values(rev.customFields ?? {})
          .filter(filterForFilterableQuestions)
          .map((field) => field.title.toLowerCase())
      )
      .forEach((opt) => (state[opt] = null))

    return state
  })

  const productOptions = useMemo(() => {
    if (isCategory) {
      return (reviews as PickType<Yotpo.CategoryReviews, 'reviews'>)
        .map(({ product }) => product!.name)
        .filter(uniqueOnly)
    } else if (mode === ReviewModuleModes.COMPANY) {
      return allProducts.map((prod) => prod.title)
    } else return []
  }, [mode, reviews, allProducts, isCategory])

  const filterOptions = useMemo(() => {
    const filters = (reviews as Yotpo.Review[]).reduce((acc, curr) => {
      Object.values(curr.customFields ?? {})
        .filter(filterForFilterableQuestions)
        .forEach((question) => {
          if (!acc[question.title]) {
            acc[question.title] = []
          }

          if (!acc[question.title].includes(question.value.toString())) {
            acc[question.title] = [
              ...acc[question.title],
              question.value.toString(),
            ]
          }
        })
      return acc
    }, {} as Record<string, Array<string>>)

    if (filters.Age) {
      filters.Age = filters.Age.sort()
    }

    return filters
  }, [reviews])

  const handleFilterChange =
    (filterName: string, shouldFetch = false) =>
    (value: string) => {
      if (shouldFetch) {
      }

      if (filters[filterName] !== value && value !== '') {
        setFilters((s) => ({
          ...s,
          [filterName]: value,
        }))
      } else if (value === '' && filters[filterName] !== null) {
        setFilters((s) => ({
          ...s,
          [filterName]: null,
        }))
      }
    }

  const handleResetFilterClick = () => {
    const newState: Record<string, null> = {}

    Object.keys(filters).forEach((filter) => {
      newState[filter] = null
    })

    setFilters(newState)
  }

  const visibleReviews = useMemo(() => {
    const reviewsToBeFiltered = reviews

    return reviewsToBeFiltered.filter((review) => {
      const customQ = Object.values(review.customFields ?? {})
      const doesReviewHaveCustomQFilter = !customQ
        .filter(filterForFilterableQuestions)
        .map(
          (question) =>
            filters[question.title.toLowerCase()] === null ||
            filters[question.title.toLowerCase()] === question.value
        )
        .includes(false)

      const doesReviewHaveProductAssociated =
        filters['product'] === null ||
        (review as NonNullSkipArray<PickType<Yotpo.CategoryReviews, 'reviews'>>)
          .product?.name === filters['product']

      return doesReviewHaveCustomQFilter && doesReviewHaveProductAssociated
    })
  }, [reviews, filters])

  const computedScore = useMemo(() => {
    if (isCategory) {
      return (
        visibleReviews
          .map((review) => review.score)
          .reduce((prev, curr) => prev + curr) / visibleReviews.length
      )
    } else return score
  }, [visibleReviews, score, isCategory])

  if (!Array.isArray(reviews) || reviews.length === 0) {
    return null
  }

  const needsPagination = visibleReviews.length > count!

  const handleLeftClick = () => {
    setCurrentPage((s) => s - 1)
  }

  const handleRightClick = () => {
    setCurrentPage((s) => s + 1)
  }

  // const id =
  //   (props.product as Yotpo.ReviewProduct)?.domain_key ??
  //   props.product?.product?.id

  // const handleSearchSubmit = async (searchTerm: string) => {
  //   try {
  //     if (!id) {
  //       const ERR_MSG = 'Cannot search reviews because theres no product ID'
  //       throw new Error(ERR_MSG)
  //     }

  //     const { data } = await axios.get<Yotpo.GetSearchResultsResponse>(
  //       `/api/reviews/search?term=${searchTerm}&id=${id}`
  //     )

  //     setSearchResults(data.results)

  //     return data.results.length > 0
  //   } catch (err) {
  //     console.error(err)
  //     captureException(err)
  //     return false
  //   }
  // }

  const canReset = Object.values(filters).some((val) => Boolean(val))

  return (
    <Reviews id="reviews">
      <Header>
        <ReviewStarRating rating={computedScore} size={32} />
        <ReviewTitle
          tag="h2"
          fontStyle={FONT_STYLE_SOFIA_32_500}
        >{`${computedScore.toFixed(2)} from ${
          isCategory ? visibleReviews.length : totalReviews
        } reviews`}</ReviewTitle>
        {asPath !== '/reviews' && (
          <ReviewCta variant={ButtonTypes.SECONDARY} tag="a" href="/reviews">
            Read all reviews
          </ReviewCta>
        )}
      </Header>
      {Object.keys(filterOptions).length > 0 && (
        <ReviewFilters>
          <div>
            {/* {mode === ReviewModuleModes.PRODUCT && id && (
              <ReviewSearchInput onSubmit={handleSearchSubmit} />
            )} */}
            {((props.mode === ReviewModuleModes.CATEGORY &&
              props.categories?.length === 1) ||
              props.mode === ReviewModuleModes.PRODUCT) &&
              Object.entries(filterOptions).map(([optionName, options]) => (
                <ReviewFilter
                  key={optionName}
                  onChange={handleFilterChange(optionName.toLowerCase())}
                  placeholder={optionName}
                  options={options}
                  value={filters[optionName.toLowerCase()]}
                />
              ))}
            {mode !== ReviewModuleModes.PRODUCT && (
              <ReviewFilter
                onChange={handleFilterChange(
                  'product',
                  mode === ReviewModuleModes.COMPANY
                )}
                placeholder="Product"
                options={productOptions}
                value={filters['product']}
              />
            )}
          </div>
          <ResetFilters
            variant={ButtonTypes.TERTIARY}
            onClick={handleResetFilterClick}
            disabled={!canReset}
          >
            Reset filters
          </ResetFilters>
        </ReviewFilters>
      )}
      <ReviewsContainer>
        {visibleReviews
          .slice(count! * (currentPage - 1), currentPage * count!)
          .map((rev) => (
            <ReviewsSnippet key={rev.id} {...rev} />
          ))}
      </ReviewsContainer>
      {needsPagination && (
        <PaginationTabBar
          currentPage={currentPage}
          itemCount={count!}
          limit={count!}
          onLeftClick={handleLeftClick}
          onRightClick={handleRightClick}
          totalPages={Math.ceil(visibleReviews.length / count!)}
        />
      )}
    </Reviews>
  )
}

const Reviews = styled.section`
  width: 100%;
  background-color: var(--softGrey);
`

const Header = styled.header`
  width: 100%;
  padding: 4rem 2rem 5rem;

  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const ReviewTitle = styled(Heading)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 3rem;
  }
`

const ReviewCta = styled(Button)`
  margin-top: 3rem;
`

const ReviewFilters = styled.div`
  padding: 0 2rem 4rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem 8rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`

// const ReviewSearchInput = styled(SearchInput)`
//   width: 100%;

//   ${MEDIA_QUERIES.desktopUp} {
//     max-width: 21rem;
//     margin: 0 1rem;
//   }
// `

const ReviewFilter = styled(Dropdown)`
  margin-top: 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;

    max-width: 34rem;
    margin: 0 1rem;
  }
`

const ResetFilters = styled(Button)`
  margin-top: 2rem;
  color: var(--accessibleGrey);
  border-bottom: solid 1px var(--accessibleGrey);
`

const ReviewsContainer = styled.div`
  margin: 0 auto;
  max-width: 148rem;
`
