import Script from 'next/script'

export const TrackingRakuten = () => {
  if (!process.env.NEXT_PUBLIC_ENABLE_RAKUTEN) {
    return null
  }

  return (
    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: /* js */ `
        (function (url) {
            /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
            if(!window.DataLayer){
              window.DataLayer = {};
            }
            if(!DataLayer.events){
              DataLayer.events = {};
            }
            DataLayer.events.SPIVersion = DataLayer.events.SPIVersion || "3.4.1";
            DataLayer.events.SiteSection = "1";
      
            var loc, ct = document.createElement("script");
            ct.type = "text/javascript";
            ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
            loc.parentNode.insertBefore(ct, loc);
            }(document.location.protocol + "//tag.rmp.rakuten.com/124361.ct.js"));
        `,
      }}
    />
  )
}
