import styled from 'styled-components'
import { MouseEvent } from 'react'

import { Text } from '../Text/Text'

import { FONT_STYLE_SOFIA_16_500, MEDIA_QUERIES } from '@cellulargoods/styles'

import { ButtonGenericProps } from './types'

export enum Bg {
  transparent = 'transparent',
  white = 'white',
  black = 'black',
}

export interface ButtonPrimaryProps extends ButtonGenericProps {
  bg?: Bg
  /**
   * `isFullWidth`
   *
   * Ensures the button is always full width by
   * disabling `width: auto` on `desktopUp` devices.
   */
  isFullWidth?: boolean
  loading?: boolean
  disabled?: boolean
}

export const ButtonPrimary = ({
  bg = Bg.transparent,
  isFullWidth = false,
  loading,
  className,
  children,
  label,
  onClick,
  href,
  tag = 'button',
  isExternal,
  type,
  disabled = false,
  testId,
  ...restProps
}: ButtonPrimaryProps) => {
  const anchorProps = {
    href,
    rel: isExternal ? 'noopener noreferrer' : '',
    target: isExternal ? '_blank' : '',
  }

  const handleOnClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <>
      {/* @ts-expect-error no idea, and got no time. – https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30117 */}
      <StyledButton
        as={tag}
        className={className}
        isFullWidth={isFullWidth}
        onClick={handleOnClick}
        bg={bg}
        disabled={disabled || loading}
        type={type}
        data-testid={testId}
        {...(tag === 'a' ? anchorProps : {})}
        {...restProps}
      >
        <Text fontStyle={FONT_STYLE_SOFIA_16_500} tag="span">
          {label ?? children}
        </Text>
      </StyledButton>
    </>
  )
}

const StyledButton = styled.button<{
  bg: Bg
  isFullWidth?: boolean
  as: string
  disabled: boolean
}>`
  border: 1px solid var(--darkGrey1);
  padding: 1rem 3.5rem 1.2rem 3.5rem;
  background-color: ${({ bg }) => BgColorMap[bg]};
  cursor: pointer;
  width: 100%;
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;

  & > span {
    color: ${(props) =>
      props.bg === Bg.black ? 'var(--white)' : 'var(--black)'};
    position: relative;
    top: -0.2rem;
    transition: color 200ms ease-out;
  }

  ${MEDIA_QUERIES.desktopUp} {
    min-width: 21rem;
    width: ${(props) => (props.isFullWidth ? '100%' : 'auto')};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0rem;
    right: 0rem;
    background-color: ${(props) =>
      props.bg === Bg.transparent ? 'var(--black)' : 'var(--lightBrown)'};
    border-top: none;
    width: 100%;
    height: 0;
    transition: height 200ms ease-out;
  }

  @media (hover: hover) {
    &:hover {
      & > span {
        color: ${(props) =>
          props.bg === Bg.transparent ? 'var(--white)' : 'var(--black)'};
      }
    }

    &:hover:before {
      height: 100%;
    }
  }

  opacity: ${(props) => (props.disabled ? 0.35 : 1)};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const BgColorMap = {
  transparent: 'transparent',
  white: 'var(--white)',
  black: 'var(--darkGrey1)',
  eGreen: 'var(--lightBrown)',
}
