/* eslint-disable react/display-name */
/**
 * This is the main TextRenderer for Utility pages and Investor pages.
 *
 * It should include all styling for all cases.
 *
 * Other `TextRenderers` exist, but they only allow for certain styles such as underlined or highlighted text.
 */
import { ReactNode } from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import BlockContent from '@sanity/block-content-to-react'
import { SanityImageSource } from '@sanity/image-url/lib/types/types'
import { Sanity } from '@cellulargoods/types'
import {
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_16_500,
  FONT_STYLE_SOFIA_18_400,
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_22_400,
  FONT_STYLE_SOFIA_22_500,
  FONT_STYLE_SOFIA_28_400,
  FONT_STYLE_SOFIA_32_400,
  FONT_STYLE_SOFIA_40_400,
  FONT_STYLE_SOFIA_48_400,
  getFontStyles,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { Heading, Text } from '../Text'
import { Button, ButtonTypes } from '../Button'

import { urlFor } from '../../lib/sanity'
import { slugify } from '../../helpers/strings'

/**
 * Matches a `style` slug from Rich Text such as "h1", "h2" etc, and pairs
 * it with the corresponding `fontStyle` string.
 */
const richTextStyleHeadingFontMap = {
  h1: FONT_STYLE_SOFIA_48_400,
  h2: FONT_STYLE_SOFIA_40_400,
  h3: FONT_STYLE_SOFIA_32_400,
  h4: FONT_STYLE_SOFIA_28_400,
  h5: FONT_STYLE_SOFIA_22_400,
  h6: FONT_STYLE_SOFIA_18_400,
}
const internalArticleRichTextFontMap = {
  h1: FONT_STYLE_SOFIA_28_400,
  h2: FONT_STYLE_SOFIA_22_500,
  h3: FONT_STYLE_SOFIA_18_500,
  h4: FONT_STYLE_SOFIA_16_500,
  h5: FONT_STYLE_SOFIA_16_500,
}
/**
 * MarkDefs is missing in Sanity type?
 */
export type BlockWithMarkDefs = Sanity.Block & {
  _key?: string
}

type RichTextBlockProps = {
  children: string[]
  fontStyle: string
  node: Sanity.Block
  isInternalArticle: boolean
}

const RichTextBlock = ({
  children,
  node,
  fontStyle,
  isInternalArticle,
}: RichTextBlockProps) => {
  switch (node.style) {
    case 'h6':
    case 'h5':
    case 'h4':
    case 'h3':
    case 'h2':
    case 'h1': {
      return (
        <>
          {isInternalArticle ? <a id={slugify(children[0])}></a> : null}
          <RichText_Heading
            tag={node.style}
            node={node.style}
            isInternalArticle={isInternalArticle}
            fontStyle={
              isInternalArticle
                ? internalArticleRichTextFontMap[
                    node.style as keyof typeof internalArticleRichTextFontMap
                  ]
                : richTextStyleHeadingFontMap[
                    node.style as keyof typeof richTextStyleHeadingFontMap
                  ]
            }
          >
            {children}
          </RichText_Heading>
        </>
      )
    }
    default:
      return (
        <RichText_Text
          isInternalArticle={isInternalArticle}
          fontStyle={fontStyle}
        >
          {children}
        </RichText_Text>
      )
  }
}

const createSerializer = (fontStyle: string, isInternalArticle: boolean) => ({
  list: ({
    children,
    type,
  }: {
    children: ReactNode
    type: 'bullet' | 'number'
  }) => (
    <RichTextList as={type === 'bullet' ? 'ul' : 'ol'} fontStyle={fontStyle}>
      {children}
    </RichTextList>
  ),
  marks: {
    sup: ({ children }: { children: string }) => <sup>{children}</sup>,
    link: ({
      mark,
      children,
    }: {
      mark: {
        href: string
        openInNewTab?: boolean
      }
      children: ReactNode
    }) => {
      const { href, openInNewTab = false } = mark
      return (
        <Link href={href} passHref>
          <RichTextInlineLink
            target={openInNewTab ? '_blank' : '_self'}
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
            fontStyle={fontStyle}
          >
            {children}
          </RichTextInlineLink>
        </Link>
      )
    },
  },
  types: {
    block: (props: RichTextBlockProps) => (
      <RichTextBlock
        {...props}
        fontStyle={fontStyle}
        isInternalArticle={isInternalArticle}
      />
    ),
    MediaImage: ({ node }: { node: Sanity.MediaImage }) => (
      <ImageContainer>
        {node.asset ? (
          <Image src={urlFor(node.asset as SanityImageSource)} alt="" />
        ) : null}
      </ImageContainer>
    ),
    CTAButton: ({ node }: { node: Sanity.CTAButton }) => {
      if (!node.url || !node.label) {
        return null
      }
      return (
        <RichTextCta
          fontStyle={fontStyle}
          variant={ButtonTypes.SECONDARY}
          tag="a"
          href={node.url}
          label={node.label}
          isExternal={Boolean(node.isExternal)}
        />
      )
    },
  },
})

interface TextRendererProps {
  isInternalArticle?: boolean
  blocks: BlockWithMarkDefs[]
  bodyFontStyle?: string
}

export const TextRenderer = ({
  blocks,
  isInternalArticle = false,
  bodyFontStyle = FONT_STYLE_SOFIA_16_400,
}: TextRendererProps) => {
  if (!blocks) {
    return null
  }

  return (
    <BlockContent
      serializers={createSerializer(bodyFontStyle, isInternalArticle)}
      renderContainerOnSingleChild
      blocks={blocks}
    />
  )
}

const getMargin = (node: 'h1' | 'h2' | 'h3' | 'h4' | 'h5') => {
  switch (node) {
    case 'h1':
      return '2rem'
    default:
      return '1rem'
  }
}

const RichText_Heading = styled(Heading)<{
  isInternalArticle: boolean
  node: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}>`
  margin: 2rem 0 2.2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 3rem 0 2.2rem;
  }

  ${(props) => {
    if (props.isInternalArticle) {
      return css`
        margin: 0;
        margin-bottom: ${getMargin(props.node)};

        ${MEDIA_QUERIES.desktopUp} {
          margin: 0;
          margin-bottom: ${getMargin(props.node)};
        }
      `
    } else return ''
  }}
`
const RichText_Text = styled(Text)<{ isInternalArticle: boolean }>`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  & + a + h1,
  & + a + h2,
  & + a + h3,
  & + a + h4,
  & + a + h5,
  & + a + h6 {
    margin-top: 2rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    & + a + h1,
    & + a + h2,
    & + a + h3,
    & + a + h4,
    & + a + h5,
    & + a + h6 {
      margin-top: 3rem;
    }
  }

  ${(props) =>
    !props.isInternalArticle
      ? css`
          margin-top: 2rem;

          ${MEDIA_QUERIES.desktopUp} {
            margin-top: 3rem;

            & + &,
            ul + &,
            ol + & {
              margin-top: 0;
            }
          }
        `
      : ''}
`
const RichTextInlineLink = styled.a<{
  fontStyle: string
}>`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  transition: color 150ms ease-out;

  &:hover {
    color: var(--accessibleGrey);
    text-decoration: none;
  }
`

const RichTextCta = styled(Button)<{
  fontStyle: string
}>`
  ${(props) => getFontStyles(props.fontStyle)}
  margin-top: 2rem;
`

const RichTextList = styled.ul<{
  fontStyle: string
}>`
  ${(props) => getFontStyles(props.fontStyle)}
  padding: unset;
  padding-left: 3rem;
  margin: unset;
  list-style: revert;
  margin: 2rem 0;

  & > li + li {
    margin-top: 8px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
`

const Image = styled.img`
  max-width: 100%;
`
