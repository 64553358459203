import { useMemo } from 'react'
import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { Heading, Text } from '../Text'
import { Bg, Button, ButtonTypes } from '../Button'

import {
  FONT_STYLE_SOFIA_32_500,
  FONT_STYLE_SOFIA_16_400,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

import { createArticleLink, createSlug } from '../../helpers/links'

import { SlugType } from '../../references/constants'

import { HighlighterSnippet } from './HighlighterSnippet'

export type HighlighterWhitepaperProps =
  Sanity.Keyed<Sanity.WhitepaperHighlighter>

type WhitepaperSnippet = {
  key: string | number
  title: string
  hrefTo: string
  hrefProps: {
    rel?: string
    target?: string
  }
  tag: string | null
}

export const HighlighterWhitepaper = ({
  title,
  text,
  whitepapers,
}: HighlighterWhitepaperProps) => {
  const whitepaperSnippet = useMemo(
    () =>
      (whitepapers || [])
        .map((paper, i) => {
          if (!paper) {
            return null
          }

          const { _key, reference } = paper
          const [tag] = reference?.Tags ?? []
          const { title: tagTitle, slug: tagSlug } = tag ?? {}

          return {
            key: _key ?? i,
            title: reference?.title ?? '',
            tag: tagTitle,
            excerpt: reference?.ArticleCardInfo?.excerpt ?? undefined,
            ...createArticleLink({
              slug: (reference as Sanity.InternalArticle).slug,
              tagSlug,
              link: (reference as Sanity.ExternalArticle).link,
              file: (reference as Sanity.ExternalArticle).file,
            }),
          }
        })
        .filter((x) => x !== null) as WhitepaperSnippet[],
    [whitepapers]
  )

  return (
    <WhitepaperContainer>
      <WhitepaperContent>
        {title ? (
          <WhitepaperTitle tag={'h2'} fontStyle={FONT_STYLE_SOFIA_32_500}>
            {title}
          </WhitepaperTitle>
        ) : null}
        {text ? (
          <WhitepaperCopy fontStyle={FONT_STYLE_SOFIA_16_400}>
            {text}
          </WhitepaperCopy>
        ) : null}
      </WhitepaperContent>
      <WhitepaperList>
        {whitepaperSnippet.map(({ key, hrefProps, hrefTo, ...restProps }) => (
          <WhitepaperItem key={key}>
            <WhitepaperAnchor href={hrefTo} {...hrefProps}>
              <WhitepaperSnippet whitepaper {...restProps} />
            </WhitepaperAnchor>
          </WhitepaperItem>
        ))}
      </WhitepaperList>
      <WhitepaperButton
        variant={ButtonTypes.PRIMARY}
        tag="a"
        href={createSlug(SlugType.LEARN_ARTICLE, ['whitepapers'])}
        bg={Bg.white}
      >
        View all whitepapers
      </WhitepaperButton>
    </WhitepaperContainer>
  )
}

const WhitepaperContainer = styled.section``

const WhitepaperContent = styled.div`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 4rem 6rem 4rem;
    max-width: 50%;
  }
`

const WhitepaperTitle = styled(Heading)``

const WhitepaperCopy = styled(Text)`
  margin-top: 2rem;
`

const WhitepaperList = styled.ul`
  ${MEDIA_QUERIES.desktopUp} {
    display: flex;
    flex-wrap: wrap;
    padding: 0 3rem 6rem 3rem;
  }
`

const WhitepaperItem = styled.li`
  padding: 0 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0;
    flex: 1 0 50%;
    max-width: 50%;
    padding: 0 1rem;
  }
`

const WhitepaperAnchor = styled.a`
  text-decoration: none;
`

const WhitepaperSnippet = styled(HighlighterSnippet)`
  padding: 3rem 0;
`

const WhitepaperButton = styled(Button)`
  display: none;

  ${MEDIA_QUERIES.desktopUp} {
    display: block;
    margin: 0 4rem 4rem 4rem;
  }
`
