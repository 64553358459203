import { CSSProperties } from 'react'
import styled from 'styled-components'
import Ticker from 'react-ticker'

import { Text } from '../Text/Text'

import { FONT_STYLE_SOFIA_11_400 } from '@cellulargoods/styles'

export enum SmallTickerTheme {
  BLUE = 'blue',
  GREEN = 'green',
}

export type SmallTickerProps = {
  children: string
  theme?: SmallTickerTheme
  move?: boolean
  style?: CSSProperties
  className?: string
}

export const SmallTicker = ({
  children,
  theme = SmallTickerTheme.BLUE,
  move = true,
  style,
  className,
}: SmallTickerProps) => (
  <Banner className={className} theme={theme} style={style}>
    <Ticker move={move} height={16}>
      {() => <Text fontStyle={FONT_STYLE_SOFIA_11_400}>{children}</Text>}
    </Ticker>
  </Banner>
)

const Banner = styled.div`
  background-color: ${(props) =>
    props.theme === SmallTickerTheme.BLUE
      ? 'var(--darkElectricBlue)'
      : 'var(--lightBrown)'};
  padding: 0.8rem 0;
  user-select: none;

  & p {
    color: ${(props) =>
      props.theme === SmallTickerTheme.BLUE ? 'var(--white)' : 'var(--black)'};
    margin-right: 1rem;
  }
`
