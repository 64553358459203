import { useRef, useCallback, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { useDrag } from '@use-gesture/react'
import { ArrowLeft, ArrowRight } from 'phosphor-react'
import { animated, useSpring } from '@react-spring/web'
import { MEDIA_QUERIES } from '@cellulargoods/styles'

import { IconButton } from '../Button'

type CarouselProps = {
  children: ReactNode
  className?: string
  hideButtons?: boolean
  disableDrag?: boolean
  offset?: number
}

export const Carousel = ({
  children,
  className,
  hideButtons,
  disableDrag,
  offset = 20,
}: CarouselProps) => {
  const leftButtonRef = useRef<HTMLButtonElement>(null!)
  const rightButtonRef = useRef<HTMLButtonElement>(null!)
  const draggerRef = useRef<HTMLDivElement>(null!)
  const tilePositions = useRef<number[]>([])

  const currentPosX = useRef(0)

  const [styles, buttonsApi] = useSpring(
    () => ({
      from: {
        leftButtonOpacity: 0,
        rightButtonOpacity: 0,
      },
    }),
    []
  )

  const runButtonSprings = useCallback((posX: number) => {
    if (rightButtonRef.current) {
      if (
        Math.floor(posX) <=
        -(
          (draggerRef.current.scrollWidth ?? 0) -
          (draggerRef.current.clientWidth ?? 0)
        )
      ) {
        rightButtonRef.current.style.pointerEvents = 'none'
        buttonsApi.start({
          to: {
            rightButtonOpacity: 0,
          },
          onRest: () => {
            if (rightButtonRef.current) {
              rightButtonRef.current.style.visibility = 'hidden'
            }
          },
        })
      } else if (
        posX >
        -(
          (draggerRef.current.scrollWidth ?? 0) -
          (draggerRef.current.clientWidth ?? 0)
        )
      ) {
        rightButtonRef.current.style.pointerEvents = 'auto'
        buttonsApi.start({
          to: {
            rightButtonOpacity: 1,
          },
          onStart: () => {
            if (rightButtonRef.current) {
              rightButtonRef.current.style.visibility = 'visible'
            }
          },
        })
      }
    }
    if (leftButtonRef.current) {
      if (posX < 0) {
        leftButtonRef.current.style.pointerEvents = 'auto'
        buttonsApi.start({
          to: {
            leftButtonOpacity: 1,
          },
          onStart: () => {
            if (leftButtonRef.current) {
              leftButtonRef.current.style.visibility = 'visible'
            }
          },
        })
      } else if (posX >= 0) {
        leftButtonRef.current.style.pointerEvents = 'none'
        buttonsApi.start({
          to: {
            leftButtonOpacity: 0,
          },
          onRest: () => {
            if (leftButtonRef.current) {
              leftButtonRef.current.style.visibility = 'hidden'
            }
          },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [spring, api] = useSpring(() => ({
    x: 0,
  }))

  const handleLeftClick = () => {
    const pos = currentPosX.current
    const ind = tilePositions.current.findIndex((tilePos) => {
      return tilePos >= Math.abs(pos)
    })
    const newPosX = tilePositions.current[ind - 1]

    if (newPosX !== undefined) {
      runCarouselSpring(-newPosX, true, true)
    }
  }

  const handleRightClick = () => {
    const pos = currentPosX.current
    const newPosX = tilePositions.current.find((tilePos) => {
      return -tilePos < pos
    })

    if (newPosX !== undefined) {
      runCarouselSpring(-newPosX, true, true)
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (draggerRef.current) {
        const width = (
          draggerRef.current.childNodes[0] as HTMLDivElement
        )?.getBoundingClientRect()?.width

        if (Array.isArray(children)) {
          tilePositions.current = children.map((_, i) => (width + offset) * i)
        }
      }
    }

    handleResize()
    runButtonSprings(0)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const runCarouselSpring = (newX: number, active = true, manual = false) => {
    const { current: dragger } = draggerRef
    const maxMovement = dragger.scrollWidth - dragger.clientWidth

    if (!Boolean(disableDrag)) {
      if (active) {
        dragger.style.cursor = 'grabbing'

        api.start(() => ({
          x: newX >= 0 ? 0 : newX < -maxMovement ? -maxMovement : newX,
        }))

        if (manual) {
          currentPosX.current = newX < -maxMovement ? -maxMovement : newX
        }

        runButtonSprings(newX < -maxMovement ? -maxMovement : newX)
      } else if (!active) {
        dragger.style.cursor = 'grab'

        if (newX <= 0) {
          currentPosX.current = newX < -maxMovement ? -maxMovement : newX
        } else {
          currentPosX.current = 0
        }

        runButtonSprings(currentPosX.current)
      }
    }
  }

  const bind = useDrag(
    ({ movement: [xMove], active }) => {
      let newX = 0
      if (xMove > 0) {
        newX = currentPosX.current + draggerRef.current.clientWidth + offset
      } else {
        newX = currentPosX.current - (draggerRef.current.clientWidth + offset)
      }
      runCarouselSpring(newX, active)
    },
    {
      axis: 'x',
    }
  )

  return (
    <CarouselContainer className={className}>
      <CarouselFlex
        ref={draggerRef}
        $disabledInteraction={!Boolean(disableDrag)}
        {...bind()}
        style={{
          cursor: disableDrag ? 'default' : 'grab',
          ...spring,
        }}
      >
        {children}
      </CarouselFlex>
      {!hideButtons && (
        <CarouselButtons>
          <CarouselButton
            ref={leftButtonRef}
            onClick={handleLeftClick}
            ariaLabel="Move carousel left"
            style={{
              visibility: 'hidden',
              opacity: styles.leftButtonOpacity,
            }}
          >
            <ArrowLeft size={24} weight="thin" color="var(--white)" />
          </CarouselButton>
          <CarouselButton
            ref={rightButtonRef}
            onClick={handleRightClick}
            ariaLabel="Move carousel right"
            style={{
              opacity: styles.rightButtonOpacity,
            }}
          >
            <ArrowRight size={24} weight="thin" color="var(--white)" />
          </CarouselButton>
        </CarouselButtons>
      )}
    </CarouselContainer>
  )
}

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`

export const CarouselFlex = styled(animated.div)<{
  $disabledInteraction: boolean
}>`
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  user-select: ${(props) => (props.$disabledInteraction ? 'none' : 'auto')};
  touch-action: pan-y;

  * {
    user-select: ${(props) => (props.$disabledInteraction ? 'none' : 'auto')};
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding-left: 11.5rem;
  }
`

const CarouselButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${MEDIA_QUERIES.desktopUp} {
    position: absolute;
    top: 40%;
    pointer-events: none;
    left: 0;
    padding-left: inherit;
    padding-right: inherit;
  }
`

const CarouselButton = styled(IconButton)`
  background-color: var(--black);
  padding: 0.4rem;
  border: none;
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin-top: 0;
    padding: 0.8rem;
    pointer-events: auto;
  }
`
