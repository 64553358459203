import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { Button, ButtonTypes } from '../Button'
import { Text, Heading } from '../Text'
import { UGCCard } from '../Cards'

import { FONT_STYLE_SOFIA_40_500, MEDIA_QUERIES } from '@cellulargoods/styles'

import { Carousel } from './Carousel'

export type CarouselUGCProps = Sanity.Keyed<Sanity.UGCCarouselWithYotpo>

export const CarouselUGC = ({
  title,
  copy,
  link,
  label,
  images,
}: CarouselUGCProps) => {
  const renderHeader = title !== null && copy !== null && label !== null

  return (
    <UGCSection>
      {renderHeader && (
        <UGCHeader>
          <div>
            {title ? (
              <Heading fontStyle={FONT_STYLE_SOFIA_40_500}>{title}</Heading>
            ) : null}
          </div>
          <div>
            {copy ? <UGCCopy>{copy}</UGCCopy> : null}
            {label && link ? (
              <UGCCta
                variant={ButtonTypes.SECONDARY}
                tag="a"
                isExternal
                href={link ?? ''}
              >
                {label}
              </UGCCta>
            ) : null}
          </div>
        </UGCHeader>
      )}
      <UGCCarousel>
        {images.map((img) => (
          <UGCCarouselCard key={img.imageId} {...img} />
        ))}
      </UGCCarousel>
    </UGCSection>
  )
}

const UGCSection = styled.section``

const UGCHeader = styled.div`
  padding: 4rem 2rem;

  & > div {
    &:first-child {
      margin-bottom: 2rem;
    }
  }

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 15.5rem;
  }
`

const UGCCopy = styled(Text)`
  margin-bottom: 3rem;
`

const UGCCta = styled(Button)`
  margin: 0 0 1rem;
`

const UGCCarousel = styled(Carousel)`
  padding: 0 2rem 2rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem 8rem 4rem;
  }
`

const UGCCarouselCard = styled(UGCCard)`
  flex: 0 0 31.5rem;

  & + & {
    margin-left: 2rem;
  }

  ${MEDIA_QUERIES.desktopUp} {
    flex: 0 0 40.5rem;
  }
`
