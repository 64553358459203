import styled from 'styled-components'

import { Image, SizesArray, ImageLayout } from '../Image'
import { VIDEO_MODES } from '../Video'

import { Sanity } from '@cellulargoods/types'

import { MediaMux } from './MediaMux'
import { MediaYoutube } from './MediaYoutube'

type SharedMediaProps = {
  className?: string
  floodParent?: boolean
  sizes?: SizesArray
  layout?: ImageLayout
  mode?: VIDEO_MODES
  objectFit?: NonNullable<JSX.IntrinsicElements['img']['style']>['objectFit']
  showVolume?: boolean
}

type ImageProps = {
  _type: 'image'
  asset: Sanity.MediaImage['asset']
}

export type MediaProps =
  | (Sanity.MediaImage & SharedMediaProps)
  | (Sanity.MediaMux & SharedMediaProps)
  | (Sanity.MediaYoutube & SharedMediaProps)
  | (ImageProps & SharedMediaProps)

export const Media = (props: MediaProps) => {
  const {
    _type,
    className,
    floodParent,
    mode,
    showVolume,
    layout = 'fill',
    objectFit,
    ...restProps
  } = props
  switch (_type) {
    case 'MediaImage':
    case 'image':
      const { sizes } = props
      return (
        <MediaContainer className={className}>
          <Image
            layout={layout}
            objectFit={objectFit}
            image={{
              ...(restProps as Sanity.MediaImage),
            }}
            sizes={sizes}
          />
        </MediaContainer>
      )
    case 'MediaMux': {
      return (
        <MediaContainer className={className}>
          <MediaMux
            {...(restProps as Sanity.MediaMux)}
            floodParent={floodParent}
            showVolume={showVolume}
            mode={mode}
          />
        </MediaContainer>
      )
    }
    case 'MediaYoutube':
      return (
        <MediaContainer className={className}>
          <MediaYoutube {...(restProps as Sanity.MediaYoutube)} />
        </MediaContainer>
      )
    default:
      console.warn('missing case for ', _type)
      return null
  }
}

const MediaContainer = styled.div`
  height: 100%;
  width: 100%;
`
