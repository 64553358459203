export type ImpressionFieldObjects = ProductFieldObject & {
  list: string
  position: number
}

export type Impressions = {
  ecommerce: {
    currencyCode: string
    impressions: ImpressionFieldObjects[]
  }
}

export type ProductFieldObject = {
  name: string
  id: string
  price: string
  brand: string
  category: string
  variant: string
}

export type Detail = {
  ecommerce: {
    detail: {
      actionField?: Record<string, string>
      products: ProductFieldObject[]
    }
  }
}

export enum CartEvents {
  ADD_TO_CART = 'addToCart',
  REMOVE_FROM_CART = 'removeFromCart',
}

export type ProductFieldWithQuantity = ProductFieldObject & {
  quantity: number
}

export type CartEvent = {
  event: CartEvents
  ecommerce: {
    currencyCode: string
    add?: {
      products: ProductFieldWithQuantity[]
    }
    remove?: {
      products: ProductFieldWithQuantity[]
    }
  }
}

export type CheckoutActionField = {
  id: string
  revenue: string
}

export type CheckoutEvent = {
  event: 'checkout'
  ecommerce: {
    currencyCode: string
    checkout: {
      actionField: CheckoutActionField
      products: ProductFieldObject[]
    }
  }
}
