export const styleVisuallyHidden = `
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const aspectRatio = (w: number, h: number, positionChild = true) => `
overflow: hidden;
position: relative;

&:before {
  display: block;
  content: '';
  width: 100%;
  padding-top: ${(h / w) * 100}%;
}

${
  positionChild
    ? `
    & > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
`
    : ''
}
`
