export const COLORS = {
  white: '#ffffff',
  black: '#000000',
  accessibleGrey: '#757575',
  lightGrey: '#F2F3F5',
  lightGrey2: '#E5E3E1',
  softGrey: '#F7F7F7',
  midGrey: '#CCCCCC',
  darkGrey1: '#272522',
  darkGrey2: '#4D5256',
  lightBrown: '#DBD2C5',
  electricGreen: '#E0FC3C',
  electricBlue: '#0521D3',
  lightElectricBlue: '#F2F4FC',
  darkElectricBlue: '#07364A',
  validationError: '#EA6956',
  validationSuccess: '#69CB68',
}
