import { animated, useSpring, config } from '@react-spring/web'
import styled from 'styled-components'

import { Tag } from '../Ornaments'
import { Text } from '../Text'

import {
  FONT_STYLE_SOFIA_11_400,
  FONT_STYLE_SOFIA_16_400,
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_28_500,
  getFontStyles,
  COLORS,
} from '@cellulargoods/styles'

export type HighlighterSnippetProps = {
  title: string
  tag: string | null
  excerpt?: string
  active?: boolean
  whitepaper?: boolean
  className?: string
}

export const HighlighterSnippet = ({
  title,
  tag,
  active = false,
  whitepaper = false,
  excerpt,
  className,
}: HighlighterSnippetProps) => {
  const styles = useSpring({
    borderTop: whitepaper
      ? `solid 1px ${COLORS.midGrey}`
      : active
      ? `solid 2px ${COLORS.darkGrey1}`
      : `solid 2px ${COLORS.accessibleGrey}`,
    opacity: whitepaper || active ? 1 : 0.3,
    config: config.slow,
  })

  return (
    <animated.div style={styles}>
      <Snippet className={className} fontStyle={FONT_STYLE_SOFIA_18_500}>
        {tag && !whitepaper ? (
          <SnippetTag>{tag}</SnippetTag>
        ) : tag && whitepaper ? (
          <SnippetWhitepaperTag inSnippet title={tag} />
        ) : null}
        <SnippetText whitepaper={whitepaper}>{title}</SnippetText>
        {excerpt ? <SnippetExcerpt>{excerpt}</SnippetExcerpt> : null}
      </Snippet>
    </animated.div>
  )
}

const Snippet = styled(Text)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0 3rem 0;
  color: var(--darkGrey);
`

const SnippetTag = styled.span`
  ${getFontStyles(FONT_STYLE_SOFIA_11_400)}
  text-transform: uppercase;
`

const SnippetWhitepaperTag = styled(Tag)``

const SnippetText = styled.span<Pick<HighlighterSnippetProps, 'whitepaper'>>`
  ${(props) => (props.whitepaper ? getFontStyles(FONT_STYLE_SOFIA_28_500) : '')}
  margin-top: ${(props) => (!props.whitepaper ? '1rem' : '2rem')};
  text-align: left;
`

const SnippetExcerpt = styled.span`
  ${getFontStyles(FONT_STYLE_SOFIA_16_400)};
  margin-top: 2rem;
`
