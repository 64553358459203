import styled from 'styled-components'
import { Sanity } from '@cellulargoods/types'

import { mergeColumns } from '../../helpers/mergeColumns'

import { Text, Heading } from '../Text'
import { Media } from '../Media'
import { Button, ButtonTypes } from '../Button'

import {
  FONT_STYLE_SOFIA_18_500,
  aspectRatio,
  MEDIA_QUERIES,
} from '@cellulargoods/styles'

export type ContentTwoImageAndTextProps = Sanity.Keyed<Sanity.TwoImageAndText>

export const ContentTwoImageAndText = ({
  leftCol_twoImageAndText: leftCol,
  rightCol_twoImageAndText: rightCol,
}: ContentTwoImageAndTextProps) => {
  if (!leftCol || !rightCol) {
    return null
  }

  const items = mergeColumns(leftCol, rightCol)

  return (
    <TwoImageAndTextContainer>
      {items.map((item, index) => item && miniRenderer(item, index))}
    </TwoImageAndTextContainer>
  )
}

const miniRenderer = (
  CMSComponent:
    | Sanity.Keyed<Sanity.MediaGridItem>
    | Sanity.Keyed<Sanity.TwoImageAndTextVerticalColumn>,
  index: number
) => {
  const isFirst = index === 0
  switch (CMSComponent._type) {
    case 'MediaGridItem': {
      const { _key, asset } = CMSComponent

      const [media] = asset ?? []

      return (
        <RegularColumn isFirst={isFirst} key={_key}>
          <RegularImage>
            {media && (
              <Media {...media} sizes={['100vw', null, null, '66vw']} />
            )}
          </RegularImage>
        </RegularColumn>
      )
    }
    case 'TwoImageAndTextVerticalColumn': {
      const { _key, media, text, heading, CTAButton } = CMSComponent

      const [asset] = media ?? []

      const renderCta = CTAButton && CTAButton.label && CTAButton.url

      return (
        <VerticalColumn isFirst={isFirst} key={_key}>
          <VerticalColumnImage>
            {asset && (
              <Media {...asset} sizes={['100vw', null, null, '33vw']} />
            )}
          </VerticalColumnImage>
          <VerticalColumnContent>
            {heading ? (
              <Heading tag="h2" fontStyle={FONT_STYLE_SOFIA_18_500}>
                {heading}
              </Heading>
            ) : null}
            {text ? <VeritcalColumnText>{text}</VeritcalColumnText> : null}
            {renderCta && (
              <VerticalColumnCta
                variant={ButtonTypes.SECONDARY}
                tag="a"
                href={CTAButton.url}
                isExternal={Boolean(CTAButton.isExternal)}
              >
                {CTAButton.label}
              </VerticalColumnCta>
            )}
          </VerticalColumnContent>
        </VerticalColumn>
      )
    }
    default:
      break
  }
}

type SharedContainerProps = {
  isFirst: boolean
}

const TwoImageAndTextContainer = styled.section`
  padding: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    padding: 8rem 4rem;
    display: flex;
    justify-content: space-between;
  }
`

const GenericImageContainer = styled.div`
  ${aspectRatio(785, 985, false)};
`

const VerticalColumnImage = styled(GenericImageContainer)``

const VerticalColumnContent = styled.div`
  margin: 2rem 0;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0;
  }
`

const VeritcalColumnText = styled(Text)`
  margin-top: 2rem;
  white-space: pre-wrap;
`

const VerticalColumnCta = styled(Button)`
  margin-top: 2rem;
`

const VerticalColumn = styled.div<SharedContainerProps>`
  display: flex;
  flex-direction: column;

  ${VerticalColumnImage} {
    order: ${(props) => (props.isFirst ? 1 : 2)};
  }

  ${VerticalColumnContent} {
    order: ${(props) => (props.isFirst ? 2 : 1)};
  }

  ${MEDIA_QUERIES.desktopUp} {
    justify-content: space-between;
    flex-basis: 33%;
    margin-right: ${(props) => props.isFirst && `calc(1 / 12 * 100%)`};

    ${VerticalColumnImage} {
      order: 1;
    }

    ${VerticalColumnContent} {
      order: 2;
    }
  }
`

const RegularColumn = styled.div<SharedContainerProps>`
  ${MEDIA_QUERIES.desktopUp} {
    flex-basis: 66%;
    margin-right: ${(props) => props.isFirst && `calc(1 / 12 * 100%)`};
  }
`

const RegularImage = styled(GenericImageContainer)``
