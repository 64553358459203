import styled from 'styled-components'

import { Sanity } from '@cellulargoods/types'

import { Media } from '../Media/Media'
import { SmallTickerTheme, SmallTicker } from '../Ornaments/SmallTicker'
import { Text } from '../Text/Text'
import { Button, ButtonTypes } from '../Button'

import { aspectRatio, MEDIA_QUERIES } from '@cellulargoods/styles'

import { createSlug } from '../../helpers/links'

import { SlugType } from '../../references/constants'

export type CategoryAnnouncementProps =
  Sanity.Keyed<Sanity.CategoryAnnouncement>

export const ContentCategoryAnnouncement = ({
  pillText,
  media,
  ctaLabel,
  category,
  announcement,
}: CategoryAnnouncementProps) => {
  return (
    <Announcement>
      {media && media.length > 0 && (
        <MediaContainer>
          <Media {...media[0]} sizes={['100vw']} />
        </MediaContainer>
      )}
      {pillText ? (
        <Ticker theme={SmallTickerTheme.GREEN}>{pillText}</Ticker>
      ) : null}
      <AccouncementBanner>
        <AnnouncementText>{announcement}</AnnouncementText>
        {category?.slug?.current ? (
          <AnnouncementButton
            tag="a"
            href={createSlug(SlugType.ENHANCED_PLP, [category.slug.current])}
            variant={ButtonTypes.PRIMARY}
          >
            {ctaLabel}
          </AnnouncementButton>
        ) : null}
      </AccouncementBanner>
    </Announcement>
  )
}

const Announcement = styled.section`
  width: 100%;
  position: relative;
`

const MediaContainer = styled.div`
  ${aspectRatio(375, 736)}

  ${MEDIA_QUERIES.desktopUp} {
    ${aspectRatio(1400, 900)}
  }
`

const Ticker = styled(SmallTicker)`
  position: absolute;
  top: 4rem;
  transform: translateX(-50%);
  left: 50%;
  z-index: 10;
  margin-left: 0;
  width: 100%;
  min-width: 18rem;
  max-width: 30rem;
`

const AccouncementBanner = styled.div`
  position: absolute;
  background-color: var(--lightBrown);
  padding: 4rem 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  ${MEDIA_QUERIES.desktopUp} {
    bottom: 4rem;
    left: 3rem;
    width: calc(100% - 6rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 4rem;
  }
`

const AnnouncementText = styled(Text)``

const AnnouncementButton = styled(Button)`
  margin-top: 3rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 0;
    margin-left: 2rem;
    white-space: nowrap;
  }
`
